import UploadService from './UploadService';

type TUploadFilePayload = {
    elementId: number;
    projectId: number;
    file: File;
    onChunkUploaded: (uploadedBytes: number) => void;
    onUploaded: (file: TUploadedFile) => void;
    uuid?: string;
};

export type TUploadedFile = {
    uuid: string;
    createdAt: string;
    fileType: string;
    contentType: string;
    originalName: string;
    size: number;
    projectId: number;
    elementId: number;
};
export enum UploadFileTypes {
    INITIALIZE = 'initialize',
    UPDATE = 'update',
}
export type TUploadFile = UploadFileTypes.INITIALIZE | UploadFileTypes.UPDATE;
interface IUploadFile {
    (payload: TUploadFilePayload, type: TUploadFile): void;
}

export default class FileUploadService {
    static uploadFile: IUploadFile = async (payload: TUploadFilePayload, type: TUploadFile) => {
        const headers = {
            projectId: payload.projectId,
            elementId: payload.elementId,
        };
        const chunkSize = 1048576; // 1Mb        
        const updateUrl = payload.uuid
            ? `/api/v1/modeling/updateProjectFile?uuid=${payload.uuid}`
            : '/api/v1/modeling/updateProjectFile';
        const url =
            type === UploadFileTypes.INITIALIZE
                ? '/api/v1/modeling/uploadProjectFile'
                : updateUrl;

        const uploadService = new UploadService(url, payload.file, headers, chunkSize);
        await uploadService.uploadFile<TUploadedFile>(payload.onChunkUploaded, payload.onUploaded);
    };
}
