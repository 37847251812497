export enum PortConnectionTypes {
    DEFAULT_INPUT = 'default_input',
    DEFAULT_OUTPUT = 'default_output',
    AUTO_IN = 'auto_in',
    AUTO_OUT = 'auto_out',
    ANG_SPEED_IN = 'ang_speed_in',
    ANG_SPEED_OUT = 'ang_speed_out',
    TORQUE_IN = 'torque_in',
    TORQUE_OUT = 'torque_out',
    SPEED_IN = 'speed_in',
    SPEED_OUT = 'speed_out',
    FORCE_IN = 'force_in',
    FORCE_OUT = 'force_out',
    THERMOHYDRAULICS_IN = 'thermohydraulics_in',
    THERMOHYDRAULICS_OUT = 'thermohydraulics_out',
    ISOTHERMAL_HYDRAULICS_IN = 'isothermal_hydraulics_in',
    ISOTHERMAL_HYDRAULICS_OUT = 'isothermal_hydraulics_out',
    ELECTROCITY_IN = 'electrocity_in',
    ELECTROCITY_OUT = 'electrocity_out',
    ELECTRONICS_IN = 'electronics_in',
    ELECTRONICS_OUT = 'electronics_out',
    CAR_IN = 'car_in',
    CAR_OUT = 'car_out',
    VOLTAGE_IN = 'voltage_in',
    VOLTAGE_OUT = 'voltage_out',
    SYSTEM_DESIGN_TORQUE_IN = 'system_design_torque_in',
    SYSTEM_DESIGN_TORQUE_OUT = 'system_design_torque_out',
    HEATTRANSFER_IN = 'heattransfer_in',
    HEATTRANSFER_OUT = 'heattransfer_out',
    RF_IN = 'rf_in',
    RF_OUT = 'rf_out',
}

export type TPortConnectionType = (typeof PortConnectionTypes)[keyof typeof PortConnectionTypes];
