import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.SAVE]: {
        id: TranslationKey.SAVE,
        defaultMessage: ru[TranslationKey.SAVE],
    },
    [TranslationKey.SAVE_AS]: {
        id: TranslationKey.SAVE_AS,
        defaultMessage: ru[TranslationKey.SAVE_AS],
    },
    [TranslationKey.TOUR_PROJECTS_LIST]: {
        id: TranslationKey.TOUR_PROJECTS_LIST,
        defaultMessage: ru[TranslationKey.TOUR_PROJECTS_LIST],
    },
    [TranslationKey.CLOSE_PROJECT]: {
        id: TranslationKey.CLOSE_PROJECT,
        defaultMessage: ru[TranslationKey.CLOSE_PROJECT],
    },
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA]: {
        id: TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA],
    },
    [TranslationKey.USER_BLOCK_EDIT_VIEW_TITLE]: {
        id: TranslationKey.USER_BLOCK_EDIT_VIEW_TITLE,
        defaultMessage: ru[TranslationKey.USER_BLOCK_EDIT_VIEW_TITLE],
    },
});
