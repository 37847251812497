import { PayloadAction } from '@reduxjs/toolkit';

import { ILibraryItem, IWorkspaceState, LibraryTypes, Statuses, TLibraryType, TSolverType } from '@repeat/models';
import { IBlockResponse, LibraryItemsAssetsService, LibraryItemsService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';

import { AppDispatch } from '../../../store';
import { actions, initialState } from '../index';

export const libraryItemsReducers = {
    getLibraryItemsRequest: (state: IWorkspaceState) => ({
        ...state,
        libraryItems: {
            ...initialState.libraryItems,
            status: Statuses.LOADING,
        },
    }),
    getLibraryItemsSuccess: (state: IWorkspaceState, action: PayloadAction<ILibraryItem[]>) => ({
        ...state,
        libraryItems: {
            ...state.libraryItems,
            items: action.payload,
            status: Statuses.SUCCEEDED,
        },
    }),
    getLibraryItemsFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        libraryItems: {
            ...state.libraryItems,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
};

export const fetchLibraryItems =
    (solverType: TSolverType, type: TLibraryType = LibraryTypes.ELECTROCITY, locale: string, isFromDB?: boolean) =>
    async (dispatch: AppDispatch) => {
        dispatch(actions.getLibraryItemsRequest());
        try {
            if (!isFromDB) {
                const libraryItemsService = new LibraryItemsAssetsService({ solverType, type, locale });
                const items = await libraryItemsService.getLibraryItems();

                if (!items.data) {
                    return;
                }

                const libraryItems = items.data;

                dispatch(actions.getLibraryItemsSuccess(libraryItems));

                return;
            }

            const libraryItemsService = new LibraryItemsService({ solverType, type, locale });
            const items = await libraryItemsService.getLibraryItems();

            if (!items.data) {
                return;
            }

            const libraryItems = items.data.map((item: IBlockResponse) => {
                const { id, block, image, categoryId } = item;
                return { ...block, image } as ILibraryItem;
            });

            dispatch(actions.getLibraryItemsSuccess(libraryItems));
        } catch (error: any) {
            const errorKey = TranslationKey.ERROR_LIBRARY_ITEMS;
            dispatch(actions.getLibraryItemsFailed({ error: errorKey }));
        }
    };
