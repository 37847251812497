import { MouseEvent, DragEvent, FC, useCallback, useState, memo } from 'react';

import { fileManagerCheckRule } from './FileManagerHelpers';
import { FileManagerItem } from './FileManagerItem';
import { FileManagerPreview } from './FileManagerPreview';
import { EFileManagerItemType, EFileManagerRule, IFileManagerBody, TFileManagerElement } from './FileManagerTypes';
import { FileManagerUpItem } from './FileManagerUpItem';
import { FileManagerUploadZone } from './FileManagerUploadZone';
import { SFileManagerBody, SFileManagerItemWrapper, SSFileManagerEmpty } from './SFileManager';

import { DATA_VIEW_MODE_PROPERTY, DATA_VIEW_PREVIEW_MODE_PROPERTY } from '../../hooks/useViewMode';
import { IContextMenuItem, useContextMenu } from '../ContextMenu/ContextMenu';
import { Icon } from '../Icon/Icon';
import { LoaderDefault } from '../LoaderDefault/LoaderDefault';

export const FileManagerBody: FC<IFileManagerBody> = memo(
    ({
        translations,
        viewMode,
        files,
        isPreviewOpen,
        imageLoader,
        currentFolderId,
        selectedElements,
        previousFolderIds,
        isOpenImport,
        isItemDragging,
        favoriteList,
        isShowPreloader,
        rules,
        onSaveFolderName,
        onEditFolderName,
        onUnSelectAll,
        onCheckboxSelect,
        onRemove,
        onReturnFolder,
        onOpenFolder,
        onOpenFile,
        onSelect,
        onExport,
        onMove,
        onSort,
        onImport,
        onOpenImport,
        onItemDragging,
        onCopyId,
        onAddToFavorites,
        onDuplicate,
        onManageDemo,
        onAddToRoot,
    }) => {
        const [contextMenu, setContextMenu] = useState<IContextMenuItem[] | null>(null);
        const { Menu, handleContextMenuRightClick, setTarget } = useContextMenu();

        const handleContextMenu = useCallback((event: React.MouseEvent, id: number, items: IContextMenuItem[]) => {
            handleContextMenuRightClick(event);
            setTarget(id);
            return setContextMenu(items);
        }, []);

        const handleMove = useCallback((event: DragEvent) => {
            const element = event.dataTransfer.getData('element') || '';
            const elements = event.dataTransfer.getData('elements') || '';
            const transferData = element ? JSON.parse(element) : JSON.parse(elements);

            if (transferData) {
                let target = (event.target as HTMLElement) || null;
                while (!target.classList.contains('file-manager__item')) {
                    target = target.parentElement as HTMLElement;
                    if (!target) {
                        break;
                    }
                }
                if (target?.getAttribute('data-file-manager-type') === EFileManagerItemType.DIR) {
                    const targetId = parseInt(target.getAttribute('data-file-manager-id') as string);
                    if (targetId !== transferData.id && !Array.isArray(transferData)) {
                        const data = {
                            newFolderId: targetId,
                            ...(transferData.type === EFileManagerItemType.FILE && { files: [transferData.id] }),
                            ...(transferData.type === EFileManagerItemType.DIR && { folders: [transferData.id] }),
                        };
                        onMove && onMove(data);
                    } else if (targetId !== transferData.id && transferData.length > 1) {
                        const folders = transferData
                            .filter((folder: TFileManagerElement) => folder.type === EFileManagerItemType.DIR)
                            .map((folder: TFileManagerElement) => folder.id);
                        const files = transferData
                            .filter((file: TFileManagerElement) => file.type === EFileManagerItemType.FILE)
                            .map((file: TFileManagerElement) => file.id);
                        const data = {
                            newFolderId: targetId,
                            ...(folders.length > 0 && { folders }),
                            ...(files.length > 0 && { files: files }),
                        };
                        onMove && onMove(data);
                    } else {
                        return false;
                    }
                }
            }
            return false;
        }, []);

        return (
            <SFileManagerBody
                {...{ [DATA_VIEW_MODE_PROPERTY]: viewMode }}
                {...{ [DATA_VIEW_PREVIEW_MODE_PROPERTY]: isPreviewOpen }}
                data-file-manager-body
                className={'file-manager__body'}
                onDragEnter={(event) => {
                    let target = (event.target as HTMLElement) || null;
                    while (!target.classList.contains('file-manager__body')) {
                        target = target.parentElement as HTMLElement;
                        if (!target) {
                            break;
                        }
                    }
                    if (!isItemDragging && target) {
                        if (!isOpenImport && target.classList.contains('file-manager__body')) {
                            onOpenImport && onOpenImport(true);
                        }
                    }
                }}
            >
                <SFileManagerItemWrapper currentFolderId={currentFolderId}>
                    {currentFolderId !== 0 && (
                        <FileManagerUpItem
                            onMove={handleMove}
                            onClick={onReturnFolder}
                            id={previousFolderIds[previousFolderIds.length - 2]}
                        />
                    )}
                    {files.length > 0 &&
                        [...files].sort(onSort).map((file) => (
                            <FileManagerItem
                                file={file}
                                key={file.uuid}
                                selectedElements={selectedElements}
                                translations={translations}
                                files={files}
                                isItemDragging={isItemDragging}
                                favoriteList={favoriteList}
                                rules={rules}
                                onCheckboxSelect={onCheckboxSelect}
                                onSaveFolderName={onSaveFolderName}
                                onEditFolderName={onEditFolderName}
                                onOpenFolder={onOpenFolder}
                                onOpenFile={onOpenFile}
                                onMove={handleMove}
                                onRemove={onRemove}
                                onExport={onExport}
                                onUnSelectAll={onUnSelectAll}
                                onItemDragging={onItemDragging}
                                onCopyId={onCopyId}
                                onContextMenu={handleContextMenu}
                                onAddToFavorites={onAddToFavorites}
                                onDuplicate={onDuplicate}
                                onManageDemo={onManageDemo}
                                onAddToRoot={onAddToRoot}
                                onSelect={(uuid: string, event?: MouseEvent) => {
                                    onSelect(uuid, event);
                                    setTarget(null);
                                }}
                            />
                        ))}

                    <SSFileManagerEmpty>
                        {isShowPreloader && <LoaderDefault color={'var(--ui-text)'} />}
                        {!isShowPreloader && files.length === 0 && translations && translations['empty'] && (
                            <>
                                <Icon name={'empty'} />
                                <span>{translations['empty']}</span>
                            </>
                        )}
                    </SSFileManagerEmpty>
                </SFileManagerItemWrapper>
                {isPreviewOpen && (
                    <FileManagerPreview
                        rules={rules}
                        imageLoader={imageLoader ? imageLoader : null}
                        translations={translations}
                        files={files}
                        selectedElements={selectedElements}
                    />
                )}
                {isOpenImport && fileManagerCheckRule(rules, EFileManagerRule.IMPORT) && (
                    <FileManagerUploadZone
                        rules={rules}
                        isOpenImport={isOpenImport}
                        isItemDragging={isItemDragging}
                        isPreviewOpen={isPreviewOpen}
                        translations={translations}
                        onImport={onImport}
                        onOpenImport={onOpenImport}
                    />
                )}
                <Menu items={contextMenu} />
            </SFileManagerBody>
        );
    }
);
