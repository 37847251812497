import { FC, MouseEvent, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import { TranslationKey } from '@repeat/translations';
import { Button, IDropZoneMessages } from '@repeat/ui-kit';

import { SDropZone, SDropZoneFileList, SDropZoneText } from './SUpload';
import { messages } from './translation';

interface IDropZone {
    acceptFileTypes?: {
        [key: string]: string[];
    };
    maxFileCount?: number;
    maxFileSize?: number;
    onDropFile: (file: File) => void;
    dropZoneMessages?: { uploadHelp: string; uploadButton: string };
}

export const DropZone: FC<IDropZone> = ({
    acceptFileTypes,
    onDropFile,
    maxFileCount = 1,
    maxFileSize = 20971520,
    dropZoneMessages,
}) => {
    const [hasError, setError] = useState({ error: false, message: '' });
    const { formatMessage } = useIntl();
    const defaultMessages: IDropZoneMessages = {
        errorLargeFile: 'Размер файла превышает допустимый',
        bytes: 'байт',
        uploadHelp: 'Загрузите файл',
        uploadButton: 'Загрузить',
    };
    const onValidate = (file: File) => {
        if (file.size > maxFileSize) {
            setError({ error: true, message: formatMessage(messages[TranslationKey.ERROR_LARGE_FILE]) });
            return null;
        }

        setError({ error: false, message: '' });
        return null;
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            onDropFile(file);
        });
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noKeyboard: true,
        maxFiles: maxFileCount,
        maxSize: maxFileSize,
        validator: onValidate,
        ...(acceptFileTypes && { accept: { ...acceptFileTypes } }),
    });

    const files = acceptedFiles.map((file: File) => (
        <li key={file.name}>
            {file.name} - {file.size} {formatMessage(messages[TranslationKey.BYTES])}
        </li>
    ));

    const buttonUploadHandler = (event: MouseEvent) => {
        event.stopPropagation();
        open();
    };

    return (
        <SDropZone {...getRootProps()} hasError={hasError.error}>
            <input {...getInputProps()} />
            <SDropZoneText>
                {dropZoneMessages?.uploadHelp ? dropZoneMessages.uploadHelp : defaultMessages.uploadHelp}
            </SDropZoneText>
            <Button onClick={buttonUploadHandler}>
                {dropZoneMessages?.uploadButton ? dropZoneMessages.uploadButton : dropZoneMessages?.uploadButton}
            </Button>
            <SDropZoneFileList>
                {files}
                {hasError.error && <li data-error={hasError.error}>{hasError.message}</li>}
            </SDropZoneFileList>
        </SDropZone>
    );
};
