import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(confirmExit: (callback: () => void) => boolean, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) {
            return;
        }

        const push = navigator.push;

        navigator.push = (...args: Parameters<typeof push>) => {
            const result = confirmExit(() => {
                push(...args);
            });
            if (result) {
                push(...args);
            }
        };

        return () => {
            navigator.push = push;
        };
    }, [navigator, confirmExit, when]);
}

export function useNavigationPrompt(message: string, when = true, callback: () => void) {
    useEffect(() => {
        if (when) {
            window.onbeforeunload = function () {
                return message;
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [message, when]);

    const confirmExit = useCallback(
        (navigationCallback: () => void) => {
            const result = window.confirm(message);
            if (result) {
                callback();
                navigationCallback();
            }
            return result;
        },
        [message, callback]
    );

    useBlocker(confirmExit, when);
}
