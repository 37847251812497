import { isAnyOf, ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import { TState } from '@repeat/models';
import { clearDBStack, setDBStack } from '@repeat/services';
import { schemaActions, workspaceActions } from '@repeat/store';

import { UndoRedoStatus } from '../slices/workspace/undoredo';

const exceptionsForAddingElement = ['fmi'];

const excludedFields = ['data', 'dataMap'];

export const appendUndoRedoListener = (listenerMiddleware: ListenerMiddlewareInstance) => {
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.initializeWorkspaceSuccess,
        effect: async (action, { getState }) => {
            const { workspace } = getState() as TState;
            const currentGraphs = { ...workspace.graphs } as Record<string, unknown>;
            const graphsKeys: string[] = Object.keys(workspace.graphs);
            const graphs: Record<string, unknown> = {};
            graphsKeys.forEach((graph) => {
                if (!excludedFields.includes(graph)) {
                    graphs[graph] = currentGraphs[graph];
                }
            });
            const options = {
                schemaItems: workspace.schema.schemaItems,
                settings: workspace.settings,
                graphs,
                externalInfo: workspace.schema.externalInfo,
                proxyMap: workspace.schema.proxyMap,
                goToMap: workspace.schema.goToMap,
                blockNotifications: workspace.schema.blockNotifications,
                itemsForSubmodel: workspace.schema.itemsForSubmodel,
                modules: workspace.modules,
                userBlocksCount: workspace.schema.userBlocksCount,
            };
            await clearDBStack();
            await setDBStack({ type: action.type, payload: options });
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.addNode,
        effect: async (action, { getState, dispatch }) => {
            const { workspace } = getState() as TState;
            const element = action.payload.data;
            if (!exceptionsForAddingElement.includes(element.type)) {
                const currentGraphs = { ...workspace.graphs } as Record<string, unknown>;
                const graphsKeys: string[] = Object.keys(workspace.graphs);
                const graphs: Record<string, unknown> = {};
                graphsKeys.forEach((graph) => {
                    if (!excludedFields.includes(graph)) {
                        graphs[graph] = currentGraphs[graph];
                    }
                });
                const options = {
                    schemaItems: workspace.schema.schemaItems,
                    settings: workspace.settings,
                    graphs,
                    proxyMap: workspace.schema.proxyMap,
                    externalInfo: workspace.schema.externalInfo,
                    goToMap: workspace.schema.goToMap,
                    blockNotifications: workspace.schema.blockNotifications,
                    itemsForSubmodel: workspace.schema.itemsForSubmodel,
                    modules: workspace.modules,
                    userBlocksCount: workspace.schema.userBlocksCount,
                };
                await setDBStack({ type: action.type, payload: options });
                dispatch(UndoRedoStatus());
            }
        },
    });
    listenerMiddleware.startListening({
        actionCreator: schemaActions.initializeElementSuccess,
        effect: async (action, { getState, dispatch }) => {
            const { workspace } = getState() as TState;
            const currentGraphs = { ...workspace.graphs } as Record<string, unknown>;
            const graphsKeys: string[] = Object.keys(workspace.graphs);
            const graphs: Record<string, unknown> = {};
            graphsKeys.forEach((graph) => {
                if (!excludedFields.includes(graph)) {
                    graphs[graph] = currentGraphs[graph];
                }
            });
            const options = {
                schemaItems: workspace.schema.schemaItems,
                settings: workspace.settings,
                graphs,
                proxyMap: workspace.schema.proxyMap,
                externalInfo: workspace.schema.externalInfo,
                goToMap: workspace.schema.goToMap,
                blockNotifications: workspace.schema.blockNotifications,
                itemsForSubmodel: workspace.schema.itemsForSubmodel,
                modules: workspace.modules,
                userBlocksCount: workspace.schema.userBlocksCount,
            };
            await setDBStack({ type: action.type, payload: options });
            dispatch(UndoRedoStatus());
        },
    });
    listenerMiddleware.startListening({
        matcher: isAnyOf(
            workspaceActions.changeElementRotation,
            workspaceActions.changeElementSize,
            workspaceActions.updateNodesPositions,
            workspaceActions.setElementPropertiesValues,
            workspaceActions.addConnection,
            workspaceActions.deleteSchemaItems,
            workspaceActions.setIndicatorParameter,
            workspaceActions.pasteItems,
            workspaceActions.addParameter,
            workspaceActions.updateNodesAndConnections,
            workspaceActions.addExternalProperty,
            workspaceActions.deleteExternalProperty,
            workspaceActions.addPortNode,
            workspaceActions.setExternalPropertyDescription,
            workspaceActions.setExternalPropertyValue,
            workspaceActions.setProjectSettings,
            workspaceActions.setBlocksGroup,
            workspaceActions.setGroupName,
            workspaceActions.setGroupBlockProperty,
            workspaceActions.deleteGroupBlockProperty,
            workspaceActions.setGroupPropertyDescription,
            workspaceActions.updateElementWithUserBlock,
            workspaceActions.addLibraryItemProperty,
            workspaceActions.deleteLibraryItemProperty,
            workspaceActions.setLibraryItemPropertyDescription
        ),
        effect: async (action, { getState, dispatch }) => {
            const { workspace } = getState() as TState;
            const currentGraphs = { ...workspace.graphs } as Record<string, unknown>;
            const graphsKeys: string[] = Object.keys(workspace.graphs);
            const graphs: Record<string, unknown> = {};
            graphsKeys.forEach((graph) => {
                if (!excludedFields.includes(graph)) {
                    graphs[graph] = currentGraphs[graph];
                }
            });
            const options = {
                schemaItems: workspace.schema.schemaItems,
                settings: workspace.settings,
                graphs,
                proxyMap: workspace.schema.proxyMap,
                externalInfo: workspace.schema.externalInfo,
                goToMap: workspace.schema.goToMap,
                blockNotifications: workspace.schema.blockNotifications,
                itemsForSubmodel: workspace.schema.itemsForSubmodel,
                modules: workspace.modules,
                userBlocksCount: workspace.schema.userBlocksCount,
            };
            await setDBStack({ type: action.type, payload: options });
            dispatch(UndoRedoStatus());
        },
    });
    listenerMiddleware.startListening({
        matcher: isAnyOf(
            workspaceActions.addParametersChart,
            workspaceActions.addPackChart,
            workspaceActions.removePackChart,
            workspaceActions.removeChart,
            workspaceActions.addEmptyChart,
            workspaceActions.setSizeCharts
        ),
        effect: async (action, { getState, dispatch }) => {
            const { workspace } = getState() as TState;
            const currentGraphs = { ...workspace.graphs } as Record<string, unknown>;
            const graphsKeys: string[] = Object.keys(workspace.graphs);
            const graphs: Record<string, unknown> = {};
            graphsKeys.forEach((graph) => {
                if (!excludedFields.includes(graph)) {
                    graphs[graph] = currentGraphs[graph];
                }
            });
            const options = {
                schemaItems: workspace.schema.schemaItems,
                settings: workspace.settings,
                graphs,
                proxyMap: workspace.schema.proxyMap,
                externalInfo: workspace.schema.externalInfo,
                goToMap: workspace.schema.goToMap,
                blockNotifications: workspace.schema.blockNotifications,
                itemsForSubmodel: workspace.schema.itemsForSubmodel,
                modules: workspace.modules,
                userBlocksCount: workspace.schema.userBlocksCount,
            };
            await setDBStack({ type: action.type, payload: options });
            dispatch(UndoRedoStatus());
        },
    });
};
