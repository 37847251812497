import { ChangeEvent, FC, MutableRefObject } from 'react';

import { SFileManagerSearch } from './SFileManager';

import { uiColors } from '../../config/config';
import { IconButton } from '../IconButton/IconButton';
import { InputField } from '../InputField/InputField';

export const FileManagerSearch: FC<{
    onSearch: (value: string) => void;
    searchFieldRef: MutableRefObject<HTMLInputElement | null>;
}> = ({ onSearch, searchFieldRef }) => {
    const handleChange = (event: ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        onSearch && onSearch(target.value);
    };

    const handleClear = () => {
        onSearch('');
        if (searchFieldRef.current) {
            searchFieldRef.current.value = '';
        }
    };

    return (
        <SFileManagerSearch>
            <InputField ref={searchFieldRef} onChange={handleChange} />
            {searchFieldRef.current !== null && searchFieldRef.current?.value.length > 0 && (
                <IconButton fill={uiColors.darkGrey} noHover name={'close'} onClick={handleClear} />
            )}
        </SFileManagerSearch>
    );
};
