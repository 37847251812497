import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { sanitizeFilename } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { EUserPermissions, ModelStatus, ModelStatuses, Statuses } from '@repeat/models';
import { generateSourcesCode, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, LoaderDefault, Tooltip, uiColors } from '@repeat/ui-kit';

import { messages } from './translation';

const DOWNLOAD_LOADER_ICON_SIZE = 26;

export const MenuCodeGenerationItem: FC<{ projectId: number; projectName: string }> = ({ projectId, projectName }) => {
    const { formatMessage } = useIntl();

    const dispatch = useAppDispatch();

    const livePermissions = useAppSelector((state) => state.workspace.livePermissions.permissions);
    const modelStatus = useAppSelector<ModelStatus>((state) => state.workspace.modelControl.modelStatus);
    const isDownloading = useAppSelector<boolean>(
        (state) => state.workspace.modelControl.generateSourcesCode.status === Statuses.LOADING
    );

    const [abortController, setAbortController] = useState<AbortController | null>(null);

    useEffect(() => {
        if (abortController && abortController.signal.aborted) {
            setAbortController(new AbortController());
        }

        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [abortController, isDownloading]);

    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        setIsEnabled(modelStatus === ModelStatuses.STOP && livePermissions[EUserPermissions.CODE_GENERATION]);
    }, [modelStatus, livePermissions]);

    const handleDownload = (localUrl: string, fileExtension: string) => {
        const anchor: HTMLAnchorElement = document.createElement('a');
        anchor.href = localUrl;
        anchor.download = `${sanitizeFilename(projectName)}-sources.${fileExtension}`;

        document.body.appendChild(anchor);
        anchor.click();

        URL.revokeObjectURL(localUrl);
    };

    const handleGenerateSources = () => {
        const controller = new AbortController();

        setAbortController(controller);

        dispatch(generateSourcesCode({ projectId, onDownload: handleDownload, abortController: controller }));
    };
    const handleCancelGenerateSources = () => {
        if (abortController) {
            abortController.abort();
        }
        dispatch(workspaceActions.generateSourcesCodeCancel());
    };

    const getTooltipMessage = useCallback(() => {
        if (isDownloading) {
            return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES_CANCEL]);
        }
        if (!isEnabled && livePermissions[EUserPermissions.CODE_GENERATION]) {
            return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES_NOT_AVAILABLE]);
        }
        if (!isEnabled && !livePermissions[EUserPermissions.CODE_GENERATION]) {
            return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES_NO_LIVE_PERMISSION]);
        }

        return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES]);
    }, [isEnabled, isDownloading]);

    return (
        <Tooltip text={getTooltipMessage()}>
            {isDownloading && (
                <LoaderDefault
                    color={uiColors.black}
                    width={DOWNLOAD_LOADER_ICON_SIZE}
                    height={DOWNLOAD_LOADER_ICON_SIZE}
                    onClick={handleCancelGenerateSources}
                />
            )}
            {!isDownloading && (
                <IconButton
                    fill={uiColors.darkGrey}
                    noHover
                    name='fileCode'
                    onClick={() => handleGenerateSources()}
                    disabled={!isEnabled}
                />
            )}
        </Tooltip>
    );
};
