import { FC } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { ApplicationActions } from '@repeat/common-slices';
import { IS_USE_USER_BLOCKS } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, TModal } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Icon, IconButton, SSidebarList, pxToRem } from '@repeat/ui-kit';

import { SSeparationLine } from './styled/SSeparationLine';
import { projectMessages as messages } from './translations';

const { production } = environment || { production: false };
const isDevelopmentEnv = !production;
const buttonStyles = {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: `${pxToRem(16)} 0`,
};

const pages = {
    demoProjects: '/projects/demo',
    projects: '/projects',
    favorites: '/projects/favorites',
    userBlocks: '/projects/userBlocks',
};

export const ProjectsToolbar: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const projectsState = useAppSelector((state) => state.projects);
    const isEngineer = useAppSelector((state) => state.appUser.currentUser?.settings?.isEngineer);
    const intl = useIntl();

    const handleCreateProjectClick = () => {
        const modal: TModal = {
            type: ModalTypes.PROJECT_CREATE,
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    const handleImportProjectClick = () => {
        const modal: TModal = {
            type: ModalTypes.PROJECT_IMPORT,
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    const isEngineerAndDevEnv = isEngineer && isDevelopmentEnv;

    const handleDemoProjectOpen = () => {
        navigate('demo');
    };
    const handleUserBlocksOpen = () => {
        navigate('userBlocks');
    };

    const handleProjectOpen = () => {
        navigate('/');
    };
    const handlePageOpen = (path: string) => {
        if (path) {
            return navigate(path);
        }
        return navigate('/');
    };

    return (
        <SSidebarList>
            {location.pathname === pages.projects && (
                <>
                    <IconButton
                        data-tour={'create-new-project'}
                        noHover
                        style={buttonStyles}
                        name={'newProject'}
                        text={intl.formatMessage(messages[TranslationKey.PROJECT_NEW])}
                        onClick={handleCreateProjectClick}
                    />
                    <IconButton
                        noHover
                        style={buttonStyles}
                        name={'import'}
                        text={intl.formatMessage(messages[TranslationKey.PROJECT_IMPORT])}
                        onClick={handleImportProjectClick}
                    />
                    <IconButton
                        noHover
                        style={buttonStyles}
                        name={'bookmark'}
                        text={intl.formatMessage(messages[TranslationKey.PAGE_DEMO_PROJECTS_TITLE])}
                        onClick={() => handlePageOpen('demo')}
                    />
                    <IconButton
                        noHover
                        style={buttonStyles}
                        name={'favProject'}
                        text={intl.formatMessage(messages[TranslationKey.PAGE_FAV_PROJECTS_TITLE])}
                        onClick={() => handlePageOpen('favorites')}
                    />
                    {IS_USE_USER_BLOCKS && (
                        <IconButton
                            noHover
                            style={buttonStyles}
                            name={'folderManaged'}
                            text={intl.formatMessage(messages[TranslationKey.USER_BLOCKS_LIST_TITLE])}
                            onClick={handleUserBlocksOpen}
                        />
                    )}
                </>
            )}
            {(location.pathname === pages.demoProjects ||
                location.pathname === pages.favorites ||
                location.pathname === pages.userBlocks) && (
                <IconButton
                    noHover
                    style={buttonStyles}
                    name={'projects'}
                    text={intl.formatMessage(messages[TranslationKey.PAGE_PROJECTS_TITLE])}
                    onClick={() => handlePageOpen('')}
                />
            )}

            {isEngineerAndDevEnv && (
                <>
                    <SSeparationLine />
                    <NavLink
                        to={{
                            pathname: '/engineer',
                        }}
                        key='engineer-item'
                    >
                        <Icon name='blockList' fill='mainBlue' />{' '}
                        {intl.formatMessage(messages[TranslationKey.PAGE_ENGINEER_TITLE])}
                    </NavLink>
                </>
            )}
        </SSidebarList>
    );
};
