import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.FILTER_BY_NAME]: {
        id: TranslationKey.FILTER_BY_NAME,
        defaultMessage: ru[TranslationKey.FILTER_BY_NAME],
    },
    [TranslationKey.ERROR_LIBRARY_ITEMS]: {
        id: TranslationKey.ERROR_LIBRARY_ITEMS,
        defaultMessage: ru[TranslationKey.ERROR_LIBRARY_ITEMS],
    },
    [TranslationKey.ELECTROCITY]: {
        id: TranslationKey.ELECTROCITY,
        defaultMessage: ru[TranslationKey.ELECTROCITY],
    },
    [TranslationKey.ELECTROCITY_DC]: {
        id: TranslationKey.ELECTROCITY_DC,
        defaultMessage: ru[TranslationKey.ELECTROCITY_DC],
    },
    [TranslationKey.AUTO]: {
        id: TranslationKey.AUTO,
        defaultMessage: ru[TranslationKey.AUTO],
    },
    [TranslationKey.THERMOHYDRAULICS]: {
        id: TranslationKey.THERMOHYDRAULICS,
        defaultMessage: ru[TranslationKey.THERMOHYDRAULICS],
    },
    [TranslationKey.COMMON]: {
        id: TranslationKey.COMMON,
        defaultMessage: ru[TranslationKey.COMMON],
    },
    [TranslationKey.DEVELOPMENT]: {
        id: TranslationKey.DEVELOPMENT,
        defaultMessage: ru[TranslationKey.DEVELOPMENT],
    },
    [TranslationKey.SIMULATION]: {
        id: TranslationKey.SIMULATION,
        defaultMessage: ru[TranslationKey.SIMULATION],
    },
    [TranslationKey.SYSTEM_DESIGN]: {
        id: TranslationKey.SYSTEM_DESIGN,
        defaultMessage: ru[TranslationKey.SYSTEM_DESIGN],
    },
    [TranslationKey.EXTERNAL_MODELS]: {
        id: TranslationKey.EXTERNAL_MODELS,
        defaultMessage: ru[TranslationKey.EXTERNAL_MODELS],
    },
    [TranslationKey.CODE_GENERATION]: {
        id: TranslationKey.CODE_GENERATION,
        defaultMessage: ru[TranslationKey.CODE_GENERATION],
    },
    [TranslationKey.MECHANICS]: {
        id: TranslationKey.MECHANICS,
        defaultMessage: ru[TranslationKey.MECHANICS],
    },
    [TranslationKey.ELECTRIC_DRIVE]: {
        id: TranslationKey.ELECTRIC_DRIVE,
        defaultMessage: ru[TranslationKey.ELECTRIC_DRIVE],
    },
    [TranslationKey.HEAT_EXCHANGE]: {
        id: TranslationKey.HEAT_EXCHANGE,
        defaultMessage: ru[TranslationKey.HEAT_EXCHANGE],
    },
    [TranslationKey.ELECTRONICS]: {
        id: TranslationKey.ELECTRONICS,
        defaultMessage: ru[TranslationKey.ELECTRONICS],
    },
    [TranslationKey.ISOTHERMAL_HYDRAULICS]: {
        id: TranslationKey.ISOTHERMAL_HYDRAULICS,
        defaultMessage: ru[TranslationKey.ISOTHERMAL_HYDRAULICS],
    },
    [TranslationKey.PNEUMATICS]: {
        id: TranslationKey.PNEUMATICS,
        defaultMessage: ru[TranslationKey.PNEUMATICS],
    },
    [TranslationKey.PROJECT_LIBRARY]: {
        id: TranslationKey.PROJECT_LIBRARY,
        defaultMessage: ru[TranslationKey.PROJECT_LIBRARY],
    },
    [TranslationKey.ELEMENT_IS_BLOCKED]: {
        id: TranslationKey.ELEMENT_IS_BLOCKED,
        defaultMessage: ru[TranslationKey.ELEMENT_IS_BLOCKED],
    },
    [TranslationKey.LIBRARY_NEW_BLOCK]: {
        id: TranslationKey.LIBRARY_NEW_BLOCK,
        defaultMessage: ru[TranslationKey.LIBRARY_NEW_BLOCK],
    },
    [TranslationKey.USER_BLOCKS_LIST_TITLE]: {
        id: TranslationKey.USER_BLOCKS_LIST_TITLE,
        defaultMessage: ru[TranslationKey.USER_BLOCKS_LIST_TITLE],
    },
});
