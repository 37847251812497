import { FC, FocusEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef } from 'react';

import { fileManagerCheckRule } from './FileManagerHelpers';
import { EFileManagerItemType, EFileManagerRule, IFileManagerItem } from './FileManagerTypes';
import { SFileManagerItem, SFileManagerItemFavorite, SFileManagerItemHelp, SFileManagerItemName } from './SFileManager';

import { CustomCheckBox } from '../CheckBox/CheckBox';
import { IContextMenuItem } from '../ContextMenu/ContextMenu';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

export const FileManagerItem: FC<IFileManagerItem> = ({
    file,
    translations,
    files,
    selectedElements,
    favoriteList,
    rules,
    onSelect,
    onSaveFolderName,
    onEditFolderName,
    onOpenFolder,
    onOpenFile,
    onMove,
    onRemove,
    onExport,
    onUnSelectAll,
    onCheckboxSelect,
    onItemDragging,
    onCopyId,
    onContextMenu,
    onAddToFavorites,
    onDuplicate,
    onManageDemo,
    onAddToRoot,
}) => {
    const { uuid, id, isEdit, ext, type, name } = file;

    const ref = useRef<HTMLDivElement>(null);

    const isInFavoritesList = favoriteList && id && favoriteList?.length > 0 ? favoriteList.includes(id) : false;

    const itemData = {
        id,
        name,
        ext,
        type,
    };

    const handleSaveFolderName = (event: FocusEvent) => {
        const target = event.target as HTMLTextAreaElement;
        if (target.value.length > 0) {
            onSaveFolderName(target.value.trim(), uuid);
        }
    };

    const handleEndEditFolderName = useCallback(
        (event: KeyboardEvent) => {
            if (
                (ref.current && isEdit && event.code === 'Enter') ||
                (ref.current && isEdit && event.code === 'Escape')
            ) {
                const target = ref.current as HTMLElement;
                const textArea = target.querySelector('textarea') as HTMLTextAreaElement;
                if (textArea.value.length > 0) {
                    return onSaveFolderName(textArea.value.trim(), uuid);
                }
            }
        },
        [ref, isEdit]
    );

    useEffect(() => {
        if (ref.current && isEdit) {
            const target = ref.current as HTMLElement;
            const textArea = target.querySelector('textarea') as HTMLTextAreaElement;
            textArea.setSelectionRange(textArea.value.length, textArea.value.length);
        }
    }, [isEdit]);

    const filesActions = [
        ...(fileManagerCheckRule(rules, EFileManagerRule.EXPORT) && onExport
            ? [
                  {
                      icon: 'export',
                      text: translations ? translations.export : '',
                      onClick: (id: number) => {
                          const file = files.find((file) => file.id === id);
                          if (file && file.id) {
                              onExport(file?.id);
                          }
                      },
                  },
              ]
            : []),
        ...(fileManagerCheckRule(rules, EFileManagerRule.COPY_ID) && onCopyId
            ? [
                  {
                      icon: 'copyId',
                      text: translations ? translations.copyId : '',
                      onClick: (id: number) => {
                          onCopyId(id);
                      },
                  },
              ]
            : []),
        ...(fileManagerCheckRule(rules, EFileManagerRule.ADD_TO_FAVORITE)
            ? [
                  {
                      icon: 'starFull',
                      text: (() => {
                          if (translations) {
                              if (!isInFavoritesList) {
                                  return translations.addToFavorites;
                              } else {
                                  return translations.removeFromFavorites;
                              }
                          } else return '';
                      })(),
                      onClick: (id: number) => {
                          if (!isInFavoritesList) {
                              onAddToFavorites && onAddToFavorites(id, true);
                          } else {
                              onAddToFavorites && onAddToFavorites(id, false);
                          }
                      },
                  },
              ]
            : []),
        ...(fileManagerCheckRule(rules, EFileManagerRule.CLONE) && onDuplicate
            ? [
                  {
                      icon: 'copy',
                      text: translations ? translations.duplicate : '',
                      onClick: (id: number) => {
                          onDuplicate(id);
                      },
                  },
              ]
            : []),
        ...(fileManagerCheckRule(rules, EFileManagerRule.MANAGE_DEMO) && onManageDemo
            ? [
                  {
                      icon: 'shareDemo',
                      text: translations ? translations.manageDemo : '',
                      onClick: (id: number) => {
                          onManageDemo(id);
                      },
                  },
              ]
            : []),
        ...(fileManagerCheckRule(rules, EFileManagerRule.ADD_TO_ROOT) && onAddToRoot
            ? [
                  {
                      icon: 'copy',
                      text: translations ? translations.addToRoot : '',
                      onClick: (id: number) => {
                          onAddToRoot(id);
                      },
                  },
              ]
            : []),
    ];

    const contextMenuItems: IContextMenuItem[] = [
        ...(fileManagerCheckRule(rules, EFileManagerRule.OPEN)
            ? [
                  {
                      icon: 'play',
                      text: translations ? translations.open : '',
                      onClick: (id: number) => {
                          if (type !== EFileManagerItemType.FILE) {
                              id && onOpenFolder(id);
                          } else {
                              id && onOpenFile(id);
                          }
                      },
                  },
              ]
            : []),
        ...(type === EFileManagerItemType.FILE ? filesActions : []),
        ...(fileManagerCheckRule(rules, EFileManagerRule.EDIT)
            ? [
                  {
                      icon: 'edit',
                      text: translations ? translations.edit : '',
                      onClick: (id: number) => {
                          const file = files.find((file) => file.id === id);
                          if (file) {
                              onEditFolderName(file.uuid);
                          }
                      },
                  },
              ]
            : []),
        ...(fileManagerCheckRule(rules, EFileManagerRule.REMOVE)
            ? [
                  {
                      icon: 'delete',
                      text: translations ? translations.remove : '',
                      onClick: () => {
                          onRemove && onRemove();
                      },
                  },
              ]
            : []),
    ];

    return (
        <SFileManagerItem
            className={'file-manager__item'}
            ref={ref}
            draggable={true}
            data-active={!isEdit && selectedElements.includes(uuid)}
            data-file-manager-uuid={uuid}
            data-file-manager-id={id}
            data-file-manager-type={type}
            onDrop={(event) => {
                onMove(event);
            }}
            onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                if (!event.ctrlKey) {
                    onUnSelectAll();
                }
                !isEdit && onSelect(uuid, event);
            }}
            onDoubleClick={(event) => {
                if (!isEdit && id) {
                    if (type !== EFileManagerItemType.FILE) {
                        return onOpenFolder(id);
                    } else {
                        return onOpenFile(id);
                    }
                }
                event.stopPropagation();
                event.preventDefault();
            }}
            onDragEnd={() => {
                onItemDragging(false);
            }}
            onDragStart={(event) => {
                if (!isEdit) {
                    onItemDragging(true);
                    if (selectedElements.length > 1) {
                        return event.dataTransfer.setData(
                            'elements',
                            JSON.stringify(files.filter((file) => selectedElements.includes(file.uuid)))
                        );
                    }
                    onSelect(uuid);
                    return event.dataTransfer.setData('element', JSON.stringify(itemData));
                }
                event.stopPropagation();
                event.preventDefault();
            }}
            onDragOver={(event) => {
                event.preventDefault();
            }}
            onContextMenu={(event: React.MouseEvent) => {
                event.preventDefault();
                onUnSelectAll();
                onSelect(uuid);
                if (id) {
                    onContextMenu(event, id, contextMenuItems);
                }
            }}
        >
            <SFileManagerItemHelp>
                <Icon name={'rightMouse'} />
            </SFileManagerItemHelp>
            <CustomCheckBox
                checked={selectedElements.includes(uuid)}
                onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                    onCheckboxSelect(uuid);
                }}
            />
            <IconButton noHover={true} name={type === EFileManagerItemType.FILE ? 'fileNoName' : 'folder'} />
            {name && !isEdit && (
                <SFileManagerItemName>
                    {name}
                    {ext && <small>.{ext}</small>}
                </SFileManagerItemName>
            )}
            {isEdit && (
                <textarea
                    maxLength={100}
                    onKeyUp={(event: KeyboardEvent) => handleEndEditFolderName(event)}
                    autoFocus={true}
                    defaultValue={name}
                    onBlur={(event) => handleSaveFolderName(event)}
                />
            )}
            <SFileManagerItemFavorite>
                {favoriteList && id && isInFavoritesList && <Icon name={isInFavoritesList ? 'starFull' : 'star'} />}
            </SFileManagerItemFavorite>
        </SFileManagerItem>
    );
};
