import { ElemParams, ElemProps, TPropertyVisibilityCondition, TProxyMap } from './element';
import { TPortConnectionType } from './libraryPortConnection';
import { IModulesCounter } from './project';
import { Status } from './status';

export interface ILibraryItemValidationRule {
    type: string;
    value?: number;
}

export enum PortDirections {
    INPUT = 'input',
    OUTPUT = 'output',
}
export type TPortDirection = PortDirections.INPUT | PortDirections.OUTPUT;

export type TPortTypeComplex = {
    type: TPortConnectionType;
    compatibleTypes: TPortConnectionType[];
    direction: TPortDirection;
};

export enum PortPositions {
    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    LEFT = 'left',
}
export type TPortPosition = PortPositions.TOP | PortPositions.RIGHT | PortPositions.BOTTOM | PortPositions.LEFT;

export enum PortTypes {
    INPUT = 'input',
    OUTPUT = 'output',
}
export type TPortType = PortTypes.INPUT | PortTypes.OUTPUT;

export type TLibraryItemPort = {
    name: string;
    position: TPortPosition;
    libraries: TLibraryType[];
    type: TPortType; // is deprecated
    typeConnection: TPortConnectionType;
    visibilityConditions?: TPropertyVisibilityCondition[];
};

export interface ILibraryItemViewData {
    isRotatable: boolean;
    isImageRotatable: boolean;
    minWidth?: number;
    minHeight?: number;
    portMargin?: number;
    isBackground?: boolean | string;
    isResizable?: boolean;
}

export interface ILibraryItem {
    id?: string;
    blockId?: string | null;
    image?: string | null;
    picId: number;
    name: string;
    aliases: string[];
    shortName: string;
    index?: string;
    type: string;
    subtype?: string;
    library: TLibraryType;
    subLibrary?: string;
    elemParams: ElemParams[];
    elemProps: ElemProps[];
    solver: TSolverType;
    description: string;
    isIndicator?: boolean;
    isActive: boolean;
    isDisabled?: boolean;
    view: ILibraryItemViewData;
    rules?: ILibraryItemValidationRule[];
    hasConfigurations: boolean;
    stateParameters: ElemParams[];
    availablePorts: TLibraryItemPort[];
    parametersToDisplay: string[] | null;
    propertiesToDisplay: string[] | null;
    isFromFile?: boolean;
    isViewOnly: boolean;
    // TODO remove props below
    portsAmount: number;
    hasPorts: boolean;
    hasManagedPorts: boolean;
    isNew: boolean;
    createdAt?: string;
    updatedAt?: string;
    isDeprecated?: boolean;
    maintainedTo?: string;
    userId?: number | null;
    proxyMap?: TProxyMap | null;
    hash?: string;
    modules?: IModulesCounter;
}
export interface ILibraryItemsState {
    items: ILibraryItem[];
    status: Status;
    error: string | null;
}
export interface ILibraryPortTypesState {
    items: TPortTypeComplex[];
    status: Status;
    error: string | null;
}
export interface IDraggableLibraryItemState {
    item: ILibraryItem;
}

export enum SolverTypes {
    USDS = 'usds',
    JAUTO = 'jauto',
    MDCORE = 'mdcore',
}
export enum LibraryTypes {
    ELECTROCITY = 'electrocity',
    ELECTROCITY_DC = 'electrocitydc',
    MDLIBRARY = 'mdlibrary',
    AUTO = 'auto',
    THERMOHYDRAULICS = 'thermohydraulics',
    SIMULATION = 'simulation',
    SYSTEM_DESIGN = 'systemDesign',
    EXTERNAL_MODELS = 'externalModels',
    COMMON = 'common',
    DEVELOPMENT = 'development',
    MECHANICS = 'mechanics',
    ELECTRIC_DRIVE = 'electricDrive',
    HEAT_EXCHANGE = 'heatExchange',
    ELECTRONICS = 'electronics',
    ISOTHERMAL_HYDRAULICS = 'isothermalHydraulics',
    PNEUMATICS = 'pneumatics',
    CODE_GENERATION = 'codegeneration',
    CUSTOM = 'custom',
}

export type TLibraryType =
    | LibraryTypes.ELECTROCITY
    | LibraryTypes.ELECTROCITY_DC
    | LibraryTypes.AUTO
    | LibraryTypes.THERMOHYDRAULICS
    | LibraryTypes.SIMULATION
    | LibraryTypes.SYSTEM_DESIGN
    | LibraryTypes.MDLIBRARY
    | LibraryTypes.EXTERNAL_MODELS
    | LibraryTypes.MECHANICS
    | LibraryTypes.ELECTRIC_DRIVE
    | LibraryTypes.HEAT_EXCHANGE
    | LibraryTypes.COMMON
    | LibraryTypes.DEVELOPMENT
    | LibraryTypes.ELECTRONICS
    | LibraryTypes.ISOTHERMAL_HYDRAULICS
    | LibraryTypes.PNEUMATICS
    | LibraryTypes.CODE_GENERATION
    | LibraryTypes.CUSTOM;

export type TSolverType = SolverTypes.USDS | SolverTypes.JAUTO | SolverTypes.MDCORE;

export type ItemsBar = ILibraryItem[];

export interface ILibraryItemImportData {
    file: File;
}

export interface IEngineerBlockSortOptions {
    column: keyof Pick<ILibraryItem, 'name' | 'type' | 'id'>;
    direction: 'asc' | 'desc';
}
