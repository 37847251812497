import { useCallback, useContext, useEffect, useState } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import { useModulesSort, useNavigationPrompt } from '@repeat/common-hooks';
import {
    getLocalStorageState,
    IS_USE_LIBRARY_FROM_DB,
    isLocalStorageState,
    monoLogoUrl,
    setLocalStorageState,
    VERSION,
} from '@repeat/constants';
import { useAppDispatch, useAppSelector, useDemoMode, useGetTariffData, useProjectId } from '@repeat/hooks';
import { SchemaItemTypes, SolverTypes, WorkspaceModes } from '@repeat/models';
import {
    appUserSelectors,
    closeProject,
    initializeWorkspace,
    uninitializeWorkspace,
    workspaceActions,
    workspaceSelectors,
} from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import {
    Dropdown,
    Footer,
    Header,
    Icon,
    IconButton,
    Layout,
    LoaderFullscreen,
    Main as MainBlock,
    pxToRem,
    Sidebar,
    Tooltip,
    TourContext,
} from '@repeat/ui-kit';

import { workspaceMessages } from './translation';

import { ModalProvider } from '../../features/BlockDescriptionModal/BlockDescriptionModalContext';
import { LeftBar } from '../../features/LeftBar/LeftBar';
import { ModelControl } from '../../features/ModelControl/ModelControl';
import { Navigation } from '../../features/Navigation/Navigation';
import { SLivePermissionStatus, SNavigationDropDownFirstItem } from '../../features/Navigation/SNavigation';
import { ProjectSettings, ProjectSettingsItemTypes } from '../../features/ProjectSettings/ProjectSettings';
import { RightBar } from '../../features/RightBar/RightBar';
import { Visualization } from '../../features/Visualization/Visualization';
import { Canvas } from '../../features/Workspace/Canvas/Canvas';
import { CodeEditor } from '../../features/Workspace/CodeEditor/CodeEditor';
import { EventLog } from '../../features/Workspace/EventLog/EventLog';
import { Tools } from '../../features/Workspace/Tools/Tools';
import { UndoRedoButtons } from '../../features/Workspace/UndoRedoButtons/UndoRedoButtons';
import { Variables } from '../../features/Workspace/Variables/Variables';
import { WorkspaceProjectMenu } from '../../features/Workspace/WorkspaceProjectMenu/WorkspaceProjectMenu';

export const WorkspacePage = () => {
    const navigate = useNavigate();

    const [isFooterActive, setIsFooterActive] = useState(getLocalStorageState('footer'));
    const [activeFooterTab, setActiveFooterTab] = useState(() => {
        const activeTab = localStorage.getItem('footer-tab');
        if (activeTab && typeof activeTab === 'string') {
            return parseInt(activeTab);
        }
        return 0;
    });
    const [isFooterFullscreen, setIsFooterFullscreen] = useState(() => {
        return getLocalStorageState('footer-fullscreen');
    });
    const { formatMessage, locale } = useIntl();

    const { projectId, userBlockId } = useProjectId();
    const dispatch = useAppDispatch();
    const userIsAuth = useAppSelector(appUserSelectors.isAuth);
    const { isInitialized, isLoading, mode, elementId } = useAppSelector((state) => state.workspace.meta);
    const { solverType } = useAppSelector((state) => state.workspace.schema);
    const { charts } = useAppSelector((state) => state.workspace.graphs);
    const { tools, globalVariables } = useAppSelector((state) => state.workspace.settings);
    const currentUserPermissions = useAppSelector(appUserSelectors.currentUserPermissions);
    const modules = useAppSelector((state) => state.workspace.modules);
    const { permissions, isConnected } = useAppSelector(workspaceSelectors.livePermissions);
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);

    const { isDemo } = useDemoMode([workspaceMetaUserBlockId, isUserBlockEditorMode]);

    const { enableScope, disableScope } = useHotkeysContext();

    const { setOpen } = useContext(TourContext);

    useEffect(() => {
        if (!isInitialized && !isLoading) {
            dispatch(initializeWorkspace(IS_USE_LIBRARY_FROM_DB));
        }

        if (isInitialized) {
            enableScope('workspacePage');

            return () => {
                dispatch(uninitializeWorkspace());
                disableScope('workspacePage');
            };
        }
        return;
    }, [isInitialized, isLoading]);

    useEffect(() => {
        if (isInitialized && solverType && ![SolverTypes.MDCORE, SolverTypes.JAUTO].includes(solverType)) {
            navigate('/projects');
        }
    }, [isInitialized, solverType]);

    useNavigationPrompt(`${formatMessage(workspaceMessages[TranslationKey.LEAVE_PAGE_MESSAGE])}`, true, () => {
        if (projectId || userBlockId) {
            dispatch(closeProject());
        }
    });

    const handleFooter = (arg: boolean) =>
        setIsFooterActive(() => {
            setLocalStorageState('footer', arg);
            return arg;
        });

    const handleTabChangeFooter = (state: number) => {
        setActiveFooterTab(state);
        setLocalStorageState('footer-tab', state);
    };

    const handleFullscreenFooter = (state: boolean) => {
        setIsFooterFullscreen(state);
        setLocalStorageState('footer-fullscreen', state);
    };

    const handleOpenFooter = () => {
        const sidebar = document.querySelector('div[data-name="elements-container"]') as HTMLDivElement;
        const searchBar = document.querySelector('div[data-name="elements-search"]') as HTMLDivElement;
        const rightBar = document.querySelector('div[data-tour="right-sidebar"]') as HTMLDivElement;
        const tagCloud = document.querySelector('div[data-name="tag-cloud"]') as HTMLDivElement;
        const elementName = document.querySelector('div[data-name="element-name"]') as HTMLDivElement;
        const elementNameHeight = elementName ? elementName.clientHeight : 0;
        if (sidebar !== null) {
            if (tagCloud && elementName) {
                const height =
                    window.innerHeight / 2 - (tagCloud.clientHeight + elementNameHeight + searchBar.clientHeight);
                sidebar.style.maxHeight = `${height / 2}px`;
            }
        }
        if (rightBar !== null) {
            rightBar.style.height = 'calc(100% / 2)';
            rightBar.style.overflowY = 'auto';
        }
    };

    const handleCloseFooter = () => {
        const sidebar = document.querySelector('div[data-name="elements-container"]') as HTMLDivElement;
        const rightBar = document.querySelector('div[data-tour="right-sidebar"]') as HTMLDivElement;
        const tagCloud = document.querySelector('div[data-name="tag-cloud"]') as HTMLDivElement;
        if (sidebar !== null) {
            if (tagCloud) {
                sidebar.style.maxHeight = `calc(100vh - ${tagCloud?.clientHeight}px)`;
            } else {
                sidebar.style.maxHeight = 'initial';
            }
        }
        if (rightBar !== null) {
            rightBar.style.height = `auto`;
        }
    };

    const handleClearSelection = () => {
        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
    };

    const footerTabs = [
        {
            name: 'visualization',
            text: `${formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_VISUALIZATION])}`,
            content: <Visualization />,
            disabled: isUserBlockEditorMode,
            ...(charts && { badge: Object.keys(charts).length }),
        },
        {
            name: 'journal',
            text: formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_JOURNAL]),
            content: <EventLog />,
            disabled: isUserBlockEditorMode,
        },
        {
            name: 'variable',
            text: formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_VARIABLES]),
            content: <Variables />,
            disabled: isUserBlockEditorMode,
            ...(globalVariables &&
                Object.keys(globalVariables).length > 0 && { badge: Object.keys(globalVariables).length }),
        },
        {
            name: 'browse',
            text: formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_TOOLS]),
            content: <Tools />,
            disabled: isUserBlockEditorMode,
            ...(tools && { badge: Object.keys(tools).length }),
        },
    ];

    const { userModules } = useGetTariffData();
    const sorted = useModulesSort(currentUserPermissions || []);

    const getSidebarIsOpen = useCallback(
        (sidebarType: string) => {
            if (mode === WorkspaceModes.CODE_EDITOR) {
                return sidebarType !== 'left-bar';
            }

            return isLocalStorageState(sidebarType) ? getLocalStorageState(sidebarType) : true;
        },
        [mode]
    );

    if (!isInitialized) {
        return <LoaderFullscreen />;
    }
    const isModelingAvailable = !isUserBlockEditorMode;

    return (
        <ReactFlowProvider>
            <Layout withFooter withRightSidebar>
                <Header
                    Navigation={userIsAuth && <Navigation setTourOpen={(open) => setOpen(open)} />}
                    version={VERSION}
                    logoUrl={monoLogoUrl(locale)}
                    indexUrl='/projects'
                >
                    {isModelingAvailable && <ModelControl />}
                </Header>
                <Sidebar
                    open={getSidebarIsOpen('left-bar')}
                    addon={<WorkspaceProjectMenu />}
                    onMount={(e) => {
                        const isLeftBarOpen = localStorage.getItem('left-bar');
                        if (isLeftBarOpen === null) {
                            setLocalStorageState('left-bar', true);
                        }
                    }}
                    onTriggerClick={(e) => setLocalStorageState('left-bar', e)}
                >
                    <LeftBar />
                </Sidebar>
                <ModalProvider>
                    <Sidebar
                        open={getSidebarIsOpen('right-bar')}
                        onMount={(e) => {
                            const isRightBarOpen = localStorage.getItem('right-bar');
                            if (isRightBarOpen === null) {
                                setLocalStorageState('right-bar', true);
                            }
                        }}
                        onTriggerClick={(e) => setLocalStorageState('right-bar', e)}
                        position={'RIGHT'}
                        addon={
                            (mode === WorkspaceModes.MAIN ||
                                mode === WorkspaceModes.SUBMODEL ||
                                mode === WorkspaceModes.GROUP) && (
                                <>
                                    {!isDemo && (
                                        <>
                                            <Tooltip
                                                text={formatMessage(
                                                    workspaceMessages[TranslationKey.WORKSPACE_LIVE_PERMISSION_MODULES]
                                                )}
                                                style={{ maxWidth: '130px', marginRight: 0 }}
                                            >
                                                <Dropdown
                                                    onMouseOver={(event: React.MouseEvent) => event.stopPropagation()}
                                                    clickOutside
                                                    trigger={<SLivePermissionStatus status={isConnected} />}
                                                >
                                                    {permissions &&
                                                        Object.keys(permissions).map((permission, index) => (
                                                            <SNavigationDropDownFirstItem
                                                                key={`available-modules-permission-${permission}-${index}`}
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <span>
                                                                    {formatMessage({
                                                                        id: `${userModules(permission)}`,
                                                                    })}
                                                                    &nbsp;
                                                                </span>
                                                                <SLivePermissionStatus
                                                                    size={'small'}
                                                                    status={
                                                                        permissions[
                                                                            permission as keyof typeof permissions
                                                                        ]
                                                                    }
                                                                />
                                                            </SNavigationDropDownFirstItem>
                                                        ))}
                                                </Dropdown>
                                            </Tooltip>
                                            <Tooltip
                                                text={formatMessage(
                                                    workspaceMessages[TranslationKey.WORKSPACE_INVOLVED_MODULES]
                                                )}
                                                style={{ maxWidth: '130px', marginRight: `${pxToRem(16)}` }}
                                            >
                                                <Dropdown
                                                    onMouseOver={(event: React.MouseEvent) => event.stopPropagation()}
                                                    clickOutside
                                                    trigger={<Icon name='modules' fill='white' />}
                                                >
                                                    {sorted?.map((permission, index) => (
                                                        <SNavigationDropDownFirstItem
                                                            key={`user-modules-permission-${permission}-${index}`}
                                                        >
                                                            <span>
                                                                {formatMessage({
                                                                    id: `${userModules(permission)}`,
                                                                })}
                                                                :&nbsp;
                                                            </span>
                                                            {modules !== null && modules[permission] ? (
                                                                <span>{modules[permission]}</span>
                                                            ) : (
                                                                0
                                                            )}
                                                        </SNavigationDropDownFirstItem>
                                                    ))}
                                                </Dropdown>
                                            </Tooltip>
                                        </>
                                    )}
                                    {!workspaceMetaUserBlockId && (
                                        <>
                                            <Tooltip
                                                style={{ maxWidth: '130px', marginRight: `${pxToRem(16)}` }}
                                                text={`${formatMessage(
                                                    workspaceMessages[TranslationKey.WORKSPACE_MEMORY_USAGE]
                                                )}, ${formatMessage(
                                                    workspaceMessages[TranslationKey.WORKSPACE_MEMORY_LIMIT]
                                                )}`}
                                            >
                                                <ProjectSettings
                                                    mode={ProjectSettingsItemTypes.COMPACT}
                                                    onClick={handleClearSelection}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                text={formatMessage(
                                                    workspaceMessages[TranslationKey.WORKSPACE_PAGE_VISUALIZATION]
                                                )}
                                            >
                                                <IconButton
                                                    data-tour='workspace-visualization-trigger'
                                                    noHover
                                                    name={'visualization'}
                                                    onClick={() => {
                                                        handleFooter(!isFooterActive);
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    )}
                                    <UndoRedoButtons />
                                </>
                            )
                        }
                    >
                        <RightBar />
                    </Sidebar>
                </ModalProvider>
                <MainBlock>
                    {(mode === WorkspaceModes.MAIN ||
                        mode === WorkspaceModes.SUBMODEL ||
                        mode === WorkspaceModes.GROUP) && <Canvas />}

                    {mode === WorkspaceModes.CODE_EDITOR && elementId !== null && <CodeEditor elementId={elementId} />}
                </MainBlock>
                <Footer
                    onMount={() => {
                        if (isFooterActive) {
                            setTimeout(handleOpenFooter, 500);
                        }
                    }}
                    isFullscreen={isFooterFullscreen}
                    onFullScreen={(state) => handleFullscreenFooter(state)}
                    onTabChange={(state) => handleTabChangeFooter(state)}
                    onOpen={() => handleOpenFooter()}
                    onClose={() => handleCloseFooter()}
                    setIsActiveHandler={handleFooter}
                    activeTab={activeFooterTab}
                    isActive={isFooterActive}
                    tabs={footerTabs}
                />
            </Layout>
        </ReactFlowProvider>
    );
};
