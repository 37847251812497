import { FC, memo } from 'react';
import { useIntl } from 'react-intl';

import { ModalTypes, TUserBlockSaveModal } from 'libs/models/src/lib/modal';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { TSchemaGroup, TSchemaNode, WorkspaceModes } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { SContextMenuItem, SContextMenuContainer, SContextMenu } from '@repeat/ui-kit';

import { messages } from './translation';

export interface IContextMenu {
    id: string;
    top: number;
    left: number;
    right?: number;
    bottom?: number;
    onDelete?: (id: string) => void;
    onGroupClick?: () => void;
    isGroup?: boolean;
    ref?: null | HTMLDivElement | any;
    isBlockSavingAvailable?: boolean;
    element: TSchemaNode;
    groups: TSchemaGroup[];
}

const ContextMenu: FC<IContextMenu> = memo(
    ({
        id,
        top,
        left,
        right,
        bottom,
        onDelete,
        onGroupClick,
        isGroup,
        isBlockSavingAvailable,
        element,
        groups,
        ...props
    }) => {
        const { formatMessage } = useIntl();
        const dispatch = useAppDispatch();

        const mode = useAppSelector(workspaceSelectors.workspaceMode);

        const handleSaveAsBlock = () => {
            const modal: TUserBlockSaveModal = {
                type: ModalTypes.USER_BLOCK_SAVE,
                data: { groups, element },
            };
            dispatch(ApplicationActions.showModal({ modal }));
        };

        return (
            <SContextMenuContainer>
                <SContextMenu visible={true} style={{ top, left, right, bottom }} className='context-menu' {...props}>
                    {isGroup && (
                        <SContextMenuItem onClick={() => onGroupClick && onGroupClick()}>
                            {formatMessage(messages[TranslationKey.GROUP_NEW])}
                        </SContextMenuItem>
                    )}
                    {isBlockSavingAvailable && mode === WorkspaceModes.MAIN && (
                        <SContextMenuItem onClick={handleSaveAsBlock}>
                            {formatMessage(messages[TranslationKey.GROUP_SAVE_AS_BLOCK])}
                        </SContextMenuItem>
                    )}
                    <SContextMenuItem onClick={() => onDelete && onDelete(id)}>
                        {formatMessage(messages[TranslationKey.DELETE])}
                    </SContextMenuItem>
                </SContextMenu>
            </SContextMenuContainer>
        );
    }
);

export default ContextMenu;
