import { IFileManagerDefaultRules, IFileManagerRules, TFileManagerElement, TFileManagerRule } from './FileManagerTypes';

export const DEFAULT_RULES: IFileManagerRules = {
    open: true,
    edit: true,
    clone: true,
    import: true,
    export: true,
    remove: true,
    select: true,
    copyId: true,
    duplicate: true,
    createFile: true,
    addToFavorite: true,
    createDirectory: true,
    manageDemo: true,
    addToRoot: true,
};

export const setDefaultRules = (rules: IFileManagerDefaultRules): IFileManagerRules => {
    return { ...DEFAULT_RULES, ...rules };
};

export const fileManagerCheckRule = (rules: IFileManagerRules, rule: TFileManagerRule) => rules && rules[rule];

export const isNameUnique = (
    file: TFileManagerElement,
    files: TFileManagerElement[],
    existingNames: string[],
    newName: string
): boolean => {
    if (file.name === newName) {
        return true;
    }

    const otherFileNames = files.filter((f) => f.uuid !== file.uuid && f.type === file.type).map((f) => f.name);

    if (otherFileNames.includes(newName)) {
        return false;
    }

    const existingNamesForType = existingNames.filter((name) => {
        const existingFile = files.find((f) => f.name === name);
        return existingFile && existingFile.type === file.type;
    });

    return !existingNamesForType.includes(newName);
};
