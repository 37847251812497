import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormattedDate } from '@components/FormattedDate/FormattedDate';

import { DATE_FORMAT } from '@repeat/constants';
import { ElementNotificationTypes, TElementNotificationType, TSchemaNode } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { uiColors } from '@repeat/ui-kit';

import { SAttentionMessage } from './../features/Workspace/Canvas/Elements/ElementNotification/SAttentionMessage';
import { messages } from './../features/Workspace/Canvas/Elements/ElementNotification/translation';

export const useElementNotificationMessage = (
    notificationType: TElementNotificationType | null,
    currentNode?: TSchemaNode
) => {
    const { formatMessage } = useIntl();

    const maintainedDate = currentNode?.data.maintainedTo;

    const getMessage = useCallback((type: TElementNotificationType) => {
        switch (type) {
            case ElementNotificationTypes.NEED_TO_UPDATE:
                return (
                    <SAttentionMessage>
                        {formatMessage(messages[TranslationKey.ELEMENT_NOT_SUPPORTED])}
                    </SAttentionMessage>
                );

            case ElementNotificationTypes.IS_DEPRECATED:
                return (
                    <SAttentionMessage>
                        {formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED])}
                        <FormattedDate date={maintainedDate ? maintainedDate : ''} format={DATE_FORMAT} />
                    </SAttentionMessage>
                );

            case ElementNotificationTypes.PROJECT_IS_CHANGED:
                return (
                    <SAttentionMessage color={uiColors.orange}>
                        {formatMessage(messages[TranslationKey.WORKSPACE_WARNING_PROJECT_CHANGED])}
                    </SAttentionMessage>
                );
            case ElementNotificationTypes.IS_ERROR_GETTING_PROJECT:
                return (
                    <SAttentionMessage>
                        {formatMessage(messages[TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA])}
                    </SAttentionMessage>
                );
            case ElementNotificationTypes.GOTO_WITHOUT_PAIR:
                return (
                    <SAttentionMessage>
                        {formatMessage(messages[TranslationKey.ELEMENT_HAS_NO_GOTO_PAIR])}
                    </SAttentionMessage>
                );
            case ElementNotificationTypes.GOTO_NOT_UNIQUE:
                return (
                    <SAttentionMessage>
                        {formatMessage(messages[TranslationKey.ELEMENT_HAS_NOT_UNIQUE_GOTO])}
                    </SAttentionMessage>
                );
            case ElementNotificationTypes.USER_BLOCK_IS_CHANGED:
                return (
                    <SAttentionMessage color={uiColors.orange}>
                        {formatMessage(messages[TranslationKey.USER_BLOCK_CHANGED_NOTIFICATION])}
                    </SAttentionMessage>
                );
            case ElementNotificationTypes.USER_BLOCK_IS_DELETED:
                return (
                    <SAttentionMessage>
                        {formatMessage(messages[TranslationKey.USER_BLOCK_DELETED_NOTIFICATION])}
                    </SAttentionMessage>
                );
            case ElementNotificationTypes.USER_BLOCK_IS_ERROR:
                return (
                    <SAttentionMessage>
                        {formatMessage(messages[TranslationKey.USER_BLOCK_GET_INFO_ERROR])}
                    </SAttentionMessage>
                );
            default:
                return null;
        }
    }, []);

    return {
        getMessage,
    };
};
