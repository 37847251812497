export const CODE_LIST: { [key: number]: string } = {
    7006: 'successful.launch.of.the.project', // Успешный запуск проекта на расчет.
    7007: 'successful.launch.of.the.Selector', // Успешный запуск Селектора.
    7008: 'successful.launch.of.Trends', // Успешный запуск Трендов.
    7009: 'successful.closing.of.the.Selector.channel', // Успешное закрытие канала Селектора.
    7010: 'successful.closing.of.the.Trend.channel', // Успешное закрытие канала Трендов.
    7011: 'successful.setting.of.trend.parameters', // Успешная установка параметров трендов.
    7012: 'successfully.setting.a.new.value.to.the.parameter', // Успешная установка нового значения параметру.
    7014: 'successfully.setting.a.new.variable.value', // Успешная установка нового значения переменной.
    7015: 'successful.provisioning.of.the.virtual.machine', // Успешное выделение виртуальной машины.
    7050: 'successful.shutdown.of.the.project', // Успешная остановка проекта.
    7051: 'successfully.saving.the.project', // Успешное сохранение проекта.
    7052: 'successfully.retrieved.the.last.save', // Успешное получение последнего сохранения.
    7053: 'successful.deletion.of.the.project', // Успешное удаление проекта.
    7054: 'successful.stop.of.the.task', // Успешная остановка задачи.
    7055: 'successful.launch.of.the.task', // Успешная запуск задачи.
    1001: 'error.send.command.stop',
    1057: 'successful.closing.socket.selector',
    6001: 'warning.free.virtual.machine',
    6501: 'warning.max_number_of_running_projects',
    1085: 'error.incorrect.project.schema',
    1101: 'projectId.parameter.is.empty.', // Параметр projectId пуст.
    1102: 'userId.parameter.is.empty.', // Параметр userId пуст.
    1103: 'userId.header.is.empty.', // Заголовок userId пуст.
    1104: 'serviceKey.parameter.is.empty', // Параметр serviceKey пуст.
    1105: 'project.items.are.empty.', // Элементы проекта пусты.
    1106: 'schema.has.an.unsupported.element.type.', // В схеме есть неподдерживаемый тип элемента.
    1107: 'the.input.port.the.output.information', // У входного порта информация выходного.
    1108: 'link.refers.to.a.non-existent.element.', // Связь обращается к несуществующему элементу.
    1109: 'link.accesses.a.non-existent.element.port.', // Связь обращается к несуществующему порту элемента.
    1110: 'input.port.has.an.output.element.', // У входного порта есть выходной элемент.
    1111: 'unsupported.library.type.was.specified.', // Указан неподдерживаемый тип библиотеки.
    1112: 'unknown.solver.type.', // Неизвестный тип решателя.
    1113: 'error.converting.string.to.lower.case.', // Ошибка конвертации строки в нижний регистр.
    1114: 'solver.type.is.empty', //.Тип решателя пуст.
    1115: 'library.type.is.empty', // Тип библиотеки пуст.
    1206: 'account.phone.is_in_use', // Телефон уже занят.
    1207: 'account.email.is_in_use', // Email уже занят.
    1214: 'account.phone.not_valid', // Некорректный формат телефона.
    1227: 'account.password.current_not_valid', // Указан неверный пароль.
    1238: 'account.email.not_valid', // Некорректный email.
    1356: 'modeling.runtime_error', // Критическая ошибка потока вычислений
    1451: 'modeling.not_valid_discretization_step', // шаг дискретизации некратен шагу интегрирования
    1500: 'modeling.schema.has-forbidden-blocks', // На схеме есть недоступные для пользователя блоки
    6502: 'modeling.mdcore_internal_errors', // Ошибки решателя MDCore
    8002: 'successful.closing.of.the.Selector', // Успешное закрытие Селектора.
    3004: 'error.inserting.demo.example', // Ошибка добавления демо примера.
    1503: 'error.open.busy.project', // ошибка открытия проекта с занятым моудлем
};

export enum TranslationKey {
    LOGIN = 'common.login',
    PASSWORD = 'common.password.main',
    PASSWORD_CONFIRMATION = 'common.password.repeat',
    PASSWORD_NO_MATCH = 'common.password.no_match',
    BYTES = 'common.bytes',
    ROLE = 'common.role',

    ROLE_ADMIN = 'role.admin',

    PASSWORD_NEW = 'change_password.password_new',
    PASSWORD_NEW_REPEAT = 'change_password.password_new_repeat',
    CHANGE_PASSWORD_ACTION = 'account.personal_data.change_password_action.name',
    CHANGE_PASSWORD = 'account.personal_data.change_password.title',
    FORGOT_PASSWORD = 'auth.forgot_password',
    FORGOT_PASSWORD_TOKEN_INVALID = 'change_password.token_invalid',

    CREATE_TITLE = 'common.create.title',
    SECTION_IN_PROGRESS = 'common.section.in_progress',
    SIGN_IN = 'auth.sign_in',
    REGISTER = 'auth.register',
    FINALIZE = 'auth.finalize',
    NO_ACCOUNT = 'auth.no_account',
    ACCOUNT_ALREADY_EXISTS = 'auth.account_already_exists',
    ACCOUNT_PAGE = 'account.page.index.title',
    PERSONAL_DATA = 'account.menu.personal_data_item.title',
    TARIFFS = 'account.menu.tariff_item.title',
    SETTINGS = 'account.menu.settings_item.title',
    EDIT_DATA_ACTION = 'account.personal_data.edit_action.name',
    EDIT_DATA = 'account.personal_data.edit_data.title',
    NAME = 'account.page.personal_data.name_label',
    EMAIL = 'account.page.personal_data.email_label',
    PHONE = 'account.page.personal_data.phone_label',
    EXTENSION_NUMBER_SUPPORT = 'account.page.contacts_data.extension_number_support',
    FULL_TITLE = 'account.entrepreneur.full_title',
    TAX_NUMBER = 'account.corp.itn',
    ORGNIP = 'account.corp.orgnip',
    ORGN = 'account.corp.orgn',
    KPP = 'account.corp.kpp',
    KIO = 'account.corp.kio',
    ADDR_SAME_AS_LEGAL = 'account.corp.addr_same_as_legal',
    ADDR_LEGAL = 'account.corp.addr_legal',
    ADDR_POST = 'account.corp.addr_post',
    LICENSE_EDU = 'account.corp.license.license_edu',
    LICENSE_INFO = 'account.corp.license.license_info',
    LICENSE_INFO_EDU_NUMBER = 'account.corp.license.license_edu.number',
    LICENSE_INFO_EDU_DATE = 'account.corp.license.license_edu.date',
    INFO = 'workspace.context_menu.info',
    PROPERTIES = 'workspace.context_menu.properties',
    CHARTS = 'workspace.context_menu.charts',
    CHART = 'workspace.context_menu.chart',
    VARIABLE = 'workspace.context_menu.variable',
    ELEMENT = 'workspace.context_menu.element',
    PARAMETER = 'workspace.context_menu.parameter',
    PARAMETERS = 'workspace.context_menu.title.parameters',
    NO_PARAMETERS = 'workspace.context_menu.item.no_parameters',
    METER_UNITS = 'workspace.context_menu.item.meter_units',
    METER_UNITS_FACTOR = 'workspace.context_menu.item.meter_units_factor',
    NO_ELEMENTS = 'workspace.context_menu.item.no_elements',
    // Blocks group
    GROUP_NEW = 'workspace.context_menu.group.new.title',
    GROUP_SAVE_AS_BLOCK = 'workspace.context_menu.group.save_as_block.title',
    GROUP_CREATING_WARNING_TITLE = 'workspace.group.creating.warning.title',
    GROUP_CREATING_WARNING_ACCEPT = 'workspace.group.creating.warning.accept',
    GROUP_CREATING_WARNING_BLOCKS = 'workspace.group.creating.warning.blocks',
    GROUP_CREATING_WARNING_CHARTS = 'workspace.group.creating.warning.charts',

    // User blocks
    USER_BLOCKS_LIST_TITLE = 'user_blocks.title',
    USER_BLOCK_EDIT_TITLE = 'user_block.edit.title',
    USER_BLOCK_EDIT_VIEW_TITLE = 'user_block.edit_view.title',
    USER_BLOCK_EDIT_SYSTEM_PARAMETERS = 'user_block.edit.system.parameters',
    USER_BLOCK_SAVE_TITLE = 'user_block.save.title',
    USER_BLOCK_SAVED = 'user_block.saved',
    USER_BLOCK_TITLE = 'user_block.title',
    USER_BLOCK_SUBSECTION = 'user_block.subsection',
    USER_BLOCK_SUBSECTION_PLACEHOLDER = 'user_block.subsection_placeholder',
    USER_BLOCK_NO_SUBSECTIONS = 'user_block.no_subsections',
    USER_BLOCK_ICON = 'user_block.icon',
    USER_BLOCK_ICON_CHANGE = 'user_block.icon.change',
    USER_BLOCK_ICON_SCALE = 'user_block.icon.scale',
    USER_BLOCK_ICON_ROTATE = 'user_block.icon.rotate',
    USER_BLOCK_DESCRIPTION = 'user_block.description',
    USER_BLOCK_DELETE_CONFIRM = 'user_block.delete.confirm',
    USER_BLOCK_NEW = 'user_block.new',
    USER_BLOCK_CHANGED_NOTIFICATION = 'user_block.changed.notification',
    USER_BLOCK_DELETED_NOTIFICATION = 'user_block.deleted.notification',
    USER_BLOCK_GET_INFO_ERROR = 'user_block.get_info.error',
    USER_BLOCKS_MODIFIED_NOTIFICATION = 'user_blocks.modified.notification',

    PROJECT_NEW = 'projects.new.title',
    PROJECT_EDIT = 'projects.edit.title',
    PROJECT_OPEN = 'projects.open.title',
    PROJECT_IMPORT = 'projects.import.title',
    PROJECT_EXPORT = 'projects.export.title',
    PROJECT_NAME = 'projects.name',
    PROJECT_LIBRARY = 'projects.library',
    PROJECT_SOLVER = 'projects.solver',
    PROJECT_CREATED = 'project.created',
    PROJECTS_CREATED = 'projects.created_by_user',
    PROJECT_IMPORTED = 'projects.imported',
    PROJECT_EXPORTED = 'projects.exported',
    PROJECT_NOT_SUPPORTED_USDS = 'project.not_supported.usds',
    PROJECT_NOT_SUPPORTED = 'project.not_supported',
    PROJECT_IMPORT_MODAL_TITLE = 'projects.import.modal_title',
    BLOCK_IMPORT_ERROR = 'library.import.error',
    LIBRARYITEM_IMPORT_MODAL_TITLE = 'library.import.modal_title',
    PROJECT_IMPORT_FILE_DESCRIPTION = 'project.import.file_description',
    PROJECT_IMPORT_SELECT_FILE = 'project.import.select_file',
    BLOCK_NEW = 'engineer.new.title',
    ERROR_PROJECT_NOT_IMPORTED = 'project.import_error',
    ERROR_PROJECT_NOT_EXPORTED = 'project.export_error',
    ERROR_PROJECT_NOT_LOAD = 'project.load_error',
    TARIFF_WORKPLACE_COUNT = 'tariff.workplaces',
    PROJECT_SAVED = 'project.saved',
    PROJECT_DELETED = 'project.deleted',
    PROJECTS_FILTER_BY_TITLE = 'projects.filter.by_title',
    PROJECTS_COLUMN_TITLE = 'projects.column.title',
    PROJECTS_COLUMN_CREATED_AT = 'projects.column.created_at',
    PROJECTS_COLUMN_UPDATED_AT = 'projects.column.updated_at',
    PROJECT_DELETE_TITLE = 'project.delete.title',
    PROJECT_DELETE_CONFIRMATION = 'project.delete.confirmation',
    PROJECTS_DELETE_CONFIRMATION = 'projects.delete.confirmation',
    PROJECT_DELETE_DROPDOWN = 'project.delete.dropdown',
    PROJECT_EDIT_DROPDOWN = 'project.edit.dropdown',
    PROJECT_EXPORT_DROPDOWN = 'project.export.dropdown',
    PROJECT_ADD_TO_DEMO_DROPDOWN = 'project.add.to.demo.dropdown',
    PROJECT_REMOVE_FROM_DEMO_DROPDOWN = 'project.remove.from.demo.dropdown',
    PROJECT_DUPLICATE_DROPDOWN = 'project.duplicate.dropdown',
    DEMO_PROJECT_DUPLICATE_DROPDOWN = 'demo.project.duplicate.dropdown',
    PROJECT_COPY_ID_DROPDOWN = 'project.copy_id.dropdown',
    PROJECT_DELETE_NOT_SELECTED = 'project.delete.not_selected',
    PROJECT_EDIT_NOT_SELECTED = 'project.edit.not_selected',
    PROJECT_LIST_EMPTY = 'project.list.empty',
    DEMO_PROJECT_LIST_EMPTY = 'demo.project.list.empty',
    PROJECT_LIST_BY_FILTER_EMPTY = 'project.list.by_filter.empty',
    COMPANY_BASE = 'company.base_data',
    COMPANY_BANK = 'company.bank_data',
    COMPANY_TITLE = 'company.title',
    COMPANY_INN = 'company.inn',
    COMPANY_KPP = 'company.kpp',
    COMPANY_OGRN = 'company.ogrn',
    COMPANY_LEGAL_ADDRESS = 'company.legal_address',
    COMPANY_POSTAL_ADDRESS = 'company.postal_address',
    COMPANY_BANK_ACCOUNT = 'company.bank.account',
    COMPANY_BANK_CORRESPONDENT_ACCOUNT = 'company.bank.correspondent_account',
    COMPANY_BANK_BIK = 'company.bank.bik',
    COMPANY_BANK_TITLE = 'company.bank.title',
    COMPANY_BANK_INN = 'company.bank.inn',
    COMPANY_BANK_KPP = 'company.bank.kpp',

    LOGIN_IN_USE = 'register.login.in_use',
    PHONE_IN_USE = 'register.phone.in_use',
    EMAIL_IN_USE = 'register.email.in_use',

    API_KEYS_LIST_EMPTY = 'api_keys.list.empty',
    API_KEYS_COLUMN_KEY = 'api_keys.column.key',
    API_KEYS_COLUMN_NAME = 'api_keys.column.name',
    API_KEYS_COLUMN_EXPIRE_AT = 'api_keys.column.expire_at',
    API_KEYS_COLUMN_CREATED_AT = 'api_keys.column.created_at',
    API_KEYS_NEW = 'api_keys.new.title',
    API_KEYS_DELETE = 'api_keys.delete',
    API_KEY_DELETE_TITLE = 'api_keys.delete.title',
    API_KEY_DELETE_CONFIRMATION = 'api_keys.delete.confirmation',
    API_KEY_DELETE_NOT_SELECTED = 'api_keys.delete.not_selected',
    API_KEY_NAME = 'api_key.name',
    API_KEY_EXPIRE_AT = 'api_key.expire_at',
    ERROR_NEW_API_KEY_NAME = 'validations.new_api_key.name_exists',
    ERROR_API_KEY_NAME_IS_EMPTY = 'validation.api_key_name_is_empty',
    API_KEY_CREATED = 'api_key.created',
    API_KEY_DELETED = 'api_key.deleted',

    HELP_SECTION_COMMON = 'help.section.common',
    HELP_SECTION_INTERFACE = 'help.section.interface',
    HELP_SECTION_TASK = 'help.section.task_creation',
    HELP_SECTION_MODELING = 'help.section.modeling_process',
    HELP_SECTION_CHANGELOG = 'help.section.changelog',
    HELP_SECTION_VERSIONS_TITLE = 'help.section.versions_title',
    HELP_SECTION_SUPPORT = 'help.section.support',
    HELP_SECTION_HOTKEYS = 'help.section.hotkeys',
    HELP_ERROR_FETCH_HISTORY_VERSION_DATA = 'help.error_fetch.history_version_data',
    HELP_BLOCK_SUMMARY = 'help.block.summary',
    HELP_BLOCK_NEW = 'help.block.new',
    HELP_BLOCK_IMPROVEMENTS = 'help.block.improvements',
    HELP_BLOCK_FIXES = 'help.block.fixes',
    HELP_BLOCK_ADDITIONAL = 'help.block.additional',

    // pages

    PAGE_TARIFFS_TITLE = 'tariffs.page.title',
    PAGE_PROJECTS_TITLE = 'projects.page.title',
    PAGE_DEMO_PROJECTS_TITLE = 'projects.page.demo.title',
    PAGE_FAV_PROJECTS_TITLE = 'projects.page.fav.title',
    PAGE_FAV_PROJECTS_EMPTY = 'projects.page.fav.empty',
    PAGE_FAV_PROJECTS_SUCCESS = 'projects.page.fav.success',
    PAGE_DEMO_PROJECT_SUCCESS = 'projects.page.demo.success',
    PAGE_DEMO_PROJECT_REMOVED = 'projects.page.demo.removed',
    PAGE_PROJECT_DELETE_FAV = 'projects.page.fav.delete',
    PAGE_FAV_PROJECT_SUCCESS = 'project.page.fav.success',
    PAGE_PROJECT_ADD_FAV = 'projects.page.fav.add',
    PAGE_PROJECTS_ADD_FAV = 'projects.page.fav.add.selected',
    PAGE_PROJECTS_REMOVE_FAV = 'projects.page.fav.remove.selected',
    PAGE_FAV_PROJECT_DELETE = 'projects.page.fav.remove',
    PAGE_PROJECTS_REMOVE = 'projects.page.remove',
    PAGE_MAIN_TITLE = 'account.page.main.title',
    PAGE_ACCOUNT_TITLE = 'account.page.title',
    PAGE_ACCOUNT_EDIT_TITLE = 'account.edit.page.title',
    PAGE_PASSWORD_TITLE = 'account.change_password.page.title',
    PAGE_DASHBOARD_TITLE = 'dashboard.page.title',
    PAGE_BALANCE_TITLE = 'balance.page.title',
    PAEGE_DOCUMENTS_TITLE = 'documents.page.title',
    PAGE_USERS_TITLE = 'users.page.title',
    PAGE_SUBSCRIPTIONS_TITLE = 'subscriptions.page.title',
    PAGE_REFUND_TITLE = 'refund.page.title',
    PAGE_LICENSE_PAGE = 'license.page.title',
    USERS_PAGE_ADD_ACOUNT = 'users.page.add_account',
    PAGE_LEGAL_DATA_TITLE = 'account.legal_data.page.title',
    PAGE_CONTACTS_TITLE = 'account.contacts_page.title',

    PASSWORD_RESTORE = 'account.restore_password.title',
    PASSWORD_RESTORE_INSTRUCTION_SENT = 'account.restore_password.instructions_sent',
    PAGE_COMPANY_TITLE = 'account.company.page.title',
    PAGE_TARIFF_TITLE = 'account.tariff.page.title',
    PAGE_HELP_TITLE = 'account.help.page.title',
    PAGE_VERSIONS_HISTORY_TITLE = 'account.versions_history.page.title',
    PAGE_LICENSE_TITLE = 'account.license.page.title',
    PAGE_SETTINGS_TITLE = 'account.settings.page.title',
    PAGE_API_KEYS_TITLE = 'account.api_keys.page.title',
    PAGE_HELP_SUBTITLE = 'account.help.page.subtitle',
    PAGE_ENGINEER_TITLE = 'engineer.page.title',
    PAGE_ABOUT_TITLE = 'about.page.title',

    SOCKET_ERR_MSG = 'messaging.socket.error_msg',
    NETWORK_ERR_MSG = 'messaging.network.error_msg',
    NAME_ERROR_MSG = 'validations.message',
    ONLY_DIGITS_ERROR_MSG = 'validations.message.only_digits',
    ONLY_PHONE_ERROR_MSG = 'validations.message.only_phone',
    MALFORMED_EMAIL_MSG = 'validations.message.malformed_email',
    TOO_MANY_ERROR_MSG = 'validations.message.too_many_symbols',
    TOO_FEW_ERROR_MSG = 'validations.message.too_few_symbols',
    ERROR_PHONE_NOT_CORRECT = 'validations.phone.not_correct',
    AGREE = 'validations.message.agree',

    ERROR_PASSWORD_MIN_LENGTH = 'validations.password.message.min_length',
    OBLIGATORY_FIELD_MSG = 'validations.message.obligatory_field',
    FLOAT64_MSG = 'validations.message.float64',

    // error messages
    ERROR_SEND_MESSAGE_TO_SUPPORT = 'error.send.message',

    // notifications' messages
    MESSAGE_ACCOUNT_UPDATED = 'message.account.data.updated',
    MESSAGE_PASSWORD_UPDATED = 'message.account.password.updated',
    MESSAGE_REGISTRATION_SUCCESS = 'message.account.registration.success',
    MESSAGE_REGISTRATION_ON_PAGE_LEAVE = 'message.account.registration.page.leave',
    MESSAGE_DOWNLOAD_DOCUMENT_ERROR = 'message.account.document.download.error',

    // profile
    USER_ACCOUNT = 'account.user_account',
    ERROR_ACCOUNT_UPDATE = 'account.update.error',
    ERROR_ACCOUNT_SETTINGS_UPDATE = 'account.update_settings.error',

    //account type
    SELECT_ACCOUNT_TYPE = 'account.type.select.text',
    INDIVIDUAL = 'auth.account_type.individual',
    ENTREPRENEUR = 'auth.account_type.entrepreneur',
    COMPANY_RESIDENT = 'auth.account_type.company_resident',
    COMPANY_NON_RESIDENT = 'auth.account_type.company_non_resident',

    // account table
    TABLE_BALANCE_COLUMN_TRANSACTION_NAME = 'balance.table.column.transaction_name',
    TABLE_BALANCE_COLUMN_SUM = 'balance.table.column.sum',
    TABLE_BALANCE_COLUMN_DATE = 'balance.table.column.date',
    TABLE_BALANCE_COLUMN_TRANSACTION_ID = 'balance.table.column.transaction_id',
    TABLE_BALANCE_SEARCH_PLACEHOLDER = 'balance.table.search_placeholder',
    TABLE_BALANCE_ERROR_DATA = 'balance.table.error_data',
    TABLE_BALANCE_EMPTY_DATA = 'balance.table.empty_data',
    TABLE_DOCUMENT_COLUMN_TYPE = 'document.table.column.type',
    TABLE_DOCUMENT_COLUMN_SUM = 'document.table.column.sum',
    TABLE_DOCUMENT_COLUMN_VAT = 'document.table.column.vat',
    TABLE_DOCUMENT_COLUMN_TOTAL = 'document.table.column.total',
    TABLE_DOCUMENT_COLUMN_DOCUMENT_ID = 'document.table.column.doument_id',
    TABLE_DOCUMENT_COLUMN_DATE = 'document.table.column.date',
    TABLE_DOCUMENT_COLUMN_STATUS = 'document.table.column.status',
    TABLE_DOCUMENT_COLUMN_PERSONAL_ACCOUNT = 'document.table.column.personal_account',
    TABLE_DOCUMENT_COLUMN_CLIENT_NAME = 'document.table.column.client_name',
    TABLE_DOCUMENT_ERROR_DATA = 'document.table.error_data',
    TABLE_DOCUMENT_EMPTY_DATA = 'document.table.empty_data',
    TARIFF_ERROR_DATA = 'tariff.error_data',
    TARIFFS_ERROR_DATA = 'tariffs.error_data',
    TARIFF_CHANGE = 'tariff.change',
    TABLE_USERS_COLUMN_FULLNAME = 'users.table.column.fullname',
    TABLE_USERS_COLUMN_ROLE = 'users.table.column.role',
    TABLE_USERS_COLUMN_DATE = 'users.table.column.date',
    TABLE_USERS_EMPTY_DATA = 'users.table.empty_data',
    USERS_DATA = 'users.data',

    ACCOUNT_MODE_ENGINEER = 'account.mode.engineer',
    ACCOUNT_MODE_DEFAULT = 'account.mode.default',
    LIBRARYITEM_IMPORT_FILE_DESCRIPTION = 'engineer.block.import',
    LIBRARYITEM_IMPORT_SELECT_FILE = 'engineer.block.select',
    //student role
    DEFAULT = 'student.role.default',
    ADMINISTRATOR = 'student.role.administrator',

    //form
    FORM_ADD_ACCOUNT_ID = 'form.add_account.id',
    FORM_ADD_ACCOUNT_ROLE = 'form.add_account.role',

    //registration
    EDUCATIONAL_UNIT = 'auth.account_type.educational_unit',
    ERROR_SIGIN_DATA = 'validations.sigin.incorrect_login_or_password',
    ERROR_SIGNUP_DATA = 'validations.signup.error',
    ERROR_NEW_PROJECT_NAME = 'validations.new_project.name_exists',
    ERROR_PROJECT_NAME_IS_EMPTY = 'validation.project_name_is_empty',
    ERROR_UNKNOWN = 'unknown_error',
    ERROR_KPP_FORMAT = 'validations.message.malformed_kpp',
    ERROR_BANK_ACCOUNT_FORMAT = 'validations.message.malformed_bank_account',
    ERROR_BIK_FORMAT = 'validations.message.malformed_bik',
    ERROR_ORGN_FORMAT = 'validations.message.malformed_ogrn',
    ERROR_OGRNIP_FORMAT = 'validations.message.malformed_ogrnip',
    ERROR_TAX_NUMBER_FORMAT = 'validations.message.malformed_tax_number',
    ERROR_TAX_NUMBER_BANK_FORMAT = 'validations.message.malformed_tax_number_bank',
    ERROR_TERMS_AND_CONDITIONS = 'validations.message.not_agreed.terms_and_conditions',
    ERROR_PRIVACY_POLICY = 'validations.message.not_agreed.privacy_policy',
    ERROR_LARGE_FILE = 'validation.dropzone.large.file',
    ERROR_INVALID_TYPE_FILE = 'validation.dropzone.invalid_type.file',
    ERROR_TOO_MANY_FILES_TEXT = 'validation.dropzone.too_many.files.text',
    ERROR_TOO_MANY_FILE = 'validation.dropzone.too_many.file',
    ERROR_TOO_MANY_FILES = 'validation.dropzone.too_many.files',
    ERROR_SMALL_FILE = 'validation.dropzone.small.file',
    ERROR_NEW_BLOCK_NAME = 'validations.new_block.name_exists',
    ERROR_BLOCK_NAME_IS_EMPTY = 'validation.block_name_is_empty',
    ERROR_SEND_REQUEST = 'error.send.request',
    ERROR_SC_RESPONSE = 'error.send.sc.response',

    BANK_NAME = 'auth.account_type.bank_name',
    ACCOUNT_NUMBER = 'auth.account_type.account_number',
    BIC = 'auth.account_type.account_type.bic',
    KPP_BANK = 'auth.account_type.account_type.kpp_bank',
    TAX_NUMBER_BANK = 'auth.account.corp.itn_bank',
    CORRESPONDENT_ACCOUNT = 'auth.account.corp.correspondent_account',

    TERMS_AND_CONDITIONS = 'auth.account.corp.terms_and_conditions',
    PRIVACY_POLICY = 'auth.account.corp.privacy_policy',
    PRIVACY_POLICY_AGREEMENT = 'user.privacy_agreement',
    REGLAMENT_TP = 'user.ta_regulations',
    USER_AGREEMENT = 'user.agreements',
    // common
    COUNTRY_NAME = 'common.country.name',
    BACK = 'common.titles.back',
    GO_NEXT = 'common.titles.go_next',
    RETURN_TO_ENTRANCE = 'common.title.return_to_entrance',
    INVOICE = 'common.invoice',
    DOWNLOAD = 'common.download',
    UNLIMITED = 'common.unlimited',

    SEC = 'common.sec',
    MEGABYTE = 'common.megabyte',
    PIECE = 'common.piece',

    ELECTROCITY = 'available_libraries.electrocity',
    ELECTROCITY_DC = 'available_libraries.electrocity_dc',
    AUTO = 'available_libraries.auto',
    THERMOHYDRAULICS = 'available_libraries.thermohydraulics',
    SIMULATION = 'available_libraries.simulation',
    SYSTEM_DESIGN = 'available_libraries.system_design',
    EXTERNAL_MODELS = 'available_libraries.external_models',
    CODE_GENERATION = 'available_libraries.code_generation',
    COMMON = 'available_libraries.common',
    MECHANICS = 'available_libraries.mechanics',
    ELECTRIC_DRIVE = 'available_libraries.electric_drive',
    HEAT_EXCHANGE = 'available_libraries.heat_exchange',
    ELECTRONICS = 'available_libraries.electronics',
    DEVELOPMENT = 'available_libraries.development',
    ISOTHERMAL_HYDRAULICS = 'available_libraries.isothermal_hydraulics',
    PNEUMATICS = 'available_libraries.pneumatics',

    SUBLIBRARY_ROTATIONAL_MOTION = 'mechanics.sublibraries.rotational_motion',
    SUBLIBRARY_TRANSLATIONAL_MOTION = 'mechanics.sublibraries.translational_motion',
    SUBLIBRARY_BASE_ELEMENTS = 'electric_drive.sublibraries.base_elements',
    SUBLIBRARY_CONVERTERS = 'electric_drive.sublibraries.converters',
    SUBLIBRARY_ELECTRIC_MACHINES = 'electric_drive.sublibraries.electric_machines',

    SUBLIBRARY_SUPPLIERS = 'auto.sublibraries.suppliers',
    SUBLIBRARY_FUNCTIONS = 'auto.sublibraries.functions',
    SUBLIBRARY_OPERATIONS = 'auto.sublibraries.operations',
    SUBLIBRARY_LOGIC = 'auto.sublibraries.logic',
    SUBLIBRARY_SWITCH = 'auto.sublibraries.switch',
    SUBLIBRARY_TRANSFERFUNCTIONS = 'auto.sublibraries.transferfunctions',
    SUBLIBRARY_CONTROLLERS = 'auto.sublibraries.controllers',
    SUBLIBRARY_DELAYS = 'auto.sublibraries.delays',
    SUBLIBRARY_RELAY = 'auto.sublibraries.relay',
    SUBLIBRARY_NONLINEAR = 'auto.sublibraries.nonlinear',
    SUBLIBRARY_SIGNAL_PROCESSING = 'auto.sublibraries.signal_processing',

    SUBLIBRARY_STRUCTURAL_AND_SUPPORT = 'electrocity.sublibraries.structural_and_support',
    SUBLIBRARY_LOADS = 'electrocity.sublibraries.loads',
    SUBLIBRARY_TRANSFORMERS = 'electrocity.sublibraries.transformers',
    SUBLIBRARY_SOURCES = 'electrocity.sublibraries.sources',
    SUBLIBRARY_POWER_CONVERTERS = 'electrocity.sublibraries.power_converters',
    SUBLIBRARY_LONGITUDINAL_ELEMENTS = 'electrocity.sublibraries.longitudinal_elements',

    SUBLIBRARY_ELECTRONIC_COMPONENTS_AND_POWER_CONVERTERS = 'electronics.sublibraries.electronic_components_and_power_converters',
    SUBLIBRARY_PASSIVE_ELEMENTS = 'electronics.sublibraries.passive_elements',
    SUBLIBRARY_BASIC_COMPONENTS = 'electronics.sublibraries.basic_components',
    SUBLIBRARY_MEASURING_ELEMENTS = 'electronics.sublibraries.measuring_elements',

    SUBLIBRARY_CMS = 'system_design.sublibraries.cms',
    SUBLIBRARY_TRANSPORT = 'system_design.sublibraries.transport',
    SUBLIBRARY_RF = 'system_design.sublibraries.rf',

    BASE_PERMISSION = 'permissions.base',
    PNEUMO_PERMISSION = 'permissions.pneumo',
    HEATHYDRO_PERMISSION = 'permissions.heathydro',
    CODEGENERATING_PERMISSION = 'permissions.codegenerating',
    ELECTRODYNAMICS_PERMISSION = 'permissions.electrodynamics',

    ALL_LIBRARY_TYPES = 'available_libraries.all_types',

    // solvers
    SOLVER_USDS = 'solver.usds',
    SOLVER_JAUTO = 'solver.jauto',
    SOLVER_MDCORE = 'solver.mdcore',

    //tariffs
    USER_SET_TARIFF_SUCCESS = 'account.user_set_tariff.success',
    USER_SET_TARIFF_ERROR = 'account.user_set_tariff.error',
    TARIFF_ACTIVE = 'account.tariff_active',
    TARIFF_FUNCTIONAL = 'account.tariff_functional',
    TARIFF_RESOURCES = 'account.tariff_resources',
    TARIFF_FREE = 'account.tariff_free',
    TARIFF_SCHEMAS_AMOUNT = 'account.tariff.maximum_schemas_amount',
    TARIFF_BLOCKS_AMOUNT = 'account.tariff.maximum_blocks_per_schema_amount',
    TARIFF_MAX_REAL_TIME = 'account.tariff.max_real_time',
    TARIFF_MAX_RAM = 'account.tariff.max_ram',
    TARIFF_MAX_ROM = 'account.tariff.max_rom',
    TARIFF_MAX_CPU = 'account.tariff.max_cpu',
    TARIFF_MAX_MODELING_TIME = 'account.tariff.max_modelling_time',
    TARIFF_JUTHON_BLOCK = 'account.tariff.juthon_block',
    TARIFF_FMI_BLOCK = 'account.tariff.fmi_block',
    TARIFF_MAX_SIMULATIONS_COUNT = 'account.tariff.max_simulations_count',
    TARIFF_MAX_DISK_SPACE = 'account.tariff.max_disk_space',
    TARIFF_UNCONNECTED = 'account.tariff.unconnected',
    AVAILABLE_MODULES = 'account.tariff.available_modules',
    TARIFF_SUBSCRIPTION_TYPE = 'account.tariff.subscription_type',
    TARIFF_START_DATE = 'account.tariff.start_date',
    TARIFF_END_DATE = 'account.tariff.end_date',
    TARIFF_STATUS = 'account.tariff.status',
    TARIFF_LIMITED_TYPE = 'account.tariff.limited_type',
    TARIFF_UNLIMITED_TYPE = 'account.tariff.unlimited_type',
    TARIFF_STATUS_ACTIVE = 'account.tariff.status_active',
    TARIFF_STATUS_NOT_RENEWED = 'account.tariff.status_not_renewed',
    TARIFF_STATUS_CANCELED = 'account.tariff.status_canceled',

    CONTACT_SUPPORT = 'common.contact_support',
    LEAVE_PAGE_MESSAGE = 'common.leave_page_message',
    LOG_OUT_MESSAGE = 'common.log_out_message',

    //library
    FILTER_BY_NAME = 'filter_input.filter_by_name',
    ERROR_LIBRARY_ITEMS = 'library_items.fetch_failed',
    ELEMENT_IS_BLOCKED = 'library_items.element.is_blocked',
    LIBRARIES_ALL = 'libraries_all',
    LIBRARIES_AVAILABLE = 'libraries_available',
    LIBRARY_NEW_BLOCK = 'library.new_block',

    // navigation menu
    SETTINGS_MENU_ITEM = 'menu.item.settings',
    API_KEYS_MENU_ITEM = 'menu.item.api-keys',
    EXIT_MENU_ITEM = 'menu.item.profile',

    //workspace
    WORKSPACE_PAGE_VARIABLES = 'workspace.page.variables',
    WORKSPACE_PAGE_ERROR_VARIABLES = 'workspace.page.variables.error',
    WORKSPACE_PAGE_ERROR_NAME_VARIABLES = 'workspace.page.variables.error.name',
    WORKSPACE_PAGE_ERROR_VALUE_VARIABLES = 'workspace.page.variables.error.value',
    WORKSPACE_PAGE_VISUALIZATION = 'workspace.page.visualization',
    WORKSPACE_PAGE_PRESENTATION = 'workspace.page.presentation',
    WORKSPACE_PAGE_TOOLS = 'workspace.page.tools',
    WORKSPACE_PAGE_JOURNAL = 'workspace.page.journal',
    WORKSPACE_PROPERTIES = 'workspace.right_sidebar.properties',
    WORKSPACE_PROPERTIES_CUSTOM_NAME = 'workspace.right_sidebar.custom.name',
    WORKSPACE_PROPERTY = 'workspace.right_sidebar.property',
    WORKSPACE_ADD_PROPERTY = 'workspace.right_sidebar.add_property',
    WORKSPACE_PARAMETERS = 'workspace.block_parameters',
    WORKSPACE_PARAMETERS_X = 'workspace.block_parameters_x',
    WORKSPACE_RIGHT_SIDEBAR_PARAMETERS = 'workspace.right_sidebar.parameters',
    WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_INPUTS = 'workspace.right_sidebar.parameters_inputs',
    WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_OUTPUTS = 'workspace.right_sidebar.parameters_outputs',
    WORKSPACE_RIGHT_SIDEBAR_WARNING_DELETE_CONNECTED_PORT = 'workspace.right_sidebar.warning_delete_connected_port',
    WORKSPACE_WIRE = 'workspace.right_sidebar.wire',
    WORKSPACE_PROPERTIES_NOT_FOUND = 'workspace.right_sidebar.properties.not_found',
    WORKSPACE_PARAMETERS_NOT_FOUND = 'workspace.right_sidebar.parameters.not_found',
    WORKSPACE_PARAMETERS_NOT_DEFINED = 'workspace.right_sidebar.parameters.not_defined',
    WORKSPACE_PROPERTIES_NOT_DEFINED = 'workspace.right_sidebar.properties.not_defined',
    WORKSPACE_ERROR_CHART = 'workspace.chart.error_getting_data',
    WORKSPACE_ERROR_CHART_CSV = 'workspace.chart.error_create_csv',
    WORKSPACE_WARNING_CHART_START = 'workspace.chart.warning_start_chart_before_run',
    WORKSPACE_CHART_ADD = 'workspace.chart.add',
    WORKSPACE_CHART_TYPE_Y_T = 'workspace.chart.type.y_t',
    WORKSPACE_CHART_TYPE_Y_X = 'workspace.chart.type.y_x',
    WORKSPACE_CHART_SETUP_PARAMETER = 'workspace.chart.setup_parameter',
    WORKSPACE_CHART_POINT_VALUE = 'workspace.chart.point_value',
    WORKSPACE_CHART_DOWNLOAD_CSV = 'workspace.chart.download_csv',
    WORKSPACE_CHART_CSV_TIME_TITLE = 'workspace.chart.csv.time_title',
    WORKSPACE_UNDO = 'workspace.undoRedo.undo',
    WORKSPACE_REDO = 'workspace.undoRedo.redo',
    WORKSPACE_GENERATE_SOURCES = 'workspace.project_menu.generate_sources',
    WORKSPACE_GENERATE_SOURCES_NOT_AVAILABLE = 'workspace.project_menu.generate_sources.not_available',
    WORKSPACE_GENERATE_SOURCES_CANCEL = 'workspace.project_menu.generate_sources.cancel',
    WORKSPACE_GENERATE_SOURCES_NO_LIVE_PERMISSION = 'workspace.project_menu.generate_sources.no_live_permission',
    WORKSPACE_GET_NONAME_FILE = 'workspace.project_menu.get_noname_file',
    WORKSPACE_GET_NONAME_FILE_CANCEL = 'workspace.project_menu.get_noname_file.cancel',

    WORKSPACE_ERROR_GETTING_PROJECTS_LIST = 'workspace.error_getting_projects_list',
    WORKSPACE_ERROR_GETTING_PROJECT_DATA = 'workspace.error_getting_project_data',
    WORKSPACE_ERROR_GETTING_PROJECTS_FOR_PROJECT_BLOCKS = 'workspace.error_getting_projects_for_project_blocks',
    WORKSPACE_PROJECT_BLOCKS_MODIFIED_CONNECTED_PROJECT = 'workspace.project_blocks_modified_connected_project',

    WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED = 'workspace.view_mode_action_not_allowed',
    WORKSPACE_VIEW_MODE = 'workspace.view_mode',
    WORKSPACE_WARNING_PROJECT_CHANGED = 'workspace.warning_project_changed',

    WORKSPACE_MEMORY_USAGE = 'workspace.memory.usage',
    WORKSPACE_MEMORY_LIMIT = 'workspace.memory.limit',
    WORKSPACE_MEMORY_LIMIT_ERROR = 'workspace.memory.limit.error',
    WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC = 'workspace.memory.realtime.sync',
    WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC_TOOLTIP = 'workspace.memory.realtime.sync.tooltip',
    WORKSPACE_MEMORY_LIMIT_MODELLING_TIME = 'workspace.memory.modelling.time',
    WORKSPACE_MEMORY_LIMIT_MODELLING_TIME_TOOLTIP = 'workspace.memory.modelling.time.tooltip',
    WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP = 'workspace.memory.integration.step',
    WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_TOOLTIP = 'workspace.memory.integration.step.tooltip',
    WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP = 'workspace.memory.discretization.step',
    WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_TOOLTIP = 'workspace.memory.discretization.step.tooltip',
    WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE = 'workspace.memory.compression.use',
    WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE_TOOLTIP = 'workspace.memory.compression.use.tooltip',
    WORKSPACE_MEMORY_LIMIT_COMPRESSION_ACCURACY = 'workspace.memory.compression.accuracy',
    WORKSPACE_MEMORY_LIMIT_COMPRESSION_TYPE = 'workspace.memory.compression.type',
    WORKSPACE_MEMORY_LIMIT_CALCULATION = 'workspace.memory.limit.calculation',
    WORKSPACE_MEMORY_LIMIT_MODELLING = 'workspace.memory.limit.modelling',
    WORKSPACE_MEMORY_LIMIT_GRAPHS = 'workspace.memory.limit.graphs',
    WORKSPACE_MEMORY_LIMIT_MEMORY_CALCULATION = 'workspace.memory.limit.calculation.title',
    WORKSPACE_MEMORY_LIMIT_MEMORY_POINT = 'workspace.memory.limit.point',
    WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR = 'workspace.memory.limit.integration.error',
    WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR = 'workspace.memory.limit.discretization.error',
    WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR = 'workspace.memory.limit.discretization.multiplicity_error',
    WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO = 'workspace.memory.limit.more.null',
    WORKSPACE_PROJECT_INT_VALUE_ERROR = 'workspace.project.int_value.error',
    WORKSPACE_PROJECT_EXTERNAL_MODELS_TITLE = 'workspace.project.external_models.title',
    WORKSPACE_PROJECT_EXTERNAL_MODELS_TASKNAME = 'workspace.project.external_models.task_name',
    WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST = 'workspace.project.external_models.host',
    WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST_NOT_VALID = 'workspace.project.external_models.host.not_valid',
    WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT = 'workspace.project.external_models.port',
    WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT_VALUE = 'workspace.project.external_models.port.value',
    WORKSPACE_NOTEBOOK_TOOL = 'workspace.notebook.tool',
    WORKSPACE_NOTEBOOK_WITH_CORE_TOOL = 'workspace.notebook_with_core.tool',
    WORKSPACE_NOTEBOOK_WITH_CORE_TOOL_LINK_ERROR = 'workspace.notebook_with_core.tool.error',
    WORKSPACE_SHORT_CIRCUIT_TOOL = 'workspace.shot.circuit.tool',
    WORKSPACE_SHORT_CIRCUIT_TOOL_START = 'workspace.shot.circuit.tool.start',
    WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT = 'workspace.shot.circuit.tool.fault',
    WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_3 = 'workspace.shot.circuit.tool.fault.3',
    WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_2 = 'workspace.shot.circuit.tool.fault.2',
    WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_1 = 'workspace.shot.circuit.tool.fault.1',
    WORKSPACE_SHORT_CIRCUIT_TOOL_CASE = 'workspace.shot.circuit.tool.case',
    WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MAX = 'workspace.shot.circuit.tool.case.max',
    WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MIN = 'workspace.shot.circuit.tool.case.min',
    WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT = 'workspace.shot.circuit.tool.percent',
    WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT_FULL = 'workspace.shot.circuit.tool.percent.full',
    WORKSPACE_SHORT_CIRCUIT_TOOL_DURATION = 'workspace.shot.circuit.tool.dimension',
    WORKSPACE_SHORT_CIRCUIT_TOOL_NO_LIVE_PERMISSION = 'workspace.shot.circuit.tool.no_live_permission',
    WORKSPACE_HARMONIC_TOOL = 'workspace.harmonic.tool',
    WORKSPACE_INVOLVED_MODULES = 'workspace.involved_modules',
    WORKSPACE_LIVE_PERMISSION_MODULES = 'workspace.live.permission.modules',

    // model statuses
    MODEL_STATUS_STOP_HELP = 'model.status.stop.help',
    MODEL_STATUS_INIT_HELP = 'model.status.init.help',
    MODEL_STATUS_FREEZE_HELP = 'model.status.freeze.help',
    MODEL_STATUS_RUN_HELP = 'model.status.run.help',
    MODEL_STATUS_DATA_HELP = 'model.status.data.help',
    MODEL_STATUS_CODE_HELP = 'model.status.code.help',

    MODEL_RUN_HELP = 'model.run.help',
    MODEL_SOFT_STOP_HELP = 'model.soft_stop.help',
    MODEL_STOP_HELP = 'model.stop.help',
    MODEL_FREEZE_HELP = 'model.freeze.help',
    MODEL_CONTINUE_HELP = 'model.continue.help',
    MODEL_TIME_UNIT = 'model.time.unit',

    //schema
    ERROR_LOADING_SCHEMA = 'schema.error_loading_schema',
    ERROR_SAVING_SCHEMA = 'schema.error_saving_schema',
    ERROR_GETTING_ELEMENT_PROPERTIES_SETS = 'schema.error_getting_element_properties_sets',
    ERROR_RUN_PROJECT = 'model_control.error_run_project',
    ERROR_GENERATE_SOURCES = 'model_control.error_generate_sources',
    ERROR_GENERATE_NONAME_FILE = 'model_control.error_generate_noname_file',
    ERROR_FETCH_TREND_DATA = 'model_control.error_fetch_trend_data',
    WARNING_VM_IS_IN_USE = 'model_control.warning.vm_is_in_use',
    WARNING_ALL_VMS_ARE_BUSY = 'model_control.warning.all_vms_are_busy',
    WARNING_INTEGRATION_STEP_PRIORITY = 'schema.warning.integration_step_priority',
    MESSAGE_STOP_BY_USER = 'schema.message.stop_by_user',
    MESSAGE_STOP_FROM_SOLVER = 'schema.message.stop_from_solver',
    MESSAGE_ERROR_FROM_SOLVER = 'schema.message.error_from_solver',
    MESSAGE_BUILD_ERROR_FROM_SOLVER = 'schema.message.build_error_from_solver',
    MESSAGE_EXCEEDED_TIME_LIMIT_FROM_SOLVER = 'schema.message.exceeded_time_limit_from_solver',
    MESSAGE_EXCEEDED_MEMORY_HEAP_SIZE_LIMIT_FROM_SOLVER = 'schema.message.exceeded_memory_heap_size_limit_from_solver',
    MESSAGE_EXCEEDED_DB_SIZE_LIMIT_FROM_SOLVER = 'schema.message.exceeded_db_size_limit_from_solver',
    ERROR_STOP_PROJECT = 'model_control.error_stop_project',
    ERROR_FREEZE_PROJECT = 'model_control.error_freeze_project',
    ERROR_CREATION_PROJECT = 'schema.error_creation_project',
    ERROR_NOT_FOUND_CODE = 'workspace.error_not_found_code',
    ERROR_CONNECT_SELECTOR = 'connect.selector.no_connection',
    ERROR_CONNECT_LOG = 'connect.log.no_connection',
    ERROR_CONNECT_TRENDS = 'connect.trends.no_connection',
    WORKSPACE_PAGE_SAVE = 'workspace.page.save',
    WORKSPACE_PROJECT_TITLE = 'workspace.project.title',
    WORKSPACE_BLOCK_TITLE = 'workspace.block.title',
    WORKSPACE_ELEMENT_TITLE = 'workspace.element.title',
    WORKSPACE_CONNECTION_TITLE = 'workspace.connection.title',
    WORKSPACE_OBJECTS_TITLE = 'workspace.objects.title',
    WORKSPACE_ELEMENT_ACTIONS = 'workspace.element.actions',
    WORKSPACE_ELEMENT_POSITIONING = 'workspace.element.positioning',
    WORKSPACE_ELEMENT_POSITION = 'workspace.element.position',
    WORKSPACE_ELEMENT_SIZE = 'workspace.element.size',
    RULES_ELEMENT_MAX_COUNT = 'schema.rules.element_max_count',
    ELEMENT_LIVE_PERMISSIONS_ADD_ERROR = 'schema.live_permissions.add.error',
    CHART_ELEMENT_MAX_COUNT = 'chart.element_max_count',
    ELEMENT_CONFIGURATION = 'schema.element.configuration',
    ELEMENT_CONFIGURATION_NOTICE = 'schema.element.configuration_notice',
    ELEMENT_CONFIGURATION_IS_NOT_RELEVANT = 'schema.element.configuration_is_not_relevant',
    ELEMENT_CONFIGURATION_CUSTOMIZABLE = 'schema.element.configuration_customizable',
    ELEMENT_CONFIGURATION_CUSTOMIZABLE_SELECT = 'schema.element.configuration_customizable_select',
    ELEMENT_CONFIGURATION_CURRENT = 'schema.element.configuration_current',
    ELEMENT_INITIALIZATION = 'schema.element.initialization',
    ELEMENT_FILE_UPDATE = 'schema.element.file_update',
    ELEMENT_FILE_S_PARAMETERS_NAME = 'schema.element.file_s_parameters_name',
    ELEMENT_FILE_TOUCHSTONE_FORMAT = 'schema.element.file_touchstone_format',
    ELEMENT_INITIALIZATION_NOTICE = 'schema.element.initialization_notice',
    ELEMENT_INITIALIZATION_ATTENTION_MESSAGE = 'schema.element.initialization_attention_message',
    ELEMENT_INITIALIZATION_DROPZONE = 'schema.element.initialization.dropzone',
    ELEMENT_RFSPARAMETERS_INITIALIZATION_DROPZONE = 'schema.element_rfsparameters.initialization.dropzone',
    ELEMENT_INITIALIZATION_SELECT = 'schema.element.initialization.select',
    ELEMENT_INITIALIZATION_STATUS_UPLOAD_LABEL = 'schema.element.initialization.status.upload.label',
    ELEMENT_INITIALIZATION_STATUS_INITIALIZE_LABEL = 'schema.element.initialization.status.initialize.label',
    ELEMENT_INITIALIZATION_STATUS_SUCCESS = 'schema.element.initialization.status.success',
    ELEMENT_INITIALIZATION_STATUS_FAILED = 'schema.element.initialization.status.failed',
    ELEMENT_INITIALIZATION_STATUS_LOADING = 'schema.element.initialization.status.loading',
    ELEMENT_INITIALIZATION_STATUS_UPLOAD_WAITING = 'schema.element.initialization.status.upload_waiting',
    ELEMENT_INITIALIZATION_FMU_NOT_VALID = 'schema.element.initialization.not_valid',
    ELEMENT_INITIALIZATION_RUN_AGAIN = 'schema.element.initialization.run_again',
    ELEMENT_TEXT_BLOCK_TYPING = 'schema.element.text.block.type',
    ERROR_OPEN_SCHEMA = 'schema.error_opening_schema',
    CONTEXT_SEARCH_PLACEHOLDER = 'schema.context_search.placeholder',
    ELEMENT_NOT_SUPPORTED = 'schema.element.not_supported',
    ELEMENT_ATTENTION_LABEL = 'schema.element.attention_label',
    ELEMENT_HAS_NO_GOTO_PAIR = 'schema.element.has_no_goto_pair',
    ELEMENT_HAS_NOT_UNIQUE_GOTO = 'schema.element.has_not_unique_goto',
    SCHEMA_HAS_NOT_SUPPORTED_ELEMENT = 'schema.has_not_supported_element',
    SCHEMA_HAS_NOT_SUPPORTED_ELEMENTS = 'schema.has_not_supported_elements',
    SCHEMA_HAS_INVALID_CONNECTION = 'schema.has_invalid_connection',
    SCHEMA_HAS_INVALID_CONNECTIONS = 'schema.has_invalid_connections',
    SCHEMA_INVALID_CONNECTION = 'schema.invalid_connection',

    SCHEMA_ELEMENT_EDIT_CODE = 'schema.element.edit_code',
    SCHEMA_ELEMENT_BACK_TO_SCHEMA = 'schema.element.back_to_schema',
    SCHEMA_ELEMENT_DELETED = 'schema.element.deleted',
    SCHEMA_ELEMENT_IS_DEPRECATED = 'schema.element.is_deprecated',
    PROJECT_BLOCK_INITIALIZATION = 'schema.element.initialization.project_block',
    WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA = 'workspace.project_block_edit_schema',
    WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING = 'workspace.project_block_go_to_schema_editing',
    WORKSPACE_PROJECT_BLOCK_VIEW = 'workspace.project_block_view',
    WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE = 'workspace.project_block_go_to_schema_editing_message',
    WORKSPACE_USER_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE = 'workspace.user_block_go_to_schema_editing_message',
    USER_BLOCK_MODAL_WARN = 'workspace.user.block.modal.warn',
    WORKSPACE_PROJECT_BLOCK_CONFIRM_SAVING_PROJECT = 'workspace.project_block_go_to_schema_editing_confirm_saving',
    WORKSPACE_SELECT_PROJECT = 'workspace.select_project',
    WORKSPACE_NO_PROJECTS_TO_SELECT = 'workspace.no_projects_to_select',
    NEW_WINDOW_OPEN = 'workspace.charts_open_in_new_window',
    WORKSPACE_CHART_EDITOR_OPEN = 'workspace.charts.editor.open',
    WORKSPACE_CHART_EDITOR = 'workspace.charts.editor',
    WORKSPACE_CHART_EDITOR_ANNOTATIONS = 'workspace.charts.editor.annotations',
    WORKSPACE_CHART_EDITOR_ANNOTATIONS_ADD = 'workspace.charts.editor.annotations.add',
    WORKSPACE_CHART_EDITOR_ANNOTATIONS_EMPTY = 'workspace.charts.editor.annotations.empty',
    WORKSPACE_CHART_EDITOR_NOT_EDITABLE = 'workspace.charts.editor.not_editable',
    WORKSPACE_CHART_EDITOR_NO_ANNOTATIONS = 'workspace.charts.editor.no_annotations',

    // journal
    JOURNAL_EMPTY = 'journal.empty',
    JOURNAL_STACKTRACE_TITLE = 'journal.stacktrace.title',

    // code editor
    CODE_EDITOR_NODE_NOT_FOUND = 'code_editor.node_not_found',
    CODE_EDITOR_PROPERTY_NOT_FOUND = 'code_editor.property_not_found',

    // app license
    LICENSE_KEY = 'app.license.key',
    LICENSE_ACTIVATION_KEY = 'app.license.activation.key',
    LICENSE_ACTIVATION_CODE_SUBTITLE = 'app.license.activation.code_subtitle',
    LICENSE_ACTIVATION_CODE_INFO = 'app.license.activation.code_info',
    LICENSE_ACTIVATION_CODE = 'app.license.activation.code',
    LICENSE_ACTIVATION_GET_CODE_BUTTON = 'app.license.activation.get_code_button',
    LICENSE_ACTIVATION_USER_HAS_FILE = 'app.license.activation.user_has_license_file',
    LICENSE_ACTIVATION_FILE_SUBTITLE = 'app.license.activation.file_subtitle',
    LICENSE_ACTIVATION_NOTICE = 'app.license.activation.notice',
    LICENSE_ACTIVATION_UPLOAD_HELP = 'app.license.activation.upload_help',
    LICENSE_ACTIVATION_UPLOAD_BUTTON = 'app.license.activation.upload_button',
    LICENSE_ACTIVATION_STATUS_TITLE = 'app.license.activation.status_title',
    LICENSE_KEY_ISSUE_DATE_TITLE = 'app.license.key.issue_date_title',
    LICENSE_KEY_INPUT_DATE_TITLE = 'app.license.key.input_date_title',
    LICENSE_KEY_EXPIRE_DATE_TITLE = 'app.license.key.expire_date_title',
    LICENSE_KEY_TRIAL_DATE_TITLE = 'app.license.key.trial_date_title',
    LICENSE_KEY_NO_EXPIRE = 'app.license.key.no_expire',
    LICENSE_STATUS_ACTIVATION_COMPLETED = 'app.license.status.activation_completed',
    LICENSE_STATUS_ACTIVATION_REQUIRED = 'app.license.status.activation_required',
    LICENSE_KEY_NOT_VALID = 'app.license.key.not_valid',
    LICENSE_KEY_NOT_FOUND = 'app.license.key.not_found',
    LICENSE_ACTIVE_KEY = 'app.license.key.active',
    LICENSE_INPUT_NEW_KEY = 'app.license.key.input',
    LICENSE_NEW_KEY = 'app.license.key.new',
    LICENSE_ACTIVATE_NEW_KEY = 'app.license.key.activate',
    LICENSE_KEY_ANNOTATION = 'app.license.key.annotation',
    LICENSE_KEY_PATTERN_HELP = 'app.license.key.pattern_help',
    LICENSE_HAVE_NO_DATA = 'app.license.have_no_date',
    LICENSE_BACK_TO_LIST = 'app.license.back_to_list',
    LICENSE_ACTIVATION_CODE_COPY = 'app.license.activation.code.copy',
    LICENSE_ACTIVATION_CODE_COPIED = 'app.license.activation.code.copied',
    LICENSE_EXPIRATION_SOON = 'app.license.expiration.soon',
    LICENSE_EXPIRATION_LEFT_DAYS = 'app.license.expiration.left_days',
    LICENSE_EXPIRATION_TODAY = 'app.license.expiration.today',
    LICENSE_ACTIVE_TRIAL = 'app.license.active_trial',
    LICENSE_ACTIVATION_SUCCESS = 'app.license.activation.success',
    LICENSE_ACTIVATION_STATUS_UPLOAD_LABEL = 'app.license.activation.status.upload.label',
    LICENSE_ACTIVATION_STATUS_ACTIVATION_LABEL = 'app.license.activation.status.activation_label',
    LICENSE_ACTIVATION_STATUS_SUCCESS = 'app.license.activation.status.success',
    LICENSE_ACTIVATION_STATUS_FAILED = 'app.license.activation.status.failed',
    LICENSE_ACTIVATION_STATUS_LOADING = 'app.license.activation.status.loading',
    LICENSE_ACTIVATION_STATUS_UPLOAD_WAITING = 'app.license.activation.status.upload_waiting',
    LICENSE_ACTIVATION_RUN_AGAIN = 'app.license.activation.run_again',
    LICENSE_SERVICE_UNAVAILABLE = 'app.license.service_unavailable',
    LICENSE_GATEWAY_TIMEOUT = 'app.license.gateway_timeout',

    // about platform
    ABOUT_VERSION = 'app.about.version',
    ABOUT_VERSION_BUILD = 'app.about.version_build',

    // settings
    SETTINGS_INTERFACE_TITLE = 'app.settings.interface.title',
    SETTINGS_EXTERNAL_MODELS_TITLE = 'app.settings.external_models.title',

    // tour
    TOUR_ITEM = 'tour.common.menu_item',
    TOUR_NEXT = 'tour.common.next',
    TOUR_PREV = 'tour.common.previous',
    TOUR_FINISH = 'tour.common.finish',
    TOUR_SKIP = 'tour.common.skip',

    TOUR_PROJECTS_NEW = 'tour.projects.new.title',
    TOUR_PROJECTS_NEW_DESCRIPTION = 'tour.projects.new.description',
    TOUR_PROJECTS_LIST = 'tour.projects.list.title',
    TOUR_PROJECTS_LIST_DESCRIPTION = 'tour.projects.list.title.description',

    TOUR_WORKSPACE_MODEL_CONTROL = 'tour.workspace.model_control',
    TOUR_WORKSPACE_MODEL_CONTROL_DESCRIPTION = 'tour.workspace.model_control.description',
    TOUR_WORKSPACE_MODEL_CONTROL_START = 'tour.workspace.model_control_start',
    TOUR_WORKSPACE_MODEL_CONTROL_START_DESCRIPTION = 'tour.workspace.model_control_start.description',
    TOUR_WORKSPACE_PROJECT_TITLE = 'tour.workspace.project_title',
    TOUR_WORKSPACE_PROJECT_TITLE_DESCRIPTION = 'tour.workspace.project_title.description',
    TOUR_WORKSPACE_PROJECT_SAVE = 'tour.workspace.project_save',
    TOUR_WORKSPACE_PROJECT_SAVE_DESCRIPTION = 'tour.workspace.project_save.description',
    TOUR_WORKSPACE_LIBRARY = 'tour.workspace.library',
    TOUR_WORKSPACE_LIBRARY_DESCRIPTION = 'tour.workspace.library.description',
    TOUR_WORKSPACE_LIBRARY_ITEM = 'tour.workspace.library_item',
    TOUR_WORKSPACE_LIBRARY_ITEM_DESCRIPTION = 'tour.workspace.library_item.description',
    TOUR_WORKSPACE_VISUALIZATION_TRIGGER = 'tour.workspace.visualization_trigger',
    TOUR_WORKSPACE_VISUALIZATION_TRIGGER_DESCRIPTION = 'tour.workspace.visualization_trigger.description',
    TOUR_WORKSPACE_VISUALIZATION_TAB = 'tour.workspace.visualization_tab',
    TOUR_WORKSPACE_VISUALIZATION_TAB_DESCRIPTION = 'tour.workspace.visualization_tab.description',
    TOUR_WORKSPACE_VISUALIZATION_AREA = 'tour.workspace.visualization_area',
    TOUR_WORKSPACE_VISUALIZATION_AREA_DESCRIPTION = 'tour.workspace.visualization_area.description',
    TOUR_WORKSPACE_JOURNAL_TAB = 'tour.workspace.journal_tab',
    TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION = 'tour.workspace.journal_tab.description',
    TOUR_WORKSPACE_JOURNAL_AREA = 'tour.workspace.journal_area',
    TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION = 'tour.workspace.journal_area.description',
    TOUR_WORKSPACE_RIGHT_PANEL = 'tour.workspace.right_panel',
    TOUR_WORKSPACE_RIGHT_PANEL_DESCRIPTION = 'tour.workspace.right_panel.description',

    // modal
    MODAL_HELP_SECTION_MORE_DETAILED_INFORMATION = 'modal.help_section.more_detailed_information',
    MODAL_TECH_SUPPORT_CONTACT = 'modal.tech_support_contact',
    US = 'modal.us',
    MODAL_RELEASE_INFO_DONT_SHOW_UPDATES = 'modal.release_info.dont_show_updates',
    MODAL_ACCOUNT_DELTE = 'modal.account.delete',
    MODAL_ACCOUNT_DELETE_TEXT = 'modal.account.delete.text',
    MODAL_ACCOUNT_EDIT = 'modal.account.edit',
    MODAL_ACCOUNT_ADD = 'modal.account.add',

    // COMMON
    SAVE = 'common.save',
    GENERATE = 'common.generate',
    DO_NOT_SAVE = 'common.do_not_save',
    SAVE_AS = 'common.save_as',
    ADD = 'common.add',
    HIDE = 'common.hide',
    SAVING = 'common.saving',
    SELECT = 'common.select',
    EDIT = 'common.edit',
    DELETE = 'common.delete',
    CANCEL = 'common.cancel',
    ROTATE = 'common.rotate',
    CHANGE = 'common.change',
    EDITING = 'common.editing',
    SEND = 'common.send',
    TO_APP = 'common.to_app',
    ERROR_DATA = 'common.error_data',
    LANGUAGE_RUSSIAN = 'common.language.russian',
    LANGUAGE_ENGLISH = 'common.language.english',
    LANGUAGE_RUSSIAN_SHORT = 'common.language.russian_short',
    LANGUAGE_ENGLISH_SHORT = 'common.language.english_short',
    ERROR_PAGE_NOT_FOUND = 'common.error.page_not_found',
    LANGUAGE_TITLE = 'common.interface_language_title',
    MODE_TITLE = 'common.interface_mode_title',
    THEME = 'common.interface.theme',
    THEME_DARK = 'common.interface.theme.dark',
    THEME_LIGHT = 'common.interface.theme.light',
    WHATS_NEW = 'common.whats_new',
    UNDERSTAND_THANKS = 'common.understand_thanks',
    LINK = 'common.link',
    TECHNICAL_SUPPORT = 'common.technical_support',
    SALES_DEPARTMENT = 'common.sales_department',
    CONTACTING_TECHNICAL_SUPPORT = 'common.contacting_technical_support',
    CALLING_TECHNICAL_SUPPORT = 'common.calling_technical_support',
    TECHNICAL_SUPPORT_CAPTION = 'common.technical_support_caption',
    MESSAGE = 'common.message',
    ENTER_TEXT = 'common.enter_text',
    ENTER_MESSAGE = 'common.enter_message',
    MESSAGE_SENT_SUCCESSFULLY = 'common.message_sent_successfully',
    OK_THANKS = 'common.ok_thanks',
    ERROR = 'common.error',
    CRITICAL_ERROR = 'common.critical_error',
    WRITE_TO_TECHNICAL_SUPPORT = 'common.write_to_technical_support',
    ENGINEER_MODE_TITLE = 'ENGINEER_MODE_TITLE',
    ENGINEER_CLEAR_ALL = 'engineer.clear_all',
    ENGINEER_COLUMN_TYPE = 'engineer.column.type',
    ENGINEER_COLUMN_NAME = 'engineer.column.name',
    ENGINEER_EXPORT_BlOCK = 'engineer.export.block',
    SUPPORT_SUBJECT = 'common.support_subject',
    FEATURES_SUPPORT_VALUE = 'common.support.features',
    TARIFF_SUPPORT_VALUE = 'common.support.tariffs',
    AUTH_SUPPORT_VALUE = 'common.support.auth',
    OTHER_SUPPORT_VALUE = 'common.support.other',
    NEED_HELP = 'common.need_help',
    COPY_TO_CLIPBOARD = 'common.copy_to_clipboard',
    COPIED_TO_CLIPBOARD = 'common.copied_to_clipboard',
    NOT_COPIED_TO_CLIPBOARD = 'common.not_copied_to_clipboard',
    NOT_COPIED_TO_CLIPBOARD_PROJECT_ID = 'common.not_copied_to_clipboard.project_id',
    ACCOUNT_ADDED = 'common.account_added',
    ACCOUNT_UPDATED = 'common.account_updated',
    ACCOUNT_DELETE = 'common.account_delete',
    GO_BACK_TO_AUTH_PAGE = 'common.go_back_to_auth_page',

    // CONTROLS
    ZOOM_IN = 'controls.zoom.in',
    ZOOM_OUT = 'controls.zoom.out',
    FIT_VIEW = 'controls.fit.view',
    LOCK_CANVAS = 'controls.lock.canvas',
    UNLOCK_CANVAS = 'controls.unlock.canvas',
    MINI_MAP = 'controls.schema.minimap',
    CLOSE = 'controls.projects.busy.close',
    CLOSE_PROJECT = 'controls.projects.list',
    PROJECT_BUSY_MODULES = 'projects.busy.modules.list',
    PROJECT_OPEN_ERROR = 'title.projects.busy',
    BACK_TO_PROJECTS = 'controls.back.projects.list',
    SCHEMA_SCREENSHOT = 'controls.download.schema.screenshot',

    // UNITS
    UNIT_SECOND = 'unit.second',

    // File manager
    FILE_MANAGER_FILES_COUNT = 'file.manager.elements.count',
    FILE_MANAGER_SELECTED_COUNT = 'file.manager.selected.count',
    FILE_MANAGER_OPEN_FILE = 'file.manager.open.file',
    FILE_MANAGER_REMOVE_DIALOG = 'file.manager.remove.dialog',
    FILE_MANAGER_NEW_FOLDER = 'file.manager.new.folder',
    FILE_MANAGER_SORT_ASC_TOOLTIP = 'file.manager.sort.asc.tooltip',
    FILE_MANAGER_SORT_DESC_TOOLTIP = 'file.manager.sort.desc.tooltip',
    FILE_MANAGER_SORT_DEFAULT_TOOLTIP = 'file.manager.sort.default.tooltip',
    FILE_MANAGER_SORT_DATE = 'file.manager.sort.date.tooltip',
    FILE_MANAGER_SELECT_ALL_TOOLTIP = 'file.manager.selects.all.tooltip',
    FILE_MANAGER_CREATE_FOLDER_TOOLTIP = 'file.manager.new.folder.tooltip',
    FILE_MANAGER_PREVIEW_TOOLTIP = 'file.manager.preview.show.tooltip',
    FILE_MANAGER_MANAGER_VIEW_TOOLTIP = 'file.manager.change.view.tooltip',
    FILE_MANAGER_MANAGER_VIEW_UPDATE = 'file.manager.update.view.tooltip',
    USER_BLOCK_EMPTY_MESSAGE = 'user.block.empty.message',

    SESSION_CONNECTION_LOST_TITLE = 'session.connection.lost.title',
    SESSION_CONNECTION_LOST_DESCRIPTION = 'session.connection.lost.description',
    SESSION_CONNECTION_LOST_BUTTON = 'session.connection.lost.bnutton',
}
