import { createSelector } from '@reduxjs/toolkit';

import { defineAbilityFor } from '@repeat/common-ability';
import { findProjectBlockItemsInsideGroup } from '@repeat/constants';
import { getIsDemoMode } from '@repeat/hooks';
import {
    ElemProps,
    ILibraryItem,
    ISchemaBlocksNotifications,
    LibraryTypes,
    TSchemaNode,
    User,
    WorkspaceModes,
} from '@repeat/models';

import { RootState } from '../store';

export const charts = (state: RootState) => state.workspace.graphs.charts;
export const libraryItemsState = (state: RootState) => state.workspace.libraryItems;
export const userBlocksState = (state: RootState) => state.workspace.userBlocks;
export const userBlocks = (state: RootState) => state.workspace.userBlocks.items;
export const libraryItems = (state: RootState) => state.workspace.libraryItems.items;
export const libraryPortTypes = (state: RootState) => state.workspace.libraryPortTypes.items;
export const stopProjectStatus = (state: RootState) => state.workspace.modelControl.stopProject.status;
const modelControl = (state: RootState) => state.workspace.modelControl;
export const modelControlTime = (state: RootState) => modelControl(state).modelTime;

export const availableLibraryItems = (user: User) =>
    createSelector(libraryItems, (items) => {
        const ability = defineAbilityFor(user);
        return items.filter((item: ILibraryItem) => {
            const libraryType = item.library;
            return ability.can('use', `library-${libraryType}`);
            // return !['fmi', 'codeGenerationInPort', 'codeGenerationOutPort'].includes(item.type); // TODO выносим блоки Кодогенерация и FMI в отдельные разделы?
        });
    });

export const livePermissions = (state: RootState) => state.workspace.livePermissions;

export const schema = (state: RootState) => state.workspace.schema;
export const schemaElementsWithParameters = (state: RootState) => schema(state).elementsWithParams;
export const schemaElements = (state: RootState) => schema(state).schemaItems.elements;
export const schemaGroups = (state: RootState) => schema(state).schemaItems.groups;
export const schemaGroupsElements = (state: RootState) => schema(state).schemaItems.groups?.map(group=>group.elements).flat() || [];
export const userBlockGroups = (state: RootState) => schema(state).schemaItems.userBlockGroups;

export const schemaSelectedItemsCount = (state: RootState) =>
    schema(state).selectedItems.elements.length + schema(state).selectedItems.wires.length;
export const schemaElementPropertiesSetsState = (state: RootState) => schema(state).getElementPropertiesSets;
export const solverType = (state: RootState) => schema(state).solverType;
export const libraryType = (state: RootState) => schema(state).libraryType;
export const schemaGoToMap = (state: RootState) => schema(state).goToMap;
export const schemaBlockNotifications = (state: RootState) => schema(state).blockNotifications;

export const trends = (state: RootState) => state.workspace.trends;
export const workspaceMetaElementId = (state: RootState) => state.workspace.meta.elementId;
export const workspaceMetaGroupId = (state: RootState) => state.workspace.meta.groupId;
export const workspaceMetaUserBlockId = (state: RootState) => state.workspace.meta.userBlockId;
export const workspaceMode = (state: RootState) => state.workspace.meta.mode;
export const isUserBlockEditor = (state: RootState) => state.workspace.meta.isUserBlockEditor;
export const eventLog = (state: RootState) => state.workspace.eventLog;
export const getUserBlockByBlockId = (blockId: string | null) =>
    createSelector(userBlocks, (userBlocks) => {
        return userBlocks.find((item) => item.blockId === blockId) || null;
    });

export const getSchemaNodeById = (elementId: string | null) =>
    createSelector(
        schemaElements,
        schemaGroups,
        workspaceMetaElementId,
        workspaceMetaGroupId,
        workspaceMode,
        (elements, schemaGroups, workspaceMetaElementId, workspaceMetaGroupId, workspaceMode) => {
            if (workspaceMetaGroupId === null) {
                const result =
                    elementId !== null ? elements.find((element: TSchemaNode) => element.id === elementId) : null;
                return result;
            }
            const group = schemaGroups?.find((group) => group.id.toString() === workspaceMetaGroupId);
            const result = group ? group.elements.find((element: TSchemaNode) => element.id === elementId) : null;
            return result;
        }
    );
export const getSchemaNodesByIds = (ids: string[] | undefined) =>
    createSelector(schemaElements, (elements) =>
        ids !== undefined ? elements.filter((element) => ids.includes(element.id)) : []
    );
export const getSchemaBlockNotificationById = (elementId: string) =>
    createSelector(schemaBlockNotifications, (map: ISchemaBlocksNotifications | null) =>
        map !== null ? map[elementId] || null : null
    );

export const selectedItems = (state: RootState) => schema(state).selectedItems;
export const selectedItemProperties = (state: RootState) => schema(state).selectedItemProperties;
export const undoRedo = (state: RootState) => state.workspace.undoRedo;
export const projectSettings = (state: RootState) => state.workspace.settings;
export const isRealTimeSync = (state: RootState) => projectSettings(state).isRealTimeSync || false;
export const integrationStep = (state: RootState) => projectSettings(state).integrationStep || '50';

export const getProjectSettings = createSelector([projectSettings], (settings) => settings);

export const getEventLogItems = createSelector([eventLog], (log) => {
    const items = [...log.list];
    if (Array.isArray(items) && items.length) {
        return items.reverse();
    }
    return [];
});

export const getTrends = createSelector([trends], (params) => {
    return params;
});

export const getCharts = createSelector([charts], (charts) => charts);

export const currentNodeNameAndDescription = createSelector(
    schemaElements,
    selectedItems,
    workspaceMode,
    workspaceMetaElementId,
    workspaceMetaGroupId,
    schemaGroups,
    libraryItems,
    (
        schemaElements,
        selectedItems,
        workspaceMode,
        workspaceMetaElementId,
        workspaceMetaGroupId,
        schemaGroups,
        libraryItems
    ) => {
        const selectedElement = selectedItems.elements[0];
        if (selectedElement && selectedElement.data.type === 'userBlock') {
            const item = libraryItems.find((item) => item.blockId === selectedElement.data.blockId);
            return { name: item?.name || '', description: item?.description || '' };
        }

        let currentNode;
        if (workspaceMode === WorkspaceModes.MAIN || WorkspaceModes.CODE_EDITOR) {
            currentNode = schemaElements.find((el) => el.id === selectedElement?.id);
        }

        if (workspaceMode === WorkspaceModes.GROUP) {
            const groups = schemaGroups || [];
            const group = groups.find((group) => group.id.toString() === workspaceMetaGroupId);
            if (!group) {
                return null;
            }
            currentNode = group.elements.find((el) => el.id === selectedElement?.id);
        }

        if (workspaceMode === WorkspaceModes.SUBMODEL) {
            const parentElement = schemaElements.find((el) => el.id === workspaceMetaElementId);
            if (parentElement && parentElement.data.submodelItems) {
                currentNode = parentElement.data.submodelItems.elements.find((el) => el.id === selectedElement?.id);
            }
        }
        if (!currentNode) {
            return null;
        }

        return {
            name: currentNode.data.name,
            description: currentNode.data.description,
        };
    }
);

export const isUserBlockEditorMode = createSelector(
    workspaceMetaUserBlockId,
    isUserBlockEditor,
    (workspaceMetaUserBlockId, isUserBlockEditor) => {
        return isUserBlockEditor && workspaceMetaUserBlockId !== null;
    }
);

export const currentNodeProperties = createSelector(
    schemaElements,
    selectedItems,
    workspaceMetaElementId,
    workspaceMetaGroupId,
    workspaceMode,
    schemaGroups,
    workspaceMetaUserBlockId,
    libraryItems,
    isUserBlockEditorMode,
    userBlockGroups,
    (
        schemaElements,
        selectedItems,
        workspaceMetaElementId,
        workspaceMetaGroupId,
        workspaceMode,
        schemaGroups,
        workspaceMetaUserBlockId,
        libraryItems,
        isUserBlockEditorMode,
        userBlockGroups
    ) => {
        const isDemo = getIsDemoMode();
        const isGettingUserBlockViewMode = workspaceMetaUserBlockId && isDemo;
        if (isGettingUserBlockViewMode) {
            const currentGroup =
                userBlockGroups?.find((group) => group.id.toString() === workspaceMetaElementId) ||
                userBlockGroups?.find((group) => group.parentGroupId === null);

            const currentNode =
                currentGroup && currentGroup.elements.find((el) => el.id === selectedItems.elements[0]?.id);
            if (currentNode) {
                const { blockId, type, elemProps, isIndicator, availablePorts, hasManagedPorts } = currentNode.data;
                return {
                    id: blockId,
                    type,
                    elemProps: elemProps.map((prop) => ({ ...prop, editable: false })),
                    isIndicator,
                    selectedConfiguration: null,
                    availablePorts,
                    hasManagedPorts,
                };
            }
        }
        if (isUserBlockEditorMode && workspaceMetaUserBlockId && selectedItems.elements.length === 0) {
            const currentLibraryItem = libraryItems.find((item) => item.blockId === workspaceMetaUserBlockId);
            const element = schemaElements[0];
            // if (currentLibraryItem) {
            //     const { blockId, type, elemProps, isIndicator, availablePorts, hasManagedPorts } = currentLibraryItem;
            //     return {
            //         id: blockId,
            //         type,
            //         elemProps: elemProps.map((prop) => ({ ...prop, editable: false })),
            //         isIndicator,
            //         selectedConfiguration: null,
            //         availablePorts,
            //         hasManagedPorts,
            //     };
            // }
            if (element) {
                const { blockId, type, elemProps, isIndicator, availablePorts, hasManagedPorts } = element.data;
                return {
                    id: blockId,
                    type,
                    elemProps: elemProps.map((prop) => ({ ...prop, editable: false })),
                    isIndicator,
                    selectedConfiguration: null,
                    availablePorts,
                    hasManagedPorts,
                };
            }
        }
        let currentNode;
        if (workspaceMode !== WorkspaceModes.SUBMODEL) {
            if (workspaceMode === WorkspaceModes.GROUP) {
                const currentGroup = schemaGroups?.find((group) => group.id.toString() === workspaceMetaElementId);
                currentNode =
                    currentGroup && currentGroup.elements.find((el) => el.id === selectedItems.elements[0]?.id);
            } else {
                currentNode = schemaElements.find((el) => el.id === selectedItems.elements[0]?.id);
            }
        } else {
            if (workspaceMetaGroupId === null) {
                const parentBlock = schemaElements.find((el) => el.id === workspaceMetaElementId);
                if (parentBlock && parentBlock.data.submodelItems) {
                    currentNode = parentBlock.data.submodelItems.elements.find(
                        (el) => el.id === selectedItems.elements[0]?.id
                    );
                }
            } else {
                const elements =
                    findProjectBlockItemsInsideGroup(schemaGroups || [], workspaceMetaElementId, workspaceMetaGroupId)
                        ?.elements || [];
                currentNode = elements.find((el) => el.id === selectedItems.elements[0]?.id);
            }
        }

        if (!currentNode) {
            return {
                id: 0,
                type: '',
                elemProps: [],
                isIndicator: false,
                selectedConfiguration: null,
                availablePorts: [],
                hasManagedPorts: false,
            };
        }

        const {
            data: {
                id,
                type,
                elemProps,
                propertiesToDisplay,
                isIndicator,
                selectedConfiguration,
                availablePorts,
                hasManagedPorts,
            },
        } = currentNode;

        let propertiesResult: ElemProps[] = [];
        if (propertiesToDisplay === null || propertiesToDisplay === undefined) {
            propertiesResult = elemProps.filter((prop) => !prop.isStatic);
        } else if (propertiesToDisplay?.length !== 0 && propertiesToDisplay) {
            propertiesResult = elemProps.filter((property: ElemProps) => propertiesToDisplay?.includes(property.name));
        }

        return {
            id,
            type,
            elemProps: propertiesResult,
            isIndicator,
            selectedConfiguration,
            availablePorts,
            hasManagedPorts,
        };
    }
);

export const currentNodeParameters = createSelector(
    schemaElements,
    schemaElementsWithParameters,
    selectedItems,
    workspaceMode,
    workspaceMetaElementId,
    workspaceMetaGroupId,
    schemaGroups,
    workspaceMetaUserBlockId,
    libraryItems,
    isUserBlockEditor,
    userBlockGroups,
    (
        schemaElements,
        schemaElementsWithParameters,
        selectedItems,
        workspaceMode,
        workspaceMetaElementId,
        workspaceMetaGroupId,
        schemaGroups,
        workspaceMetaUserBlockId,
        libraryItems,
        isUserBlockEditor,
        userBlockGroups
    ) => {
        const isDemo = getIsDemoMode();
        const isGettingUserBlockViewMode = workspaceMetaUserBlockId && isDemo;
        if (isGettingUserBlockViewMode) {
            const currentGroup =
                userBlockGroups?.find((group) => group.id.toString() === workspaceMetaElementId) ||
                userBlockGroups?.find((group) => group.parentGroupId === null);

            const currentNode =
                currentGroup && currentGroup.elements.find((el) => el.id === selectedItems.elements[0]?.id);
            if (currentNode) {
                const { type, elemParams, parametersToDisplay, availablePorts } = currentNode.data;
                return {
                    id: 0,
                    type,
                    elemParams,
                    parametersToDisplay,
                    availablePorts: availablePorts.map((port) => ({ ...port, isConnected: false })),
                };
            }
        }
        if (workspaceMode === WorkspaceModes.SUBMODEL) {
            if (workspaceMetaGroupId === null) {
                const parentBlock = schemaElements.find((el) => el.id === workspaceMetaElementId);
                if (parentBlock && parentBlock.data.submodelItems) {
                    const currentNode = parentBlock.data.submodelItems.elements.find(
                        (el) => el.id === selectedItems.elements[0]?.id
                    );
                    if (!currentNode) {
                        return { id: 0, type: '', elemParams: [], parametersToDisplay: null, availablePorts: [] };
                    }
                    const {
                        data: { id, type, elemParams, parametersToDisplay, availablePorts },
                    } = currentNode;
                    return { id, type, elemParams, parametersToDisplay, availablePorts };
                }
            } else {
                const elements =
                    findProjectBlockItemsInsideGroup(schemaGroups || [], workspaceMetaElementId, workspaceMetaGroupId)
                        ?.elements || [];
                const currentNode = elements.find((el) => el.id === selectedItems.elements[0]?.id);
                if (!currentNode) {
                    return { id: 0, type: '', elemParams: [], parametersToDisplay: null, availablePorts: [] };
                }
                const {
                    data: { id, type, elemParams, parametersToDisplay, availablePorts },
                } = currentNode;
                return { id, type, elemParams, parametersToDisplay, availablePorts };
            }
        }
        if (workspaceMode === WorkspaceModes.GROUP && selectedItems.elements.length !== 0) {
            const currentGroup = schemaGroups?.find((group) => group.id.toString() === workspaceMetaElementId);
            const currentNode =
                currentGroup && currentGroup.elements.find((el) => el.id === selectedItems.elements[0]?.id);
            if (!currentNode) {
                return { id: 0, type: '', elemParams: [], parametersToDisplay: null, availablePorts: [] };
            }
            const {
                data: { id, type, elemParams, parametersToDisplay, availablePorts },
            } = currentNode;
            return { id, type, elemParams, parametersToDisplay, availablePorts };
        }
        if (workspaceMode === WorkspaceModes.CODE_EDITOR && workspaceMetaGroupId) {
            const currentGroup = schemaGroups?.find((group) => group.id.toString() === workspaceMetaGroupId);
            const currentNode =
                currentGroup && currentGroup.elements.find((el) => el.id === selectedItems.elements[0]?.id);
            if (!currentNode) {
                return { id: 0, type: '', elemParams: [], parametersToDisplay: null, availablePorts: [] };
            }
            const {
                data: { id, type, elemParams, parametersToDisplay, availablePorts },
            } = currentNode;
            return { id, type, elemParams, parametersToDisplay, availablePorts };
        }
        if (workspaceMetaUserBlockId) {
            if (isUserBlockEditor && selectedItems.elements.length === 0) {
                const element = schemaElements[0];
                if (!element) {
                    return { id: 0, type: '', elemParams: [], parametersToDisplay: null, availablePorts: [] };
                }
                const { type, elemParams, parametersToDisplay, availablePorts } = element.data;
                return {
                    id: 0,
                    type,
                    elemParams,
                    parametersToDisplay,
                    availablePorts: availablePorts.map((port) => ({ ...port, isConnected: false })),
                };
            }
            const libraryItem = libraryItems.find((item) => item.blockId === workspaceMetaUserBlockId);
            if (!libraryItem) {
                return { id: 0, type: '', elemParams: [], parametersToDisplay: null, availablePorts: [] };
            }
            const { type, elemParams, parametersToDisplay, availablePorts } = libraryItem;
            return {
                id: 0,
                type,
                elemParams,
                parametersToDisplay,
                availablePorts: availablePorts.map((port) => ({ ...port, isConnected: false })),
            };
        }
        const currentNodeWithParameters = schemaElementsWithParameters.find(
            (el) => el.id.toString() === selectedItems.elements[0]?.id
        );
        const currentNode = schemaElements.find((el) => el.id === selectedItems.elements[0]?.id);

        if (!currentNode || !currentNodeWithParameters) {
            return { id: 0, type: '', elemParams: [], parametersToDisplay: null, availablePorts: [] };
        }

        const { id, elemParams } = currentNodeWithParameters;
        const {
            data: { type, parametersToDisplay, availablePorts },
        } = currentNode;

        return { id, type, elemParams, parametersToDisplay, availablePorts };
    }
);
export const elementsWithStateParameters = createSelector(
    schemaElements,
    workspaceMetaGroupId,
    schemaGroups,
    (schemaElements, workspaceMetaGroupId, schemaGroups) => {
        if (workspaceMetaGroupId === null) {
            return schemaElements
                .filter((el) => el.data.stateParameters.length !== 0 || el.data.type === 'project')
                .map((el) => ({
                    data: {
                        id: el.data.id,
                        index: el.data.index,
                        name: el.data.name,
                        stateParameters: el.data.stateParameters,
                        type: el.data.type,
                    },
                }));
        } else {
            const groups = schemaGroups || [];
            const groupElements = groups.find((group) => group.id.toString() === workspaceMetaGroupId)?.elements || [];
            return groupElements
                .filter((el) => el.data.stateParameters.length !== 0 || el.data.type === 'project')
                .map((el) => ({
                    data: {
                        id: el.data.id,
                        index: el.data.index,
                        name: el.data.name,
                        stateParameters: el.data.stateParameters,
                        type: el.data.type,
                    },
                }));
        }
    }
);

export const currentNodeIndicator = createSelector(
    schemaElements,
    selectedItems,
    workspaceMetaGroupId,
    schemaGroups,
    (schemaElements, selectedItems, workspaceMetaGroupId, schemaGroups) => {
        let currentNode;
        if (workspaceMetaGroupId === null) {
            currentNode = schemaElements.find((el) => el.id === selectedItems.elements[0]?.id);
        } else {
            const groups = schemaGroups || [];
            const groupElements = groups.find((group) => group.id.toString() === workspaceMetaGroupId)?.elements || [];
            currentNode = groupElements.find((el) => el.id === selectedItems.elements[0]?.id);
        }
        if (!currentNode) {
            return { id: 0, type: '', units: 'default' };
        }

        const {
            data: { id, type, indicator },
        } = currentNode;

        return { id, type, indicator };
    }
);

export const currentSubmodelItems = createSelector(
    schemaElements,
    schemaGroups,
    userBlockGroups,
    selectedItems,
    workspaceMetaElementId,
    workspaceMetaGroupId,
    workspaceMetaUserBlockId,
    workspaceMode,
    (
        schemaElements,
        schemaGroups,
        userBlockGroups,
        selectedItems,
        workspaceMetaElementId,
        workspaceMetaGroupId,
        workspaceMetaUserBlockId,
        workspaceMode
    ) => {
        if (workspaceMode === WorkspaceModes.GROUP) {
            const isDemo = getIsDemoMode();

            const isGettingUserBlockViewMode = workspaceMetaUserBlockId && isDemo;
            const currentGroup = isGettingUserBlockViewMode
                ? userBlockGroups?.find((group) => group.id.toString() === workspaceMetaElementId) ||
                  userBlockGroups?.find((group) => group.parentGroupId === null)
                : schemaGroups?.find((group) => group.id.toString() === workspaceMetaElementId);

            if (!currentGroup) {
                return;
            }
            return currentGroup;
        }
        if (workspaceMode === WorkspaceModes.SUBMODEL && workspaceMetaGroupId) {
            const currentGroup = schemaGroups?.find((group) => group.id.toString() === workspaceMetaGroupId);
            if (!currentGroup) {
                return null;
            }

            const projectBlock = currentGroup.elements.find((el) => el.id === workspaceMetaElementId);
            if (!projectBlock) {
                return;
            }

            return projectBlock.data.submodelItems;
        }

        const currentElementId = workspaceMetaElementId;

        const currentElement = schemaElements.find((el) => el.id === currentElementId);

        if (!currentElement) {
            return null;
        }
        return currentElement.data.submodelItems;
    }
);

export const groupElementsById = (id: string) =>
    createSelector(schemaGroups, isUserBlockEditorMode, (schemaGroups, isUserBlockEditorMode) => {
        if (isUserBlockEditorMode) {
            const groups = schemaGroups || [];
            return groups.find((group) => group.parentGroupId === null)?.elements || [];
        }
        const currentGroup = schemaGroups?.find((group) => group.id.toString() === id);
        return currentGroup?.elements || [];
    });

export const getSubmodelElementById = (id: string) =>
    createSelector(
        schemaElements,
        schemaGroups,
        userBlockGroups,
        workspaceMetaElementId,
        workspaceMetaGroupId,
        workspaceMetaUserBlockId,
        workspaceMode,
        (
            schemaElements,
            schemaGroups,
            userBlockGroups,
            workspaceMetaElementId,
            workspaceMetaGroupId,
            workspaceMetaUserBlockId,
            workspaceMode
        ) => {
            if (workspaceMode === WorkspaceModes.GROUP) {
                const isDemo = getIsDemoMode();
                const isGettingUserBlockViewMode = workspaceMetaUserBlockId && isDemo;
                const currentGroup = isGettingUserBlockViewMode
                    ? userBlockGroups?.find((group) => group.id.toString() === workspaceMetaElementId) ||
                      userBlockGroups?.find((group) => group.parentGroupId === null)
                    : schemaGroups?.find((group) => group.id.toString() === workspaceMetaElementId);
                if (!currentGroup) {
                    return;
                }
                return currentGroup.elements.find((el) => el.id === id);
            }
            if (workspaceMode === WorkspaceModes.SUBMODEL && workspaceMetaGroupId) {
                const currentGroup = schemaGroups?.find((group) => group.id.toString() === workspaceMetaGroupId);
                if (!currentGroup) {
                    return;
                }
                const subElements = currentGroup.elements.find((el) => el.id === workspaceMetaElementId)?.data
                    .submodelItems;
                if (subElements) {
                    return subElements.elements.find((el) => el.id === id);
                }
            }

            const currentElement = schemaElements.find((el) => el.id === workspaceMetaElementId);
            if (!currentElement) {
                return null;
            }
            const currentElementSubmodelItems = currentElement.data.submodelItems;
            if (!currentElementSubmodelItems) {
                return null;
            }

            return currentElementSubmodelItems.elements.find((el) => el.id === id);
        }
    );

export const currentSubmodelStateParameters = createSelector(
    workspaceMetaElementId,
    schemaElements,
    (workspaceMetaElementId, schemaElements) => {
        const element = schemaElements.find((el) => el.id === workspaceMetaElementId);
        if (element) {
            return element.data.stateParameters;
        }
        return null;
    }
);

export const currentSubmodelProject = createSelector(
    workspaceMetaElementId,
    schemaElements,
    schemaGroups,
    workspaceMetaGroupId,
    workspaceMode,
    (workspaceMetaElementId, schemaElements, schemaGroups, workspaceMetaGroupId, workspaceMode) => {
        const groups = schemaGroups || [];
        if (workspaceMode === WorkspaceModes.GROUP) {
            // просто в режиме группы (без перехода в джайтон или блок проект) workspaceMetaElementId === workspaceMEtaGroupId
            const group = groups.find((group) => group.id.toString() === workspaceMetaGroupId);
            if (!group) {
                return null;
            }
            return { projectName: group.name, projectId: '' };
        }

        if (workspaceMode === WorkspaceModes.SUBMODEL && workspaceMetaGroupId) {
            const group = groups.find((group) => group.id.toString() === workspaceMetaGroupId);
            if (!group) {
                return null;
            }
            const projectBlock = group.elements.find((el) => el.id === workspaceMetaElementId);
            if (!projectBlock) {
                return null;
            }

            const projectName = projectBlock.data.elemProps
                .find((prop) => prop.name === 'projectName')
                ?.value.toString();
            const projectId = projectBlock.data.elemProps.find((prop) => prop.name === 'projectId')?.value.toString();

            return { projectName, projectId };
        }

        const element = schemaElements.find((el) => el.id === workspaceMetaElementId);

        if (element) {
            const projectName = element.data.elemProps.find((prop) => prop.name === 'projectName')?.value.toString();
            const projectId = element.data.elemProps.find((prop) => prop.name === 'projectId')?.value.toString();

            return { projectName, projectId };
        }
        return null;
    }
);

export const currentSubmodelProjectById = (id: string) =>
    createSelector(schemaElements, schemaGroups, workspaceMetaGroupId, (schemaElements, groups, groupId) => {
        if (groupId) {
            const groupsState = groups || [];
            const group = groupsState.find((group) => group.id.toString() === groupId);
            if (group) {
                const element = group.elements.find((el) => el.id === id);
                if (!element) {
                    return null;
                }

                const projectName = element.data.elemProps
                    .find((prop) => prop.name === 'projectName')
                    ?.value.toString();
                const projectId = element.data.elemProps.find((prop) => prop.name === 'projectId')?.value.toString();

                return { projectName, projectId };
            }
        }
        const element = schemaElements.find((el) => el.id === id);
        if (!element) {
            return null;
        }

        const projectName = element.data.elemProps.find((prop) => prop.name === 'projectName')?.value.toString();
        const projectId = element.data.elemProps.find((prop) => prop.name === 'projectId')?.value.toString();

        return { projectName, projectId };
    });

export const customSubLibraries = createSelector(libraryItems, (libraryItems) => {
    const customSubLibraries = libraryItems
        .filter((item) => item.library === LibraryTypes.CUSTOM && item.subLibrary?.length !== 0)
        .map((item) => item.subLibrary);
    return Array.from(new Set(customSubLibraries));
});

// export const isUserBlockEditorMode = (state: RootState) => {
//     const isDemo = getIsDemoMode();
//     const userBlockId = state.workspace.meta.userBlockId

//     // const isUserBlockEditorMode = workspaceMode === WorkspaceModes.MAIN && workspaceMetaUserBlockId;
//     const isUserBlockEditorMode = !isDemo && userBlockId !== null;
//     return isUserBlockEditorMode;
// };

export const libraryItemNameAndDescription = createSelector(
    workspaceMetaUserBlockId,
    userBlocks,
    (workspaceMetaUserBlockId, userBlocks) => {
        const item = userBlocks.find((item) => item.blockId === workspaceMetaUserBlockId);
        return { name: item?.name || '', description: item?.description || '' };
    }
);

export const isMainGroupUserBlockEditor = createSelector(
    workspaceMetaGroupId,
    workspaceMetaUserBlockId,
    schemaGroups,
    (workspaceMetaGroupId, workspaceMetaUserBlockId, schemaGroups) => {
        const group = schemaGroups?.find((group) => group.id.toString() === workspaceMetaGroupId);
        return group?.parentGroupId === null && !!workspaceMetaUserBlockId;
    }
);

export const elementsGroup = createSelector(
    workspaceMetaElementId,
    schemaGroups,
    (workspaceMetaElementId, schemaGroups) => {
        const groups = schemaGroups || [];
        return groups.find((group) => group.id.toString() === workspaceMetaElementId)?.elements || [];
    }
);

export const isMainGroupLevel = createSelector(
    workspaceMetaElementId,
    schemaGroups,
    (workspaceMetaElementId, schemaGroups) => {
        const mainGroupId = schemaGroups?.find((group) => group.parentGroupId === null)?.id;
        return mainGroupId?.toString() === workspaceMetaElementId;
    }
);

export const mainGroupId = createSelector(schemaGroups, (schemaGroups) => {
    return schemaGroups?.find((group) => group.parentGroupId === null)?.id;
});
