import { useState, useEffect } from 'react';

import { abilitiesRulesMap } from '@repeat/common-ability';
import { IModule } from '@repeat/models';

const order = [...Object.keys(abilitiesRulesMap)];

export const useModulesSort = (initialModules: string[]) => {
    const [sortedModules, setSortedModules] = useState<string[]>([]);

    useEffect(() => {
        const sorted = [...initialModules].sort((a, b) => {
            const indexA = order.indexOf(a);
            const indexB = order.indexOf(b);

            if (indexA === -1) {
                return 1;
            }

            if (indexB === -1) {
                return -1;
            }

            return indexA - indexB;
        });

        setSortedModules(sorted);
    }, [initialModules, order]);

    return sortedModules;
};

export const sortModules = (modules: IModule[]): IModule[] => {
    return [...modules].sort((a, b) => {
        const indexA = order.indexOf(a.moduleSlug);
        const indexB = order.indexOf(b.moduleSlug);

        if (indexA === -1) {
            return 1;
        }

        if (indexB === -1) {
            return -1;
        }

        return indexA - indexB;
    });
};
