import { isAnyOf, ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import { setDefaultParameter } from 'libs/services/src/lib/Workspace/Graphs/ChartsAdapterService';

import { abilitiesRulesMap } from '@repeat/common-ability';
import { ApplicationActions } from '@repeat/common-slices';
import { ELEMENTS_INITIALIZED_BY_TEXT_FILE, getAppLocale, getHandleName } from '@repeat/constants';
import { getIsDemoMode } from '@repeat/hooks';
import {
    EChartItemType,
    ElementNotificationTypes,
    ElemProps,
    IChartList,
    ModalTypes,
    NotificationTypes,
    SchemaItemTypes,
    SchemaUpdateTypes,
    TBlockGoToType,
    TSchemaGroup,
    TSchemaNode,
    TState,
    WorkspaceModes,
} from '@repeat/models';
import { findGoToBlockIdsWithoutPair, webSocketClient } from '@repeat/services';
import { ECalculateAction, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { calculateResultModules, getTotalBlocks } from '../slices/workspace/modules/modulesSlice';

export const appendWorkspaceListeners = (listenerMiddleware: ListenerMiddlewareInstance) => {
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.initializeWorkspaceSuccess,
        effect: (action, listenerApi) => {
            const webSocketConnectLog = () => {
                const onMessage = (event: MessageEvent) => {
                    const res = JSON.parse(event.data);
                    listenerApi.dispatch(workspaceActions.addLog(res));
                };
                /* eslint-disable  @typescript-eslint/no-empty-function */
                const onOpen = () => {};
                const onClose = () => {};
                const onError = () => {
                    listenerApi.dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.WARNING,
                                message: TranslationKey.ERROR_CONNECT_LOG,
                            },
                        })
                    );
                };
                webSocketClient('/api/v1/ws/connectLog', onOpen, onMessage, onClose, onError);
            };
            webSocketConnectLog();
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.initializeWorkspaceSuccess,
        effect: (action, listenerApi) => {
            const isDemoMode = getIsDemoMode();
            if (isDemoMode) {
                return;
            }

            listenerApi.dispatch(workspaceActions.startLivePermissionsConnecting());
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.initializeWorkspaceSuccess,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    schema: {
                        schemaItems: { elements },
                        goToMap,
                    },
                },
            } = listenerApi.getState() as TState;
            const needToUpdateNodesCount = elements.filter(
                (node: TSchemaNode) => node.data.isNeedToUpdate === true
            ).length;

            const notPairedBlockIds = findGoToBlockIdsWithoutPair(goToMap);
            if (notPairedBlockIds.length > 0) {
                listenerApi.dispatch(
                    workspaceActions.notifyGoToBlocks({
                        blockIds: notPairedBlockIds,
                        type: ElementNotificationTypes.GOTO_WITHOUT_PAIR,
                    })
                );
            }

            if (needToUpdateNodesCount > 0) {
                listenerApi.dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message:
                                needToUpdateNodesCount === 1
                                    ? TranslationKey.SCHEMA_HAS_NOT_SUPPORTED_ELEMENT
                                    : TranslationKey.SCHEMA_HAS_NOT_SUPPORTED_ELEMENTS,
                        },
                    })
                );
            }
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.setSelectedItems,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    schema: { selectedItems, goToMap },
                    meta: { mode },
                },
            } = listenerApi.getState() as TState;

            // update right sidebar size
            const sidebars = document.querySelectorAll('aside[data-name="elements-container"]');
            const footer = document.querySelector('footer');
            const isFooterOpen = footer ? footer.getAttribute('data-open') : 'false';
            const tagCloud = document.querySelector('div[data-name="tag-cloud"]') as HTMLDivElement;
            const searchBar = document.querySelector('div[data-name="elements-search"]') as HTMLDivElement;
            const elementName = document.querySelector('div[data-name="element-name"]') as HTMLDivElement;
            const elementNameHeight = elementName ? elementName.clientHeight : 0;
            Array.from(sidebars).forEach((item) => {
                const component = item as HTMLDivElement;
                if (isFooterOpen === 'true') {
                    const height =
                        window.innerHeight / 2 -
                        (tagCloud.clientHeight + elementNameHeight + searchBar.clientHeight) +
                        8;
                    component.style.maxHeight = `${height / 2}px`;
                    component.style.overflowY = 'auto';
                }
                if (isFooterOpen === 'false') {
                    component.style.height = 'initial';
                }
            });
            if (mode !== WorkspaceModes.SUBMODEL && selectedItems.elements.length !== 0) {
                listenerApi.dispatch(workspaceActions.setItemsForSubmodel(action.payload));
            }

            // init block properties available values
            if (selectedItems.elements.length === 1) {
                listenerApi.dispatch(workspaceActions.initializeElementProperties({ ...selectedItems.elements[0] }));
            } else {
                listenerApi.dispatch(workspaceActions.resetElementProperties());
            }

            // notify related goto-gofrom blocks
            if (goToMap !== null) {
                if (selectedItems.elements.length === 1) {
                    const node: TSchemaNode = selectedItems.elements[0];
                    const block = node.data;
                    if (['GoTo', 'GoFrom'].includes(block.type)) {
                        const tagUuid =
                            block.elemProps.find((property: ElemProps) => property.name === 'tagId')?.value || null;

                        if (tagUuid && goToMap[tagUuid]) {
                            const item = goToMap[tagUuid];
                            if (item.goToId !== null && item.goFromIds.length > 0) {
                                const blockIds = [item.goToId, ...item.goFromIds];

                                listenerApi.dispatch(
                                    workspaceActions.notifyGoToBlocks({
                                        blockIds,
                                        type: ElementNotificationTypes.GOTO_RELATED_BLOCK,
                                    })
                                );
                            }
                        }
                    }
                } else {
                    const blockIds: string[] = [];
                    listenerApi.dispatch(
                        workspaceActions.notifyGoToBlocks({
                            blockIds,
                            type: ElementNotificationTypes.GOTO_RELATED_BLOCK,
                        })
                    );
                }
            }
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.addNode,
        effect: (action, listenerApi) => {
            // refresh goToMap
            const node: TSchemaNode = action.payload;
            const blockId = node.id;
            const block = node.data;

            listenerApi.dispatch(workspaceActions.refreshGoToMapAfterAdding({ blockId, block }));
        },
    });
    // LIVE PERMISSIONS ON ADD
    listenerMiddleware.startListening({
        matcher: isAnyOf(workspaceActions.addNode, workspaceActions.addPortNode),
        effect: (action, listenerApi) => {
            const {
                workspace: { modules },
            } = listenerApi.getState() as TState;

            const node = action['payload'] as TSchemaNode;
            const { updatedModules, newlyActivatedModules } = calculateResultModules(
                [node],
                ECalculateAction.ADD,
                modules
            );
            listenerApi.dispatch(workspaceActions.updateModules(updatedModules));

            if (newlyActivatedModules.length > 0) {
                listenerApi.dispatch(workspaceActions.sendMessageAboutNewlyPermissions(newlyActivatedModules));
            }
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.deleteSchemaItems,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    schema: {
                        schemaItems: { elements },
                    },
                    graphs: { charts },
                },
            } = listenerApi.getState() as TState;
            const locale = getAppLocale();

            const previousState = listenerApi.getOriginalState() as TState;
            const metaElementId = previousState.workspace.meta.elementId;
            const groups = previousState.workspace.schema.schemaItems.groups || [];

            const selectedNodesIds = previousState.workspace.schema.selectedItems.elements.map((n) => n.id);
            if (metaElementId === null) {
                const relatedToSelectedNodesConnections = previousState.workspace.schema.schemaItems.wires.filter(
                    (c) => selectedNodesIds.includes(c.source) || selectedNodesIds.includes(c.target)
                );

                const selectedWires = [
                    ...previousState.workspace.schema.selectedItems.wires,
                    ...relatedToSelectedNodesConnections,
                ];

                if (selectedWires.length !== 0) {
                    listenerApi.dispatch(workspaceActions.markConnectionsPortsAsUnconnected(selectedWires));
                }
            } else {
                const currentGroupWires = groups.find((group) => group.id.toString() === metaElementId)?.wires || [];
                const relatedToSelectedNodesConnections = currentGroupWires.filter(
                    (c) => selectedNodesIds.includes(c.source) || selectedNodesIds.includes(c.target)
                );

                const selectedWires = [
                    ...previousState.workspace.schema.selectedItems.wires,
                    ...relatedToSelectedNodesConnections,
                ];

                if (selectedWires.length !== 0) {
                    listenerApi.dispatch(workspaceActions.markConnectionsPortsAsUnconnected(selectedWires));
                }
            }

            // refresh goToMap
            const goToBlocksIds = previousState.workspace.schema.selectedItems.elements
                .filter((n) => ['GoTo', 'GoFrom'].includes(n.data.type))
                .map((n) => n.id);
            if (goToBlocksIds.length > 0) {
                listenerApi.dispatch(workspaceActions.refreshGoToMapAfterDeleting({ blockIds: goToBlocksIds }));
            }

            // delete related charts parameters
            const elementsIds = elements.map((el) => el.id);
            const chartsUpdated: IChartList = {};

            for (const index in charts) {
                if (index in charts) {
                    const chart = charts[index];
                    chartsUpdated[index] = Object.assign({}, chart);

                    const XParameterElement = elementsIds.find((id) => chart?.XParameters?.key.includes(id));

                    const elementIDs = chart.elementIDs;

                    chartsUpdated[index] = {
                        ...chart,
                        YParameters: chart.YParameters.filter((param) => {
                            return elementsIds.some((id) => param.key.includes(id));
                        }),
                        XParameters: XParameterElement ? chart.XParameters : setDefaultParameter(locale),
                        type: XParameterElement ? charts[index].type : EChartItemType.YFromT,
                        elementIDs: elementIDs
                            ? elementIDs.filter((elId) => {
                                  return elementsIds.some((id) => elId.includes(id));
                              })
                            : null,
                        modelNames: chart.modelNames.filter((name) => {
                            return elementsIds.some((id) => name.includes(id));
                        }),
                    };
                }
            }

            listenerApi.dispatch(workspaceActions.updateChartsList(chartsUpdated));
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.pasteItems,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    meta: { mode },
                    modules,
                    schema: {
                        schemaItems: { groups },
                    },
                },
            } = listenerApi.getState() as TState;

            if (mode !== WorkspaceModes.SUBMODEL) {
                const { elementsToPaste: nodes, wiresToPaste: connections } = action.payload;
                listenerApi.dispatch(workspaceActions.setSelectedGroup({ nodes, connections }));
                listenerApi.dispatch(workspaceActions.refreshGoToMapAfterPasteItems({ nodes }));

                const totalElements = getTotalBlocks(nodes, groups ?? []);

                const { updatedModules, newlyActivatedModules } = calculateResultModules(
                    totalElements,
                    ECalculateAction.ADD,
                    modules
                );
                listenerApi.dispatch(workspaceActions.updateModules(updatedModules));

                if (newlyActivatedModules.length > 0) {
                    listenerApi.dispatch(workspaceActions.sendMessageAboutNewlyPermissions(newlyActivatedModules));
                }
            } else {
                listenerApi.dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
            }
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.setElementPropertiesValues,
        effect: (action, { getState, dispatch }) => {
            const {
                workspace: {
                    schema: {
                        schemaItems: { elements },
                    },
                    meta: { userBlockId, mode },
                },
            } = getState() as TState;
            const { id, elemProps } = action.payload;
            const specialNodes = elements.filter(
                (n: TSchemaNode) => n.data.id === id || n.data.type === 'simulationStop'
            );
            const currentNode = specialNodes.find((n) => n.data.id === id);

            if (currentNode && currentNode.data.type === 'integrationStep') {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WARNING_INTEGRATION_STEP_PRIORITY,
                        },
                    })
                );
            }
            if (Object.keys(elemProps)[0] === 'userParameterName') {
                // TODO fix condition
                const element = elements.find((el) => el.id === id.toString());
                if (element) {
                    const parentParameter =
                        element.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value || '';
                    const name = parentParameter.toString();
                    const description = Object.values(elemProps)[0];
                    const isDemo = getIsDemoMode();

                    if (userBlockId && !isDemo) {
                        dispatch(workspaceActions.setLibraryItemParameterDescription({ name, description }));
                    } else {
                        dispatch(
                            workspaceActions.setExternalParameterDescription({
                                name,
                                description,
                            })
                        );
                    }
                }
            }

            if (!currentNode) {
                return;
            }
            const block = currentNode.data;

            // обновление списка доступных значений, если было обновлено значение свойства с динамическими доступными свойствами
            const propertiesWithDynamicValues = block
                ? block.elemProps.filter((property: ElemProps) => !!property?.availableValuesSrc)
                : [];
            if (propertiesWithDynamicValues.length > 0) {
                dispatch(
                    workspaceActions.initializeElementProperties({
                        ...currentNode,
                    })
                );
            }

            // если переименован блок GoTo | GoFrom,
            // то парные блоки тоже нужно переименовать и перегенерировать uuid на основе нового названия
            if (['GoTo', 'GoFrom'].includes(block.type) && Object.keys(elemProps).includes('tagTitle')) {
                const title = elemProps['tagTitle'];
                const tagId =
                    (block.elemProps.find((property: ElemProps) => property.name === 'tagId')?.value as string) || null;

                if (tagId !== null && title.length > 0) {
                    dispatch(
                        workspaceActions.renameGoToBlockTitle({
                            blockId: currentNode.id,
                            type: block.type as TBlockGoToType,
                            title,
                            tagId,
                        })
                    );
                }
            }
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.setIndicatorParameter, // TODO use workspaceActions.setElementPropertiesValues to set meter's block and parameter
        effect: (action, listenerApi) => {
            listenerApi.dispatch(
                workspaceActions.setSelectedItems({
                    ids: [action.payload.indicatorId.toString()],
                    type: SchemaItemTypes.NODE,
                })
            );
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.deleteSchemaItems,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    meta: { mode, elementId },
                    modules,
                },
            } = listenerApi.getState() as TState;

            const {
                workspace: {
                    schema: {
                        selectedItems: { elements },
                        schemaItems: { groups },
                    },
                },
            } = listenerApi.getOriginalState() as TState;

            const totalElements = getTotalBlocks(elements, groups ?? []);

            const { updatedModules, deactivatedModules } = calculateResultModules(
                totalElements,
                ECalculateAction.REMOVE,
                modules
            );
            listenerApi.dispatch(workspaceActions.updateModules(updatedModules));

            if (deactivatedModules.length > 0) {
                listenerApi.dispatch(workspaceActions.sendMessageAboutDeactivatedPermissions(deactivatedModules));
            }

            if (elementId !== null && mode !== WorkspaceModes.SUBMODEL && mode !== WorkspaceModes.GROUP) {
                listenerApi.dispatch(
                    workspaceActions.changeWorkspaceMode({ mode: WorkspaceModes.MAIN, elementId: null })
                );
            }
            if (mode === WorkspaceModes.SUBMODEL) {
                listenerApi.dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
            }
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.resetWorkspace,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(workspaceActions.connectionLivePermissionsLost());
            listenerApi.dispatch(workspaceActions.connectionLivePermissionsDisconnect());
            listenerApi.dispatch(ApplicationActions.hideModal({ type: ModalTypes.MODAL_SESSION_CONNECT }));
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.getProjectsForProjectBlocksSuccess,
        effect: (action, listenerApi) => {
            const state = listenerApi.getState() as TState;
            const elementsWithErrorGettingProject = state.workspace.schema.schemaItems.elements.filter(
                (el: TSchemaNode) => el.data.diff?.isErrorGettingProject
            );

            if (elementsWithErrorGettingProject.length !== 0) {
                const errorKey = TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_FOR_PROJECT_BLOCKS;

                listenerApi.dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.ERROR,
                            message: errorKey,
                        },
                    })
                );
            }

            const elementsWithModifiedConnectedProject = [
                ...state.workspace.schema.schemaItems.elements,
                ...(state.workspace.schema.schemaItems.groups?.map((group) => group.elements).flat() || []),
            ].filter((el: TSchemaNode) => el.data.diff?.connectedProject);

            if (elementsWithModifiedConnectedProject.length !== 0) {
                const errorKey = TranslationKey.WORKSPACE_PROJECT_BLOCKS_MODIFIED_CONNECTED_PROJECT;

                listenerApi.dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: errorKey,
                        },
                    })
                );
            }
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.updateUserBlocksSuccess,
        effect: (action, { getState, dispatch }) => {
            const {
                workspace: {
                    graphs: { charts },
                },
            } = getState() as TState;

            const state = getState() as TState;
            const groupsElements =
                state.workspace.schema.schemaItems.groups?.map((group) => [...group.elements]).flat() || [];
            const elementsWithUserBlockError = [
                ...state.workspace.schema.schemaItems.elements,
                ...groupsElements,
            ].filter((el: TSchemaNode) => el.data.diff?.userBlockIsChanged || el.data.diff?.userBlockIsDeleted);

            if (elementsWithUserBlockError.length !== 0) {
                const errorKey = TranslationKey.USER_BLOCKS_MODIFIED_NOTIFICATION;

                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: errorKey,
                        },
                    })
                );
            }
        },
    });
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.setBlocksGroup,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    meta: { elementId: metaElementId },
                    schema: {
                        schemaItems: { elements, groups },
                    },
                    graphs: { charts },
                },
            } = listenerApi.getState() as TState;
            const { elementId: groupId } = action.payload;
            const locale = getAppLocale();

            const groupsState = groups || [];
            let element;
            if (metaElementId === null) {
                element = elements.find((el) => el.id === groupId);
            } else {
                const currentGroup = groupsState.find((group) => group.id.toString() === metaElementId);
                if (currentGroup) {
                    element = currentGroup.elements.find((el) => el.id === groupId);
                }
            }
            const elementName = element ? `${element.data.name} [${element.data.index}]` : '';

            listenerApi.dispatch(workspaceActions.setGroupName({ groupId: Number(groupId), name: elementName }));

            // delete related charts parameters

            const group = groupsState.find((group) => group.id.toString() === groupId);
            if (group) {
                const groupElements = group.elements;

                const elementsIds = groupElements.map((el) => el.id);
                const chartsUpdated: IChartList = {};
                const updateIndex = (key: string, elementTitle: string) => {
                    const splittedComplexKey = key.split('.');

                    let modelName = key;
                    if (splittedComplexKey.length === 2) {
                        modelName = splittedComplexKey[1];
                    }
                    const splittedModelName = modelName.split('_');
                    const elementId = splittedModelName[0];
                    const indexInGroup = groupElements.find((el) => el.id === elementId)?.data.index || '';
                    const newTitle = elementTitle.replace(/\[\d+\]/, `[${indexInGroup}]`);
                    return newTitle;
                };

                for (const index in charts) {
                    if (index in charts) {
                        const chart = charts[index];
                        chartsUpdated[index] = Object.assign({}, chart);

                        const XParameterElement = elementsIds.find((id) => chart?.XParameters?.key.includes(id));

                        const elementIDs = chart.elementIDs;
                        const YParameters = chart.YParameters.map((param) => {
                            if (elementsIds.some((id) => param.key.includes(id))) {
                                const updatedElementTitle = updateIndex(param.key, param.elementTitle);
                                return {
                                    ...param,
                                    key: `${group.id}.${param.key}`,
                                    elementTitle: `${elementName} / ${updatedElementTitle}`,
                                    title: `${elementName} / ${updatedElementTitle} / ${param.parameterTitle}`,
                                };
                            }
                            return param;
                        });
                        const modelNames = chart.modelNames.map((name) => {
                            if (elementsIds.some((id) => name.includes(id))) {
                                const splittedName = name.split('.');
                                return `${group.id}.${splittedName[splittedName.length - 1]}`;
                            }
                            return name;
                        });

                        chartsUpdated[index] = {
                            ...chart,
                            YParameters,
                            XParameters: XParameterElement ? chart.XParameters : setDefaultParameter(locale),
                            type: XParameterElement ? charts[index].type : EChartItemType.YFromT,
                            elementIDs: elementIDs
                                ? elementIDs.filter((elId) => !elementsIds.some((id) => elId.includes(id)))
                                : null,
                            modelNames,
                        };
                    }
                }

                listenerApi.dispatch(workspaceActions.updateChartsList(chartsUpdated));
            }
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.initializeElementByTextFileSuccess,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    schema,
                    graphs: { charts },
                },
            } = listenerApi.getState() as TState;
            const {
                selectedItems: { elements: selectedElements },
                schemaItems: { wires, elements },
            } = schema;
            const elementId = selectedElements[0].id;
            const elementType = selectedElements[0].data.type;
            const locale = getAppLocale();

            if (!ELEMENTS_INITIALIZED_BY_TEXT_FILE.includes(elementType)) {
                return;
            }

            const chartsUpdated: IChartList = {};

            for (const index in charts) {
                if (index in charts) {
                    const chart = charts[index];
                    chartsUpdated[index] = Object.assign({}, chart);

                    const elementIDs = chart.elementIDs;

                    chartsUpdated[index] = {
                        ...chart,
                        YParameters: chart.YParameters.filter((param) => !param.key.includes(elementId)),
                        XParameters: !chart.XParameters?.key.includes(elementId)
                            ? chart.XParameters
                            : setDefaultParameter(locale),
                        type: !chart.XParameters?.key.includes(elementId) ? charts[index].type : EChartItemType.YFromT,
                        elementIDs: elementIDs ? elementIDs.filter((elId) => elId !== elementId) : null,
                        modelNames: chart.modelNames.filter((name) => !name.includes(elementId)),
                    };
                }
            }

            listenerApi.dispatch(workspaceActions.updateChartsList(chartsUpdated));
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.setBlocksGroup,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    meta: { elementId: metaElementId },
                    schema: {
                        schemaItems: { elements: currentElements, groups: currentGroups },
                    },
                    modules,
                },
            } = listenerApi.getState() as TState;
            const {
                workspace: {
                    schema: {
                        schemaItems: { elements: previousElements, groups: previousGroups },
                    },
                },
            } = listenerApi.getOriginalState() as TState;

            // смотрим, в каком контексте работаем (основая схема или группа), и берем из нее блоки (до и после события)
            const contextCurrentGroup =
                metaElementId === null
                    ? null
                    : (currentGroups ?? []).find((group) => +group.id === +metaElementId) ?? null;
            const contextPreviousGroup =
                metaElementId === null
                    ? null
                    : (previousGroups ?? []).find((group) => +group.id === +metaElementId) ?? null;
            const contextCurrentElements = !contextCurrentGroup ? currentElements : contextCurrentGroup.elements;
            const contextPreviousElements = !contextPreviousGroup ? previousElements : contextPreviousGroup.elements;

            // далее вычисляем новые блоки в контексте (сам блок Группа) + новые блоки, которые появились внутри группы (это блоки-порты)
            const previousBlocksIds = contextPreviousElements.map((node: TSchemaNode) => node.data.id);
            const currentBlocksIds = contextCurrentElements.map((node: TSchemaNode) => node.data.id);
            const previousGroupsIds = previousGroups ? previousGroups.map((group: TSchemaGroup) => group.id) : [];

            const newBlocks = contextCurrentElements.filter((node) => !previousBlocksIds.includes(node.data.id));
            const movedBlocksIds = previousBlocksIds.filter((id) => !currentBlocksIds.includes(id));
            const newGroups = (currentGroups ?? []).filter((group) => !previousGroupsIds.includes(group.id));
            let newGroupBlocks: TSchemaNode[] = [];
            if (newGroups.length > 0) {
                const group = newGroups[0];
                newGroupBlocks = group.elements.filter((node) => !movedBlocksIds.includes(node.data.id));
            }

            const totalNewBlocks = [...newBlocks, ...newGroupBlocks];

            const { updatedModules, newlyActivatedModules } = calculateResultModules(
                totalNewBlocks,
                ECalculateAction.ADD,
                modules
            );
            listenerApi.dispatch(workspaceActions.updateModules(updatedModules));

            if (newlyActivatedModules.length > 0) {
                listenerApi.dispatch(workspaceActions.sendMessageAboutNewlyPermissions(newlyActivatedModules));
            }
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.setModules,
        effect: (action, listenerApi) => {
            const {
                workspace: { modules },
            } = listenerApi.getState() as TState;
            const {
                workspace: { modules: originalModules },
            } = listenerApi.getOriginalState() as TState;

            const moduleKeys = Object.keys(abilitiesRulesMap);
            const nextModules = modules ?? {};
            const prevModules = originalModules ?? {};

            let newlyActivatedModules: string[] = [];
            let deactivatedModules: string[] = [];

            moduleKeys.forEach((module: string) => {
                const nextCount = nextModules[module] ?? 0;
                const prevCount = prevModules[module] ?? 0;

                if (nextCount > prevCount && prevCount === 0) {
                    newlyActivatedModules = [...newlyActivatedModules, module];
                }
                if (prevCount > nextCount && nextCount === 0) {
                    deactivatedModules = [...deactivatedModules, module];
                }
            });

            if (newlyActivatedModules.length > 0) {
                listenerApi.dispatch(workspaceActions.sendMessageAboutNewlyPermissions(newlyActivatedModules));
            }
            if (deactivatedModules.length > 0) {
                listenerApi.dispatch(workspaceActions.sendMessageAboutDeactivatedPermissions(deactivatedModules));
            }
        },
    });
};
