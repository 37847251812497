import { PayloadAction } from '@reduxjs/toolkit';

import { abilitiesRulesMap } from '@repeat/common-ability';
import { IModulesCounter, IModulesSocketMessage, IWorkspaceState, TSchemaGroup, TSchemaNode } from '@repeat/models';

export enum ECalculateAction {
    ADD = 'add',
    REMOVE = 'remove',
}

type TCalculateAction = ECalculateAction.ADD | ECalculateAction.REMOVE;

export const modulesReducers = {
    startLivePermissionsConnecting: (state: IWorkspaceState) => {
        state.livePermissions.isEstablishingConnection = true;
    },
    connectionLivePermissionsEstablished: (state: IWorkspaceState) => {
        state.livePermissions.isConnected = true;
        state.livePermissions.isEstablishingConnection = false;
    },
    connectionLivePermissionsLost: (state: IWorkspaceState) => {
        state.livePermissions.isConnected = false;
    },
    connectionLivePermissionsDisconnect: (state: IWorkspaceState) => {
        state.livePermissions.isEstablishingConnection = false;
    },
    sendMessageAboutNewlyPermissions: (state: IWorkspaceState, { payload }: PayloadAction<string[]>) => {
        return {
            ...state,
        };
    },
    sendMessageAboutDeactivatedPermissions: (state: IWorkspaceState, { payload }: PayloadAction<string[]>) => {
        return {
            ...state,
        };
    },
    updateModules: (state: IWorkspaceState, { payload }: PayloadAction<IModulesCounter>) => ({
        ...state,
        modules: {
            ...state.modules,
            ...payload,
        },
    }),
    setModules: (state: IWorkspaceState, { payload }: PayloadAction<IModulesCounter>) => ({
        ...state,
        modules: {
            ...payload,
        },
    }),
    updateLivePermissions: (state: IWorkspaceState, { payload }: PayloadAction<{ [key: string]: boolean }>) => ({
        ...state,
        livePermissions: {
            ...state.livePermissions,
            permissions: {
                ...state.livePermissions.permissions,
                ...payload,
            },
        },
    }),
    addDelayedModulesMessage: (state: IWorkspaceState, { payload }: PayloadAction<IModulesSocketMessage>) => ({
        ...state,
        livePermissions: {
            ...state.livePermissions,
            delayedMessages: [...state.livePermissions.delayedMessages, payload],
        },
    }),
    clearDelayedModulesMessages: (state: IWorkspaceState) => {
        state.livePermissions.delayedMessages = [];
    },
};

export const getActionBlockModules = (blocks: TSchemaNode[], action: TCalculateAction) => {
    const moduleKeys = Object.keys(abilitiesRulesMap);
    const actionModules: IModulesCounter = {};
    const actionSign = action === ECalculateAction.ADD ? 1 : -1;

    blocks.forEach((block) => {
        const nodeModule = moduleKeys.find((key) =>
            abilitiesRulesMap[key as keyof typeof abilitiesRulesMap].libraries.includes(block.data.library)
        );
        if (nodeModule) {
            const currentCount = actionModules[nodeModule] ?? 0;
            const actionCount = currentCount + actionSign * 1;

            actionModules[nodeModule] = actionCount;
        }

        if (block.data?.modules) {
            const blockModules = block.data.modules;
            Object.keys(blockModules).forEach((module) => {
                const currentCount = actionModules[module] ?? 0;
                actionModules[module] = currentCount + actionSign * blockModules[module];
            });
        }
    });

    return actionModules;
};

export const getModulesDiff = (modulesBefore: IModulesCounter, modulesAfter: IModulesCounter) => {
    let modulesDiff: IModulesCounter = modulesAfter;

    const allModulesNames = [...Object.keys(modulesBefore || {}), ...Object.keys(modulesAfter || {})].filter(
        (x, i, a) => a.indexOf(x) == i
    );

    allModulesNames.forEach((module) => {
        const beforeCount = modulesBefore[module] || 0;
        const afterCount = modulesAfter[module] || 0;

        modulesDiff = {
            ...modulesDiff,
            [module]: afterCount - beforeCount,
        };
    });

    return modulesDiff;
};

export const getActionModulesResult = (actionModules: IModulesCounter, modules: IModulesCounter | null) => {
    let mergedModules: IModulesCounter = modules ?? {};
    const newlyActivatedModules: string[] = [];
    const deactivatedModules: string[] = [];

    Object.keys(actionModules).forEach((module) => {
        const currentCount = mergedModules[module] || 0;
        const newCount = currentCount + actionModules[module];

        if (currentCount === 0 && newCount >= 1) {
            newlyActivatedModules.push(module);
        } else if (currentCount > 0 && newCount === 0) {
            deactivatedModules.push(module);
        }

        mergedModules = {
            ...mergedModules,
            [module]: newCount,
        };
    });

    return {
        updatedModules: mergedModules,
        newlyActivatedModules,
        deactivatedModules,
    };
};

export const calculateResultModules = (
    blocks: TSchemaNode[],
    action: TCalculateAction,
    modules: IModulesCounter | null
) => {
    const actionModules = getActionBlockModules(blocks, action);
    const { updatedModules, newlyActivatedModules, deactivatedModules } = getActionModulesResult(
        actionModules,
        modules
    );

    return { updatedModules, newlyActivatedModules, deactivatedModules };
};

export const getTotalBlocks = (blocks: TSchemaNode[], allGroups: TSchemaGroup[]) => {
    const blockIdToGroupMap = new Map<number, TSchemaGroup>();
    allGroups.forEach((group) => {
        blockIdToGroupMap.set(group.id, group);
    });

    const groupBlocks = blocks.filter((block) => block.data.type === 'group');
    let childBlocks: TSchemaNode[] = [];
    groupBlocks.forEach((block) => {
        const groupId = block.data.id;
        if (blockIdToGroupMap.has(groupId)) {
            const childBlockGroup = blockIdToGroupMap.get(groupId);

            if (childBlockGroup?.elements) {
                const childGroupBlocks = getTotalBlocks(childBlockGroup.elements, allGroups);
                childBlocks = [...childBlocks, ...childGroupBlocks];
            }
        }
    });

    return [...blocks, ...childBlocks];
};
