import styled from 'styled-components';

import MenuItem from '@mui/material/MenuItem';

import { pxToRem, radius } from '../../config/config';

export const SContextMenuContainer = styled.div``;

export const SContextMenu = styled.div<{ visible: boolean; style?: any }>`
    background: var(--ui-sidebar);
    border-radius: ${radius.default};
    border: 1px solid var(--ui-border);
    box-shadow: none;
    position: fixed;
    flex-direction: column;
    display: flex;
    width: auto;
    overflow: hidden;
    z-index: 100;
`;

export const SContextMenuOption = styled.button`
    border: 0;
    border-bottom: 1px solid var(--ui-border);
    display: flex;
    gap: ${pxToRem(8)};
    background: none;
    font-size: 14px;
    cursor: pointer;
    min-width: 160px;
    padding: 8px 16px;
    text-align: left;
    outline: none !important;

    &:last-child {
        border-bottom: 0;
    }

    i {
        max-width: ${pxToRem(20)};

        svg {
            fill: var(--ui-text) !important;
        }
    }

    &:hover {
        background: var(--ui-table-hover);
    }
`;

export const SContextMenuItem = styled(MenuItem)`
    display: block;
    padding: 0 20px;
    width: 100%;
`;
