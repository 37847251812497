import { DistribTypes } from '@repeat/models';

export const LOCAL_STORAGE_APP_REDUX_STATE_KEY = 'repeatApp';
export const NOTIFICATION_AUTO_HIDE_DURATION = 6000;

export const supportEmail = 'support@repeatlab.ru';
export const salesEmail = 'sales@repeatlab.ru';
export const supportSubject = 'app.repeatlab.ru';

export const supportPhone = '+7 (499) 962-81-01';

export const API_OK_CODE = 200;
export const API_VM_IS_LOCKED_CODE = 423;
export const API_INTERNAL_ERROR_CODE = 500;
export const API_BAD_REQUEST_CODE = 400;
export const API_NOT_FOUND_CODE = 404;
export const API_ALL_VMS_ARE_BUSY = 510;
export const API_SERVICE_UNAVAILABLE_CODE = 503;
export const API_GATEWAY_TIMEOUT_CODE = 504;

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';

export const VERSION = '2.7.0';
export const DEFAULT_LAST_SEEN_VERSION = '1.4.0';

export const IS_USE_FORGOT_PASSWORD = true;
export const IS_SHOW_AGREEMENT_PRIVACY = true;
export const IS_SHOW_AGREEMENT_TERMS_AND_CONDITIONS = true;
export const IS_USE_USER_BLOCKS = true;

export const IS_USE_LIBRARY_FROM_DB = false;

export const TREND_TIMEOUT_ID_STORAGE_KEY = 'trendsTimeoutId';
export const TREND_TICK = 1000;
export const TREND_DATA_MAX_ATTEMPTS = 3;
export const TREND_DATA_MAX_STEP_POINTS = 1800000;

export const RIGHT_CLICK_EVENT_BUTTON = 2;

export * from './app/paths';

export const MAX_CHARTS_GRID_COUNT = 10;

export const PROJECT_FILE_EXTENSION = 'rpt';

export const LIBRARYITEM_FILE_EXTENSION = 'json';

export const TOOLTIP_SHOW_DELAY = 1000;

export const APP_MIME_TYPE_MAP = {
    'application/vnd.repeat.mdcore': ['rptc'],
    'application/vnd.repeat.app': ['rpt'],
};

export const LICENSE_TIMEZONE = 'Europe/Moscow';
export const LICENSE_DISTRIBS = [DistribTypes.NETWORK, DistribTypes.LOCAL];
export const LICENSE_WARNING_LEFT_DAYS = 7;
export const LICENSE_FILE_MAX_SIZE = 10000; // 10Kb
export const LICENSE_CHECK_EXPIRATION_TIMEOUT = 500;

export const PHONE_MIN_LENGTH = 13;

export const AVAILABLE_LOCALES = ['ru', 'en'];
export const DEFAULT_LOCALE = 'en';
export enum PORTS {
    LOIGIN = '3002',
    ACCOUNT = '3001',
    REPEAT = '3000',
}
