import { TProxyMap } from 'libs/models/src/lib/element';
import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';
import { findMissedIndexes } from 'libs/repeat/store/src/lib/slices/workspace/schema/schemaSlices/externalProjectInfoSlice';

import {
    ElemParams,
    ElemProps,
    ILibraryItem,
    IWorkspaceState,
    PortConnectionTypes,
    PortPositions,
    PortTypes,
    TLibraryItemPort,
    TPortType,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
} from '@repeat/models';

import {
    calculatePortsLength,
    containsNumbersOnly,
    countMaxOfInputsAndOutputs,
    extractNumbers,
    getHandleName,
} from '.';

import { CANVAS } from '../canvas';
import { LIBRARIES_DEFAULT_LIST } from '../schema';

const findMissedNumbers = (arr: number[]) => {
    const missedNumbers = [];
    const sortedArr = arr.sort((a, b) => a - b);

    for (let i = 1; i <= sortedArr[sortedArr.length - 1]; i++) {
        if (!arr.includes(i)) {
            missedNumbers.push(i);
        }
    }

    return missedNumbers;
};

export const findGroups = (group: TSchemaGroup, groupsState: TSchemaGroup[]) => {
    const result = [];
    const { elements } = group;
    const groups = groupsState || [];

    if (elements.some((element) => element.data.type === 'group')) {
        const groupIds = elements.filter((element) => element.data.type === 'group').map((element) => element.id);

        for (const groupId of groupIds) {
            const foundGroup = groups.find((group) => group.id.toString() === groupId);

            if (foundGroup) {
                result.push(foundGroup);
                findGroups(foundGroup, groupsState).forEach((item) => result.push(item));
            }
        }
    }

    return result;
};

export const findParentGroup = (metaElementId: string | null, groupsState: TSchemaGroup[]) => {
    const group = groupsState.find((group) => group.id.toString() === metaElementId);
    const result: TSchemaGroup[] = [];
    if (group) {
        const parentGroup = groupsState.find((g) => g.id.toString() === group.parentGroupId);
        if (parentGroup) {
            result.push(parentGroup);
            if (parentGroup.id !== null) {
                const foundGroup = findParentGroup(parentGroup.id.toString(), groupsState);
                if (foundGroup) {
                    result.push(...foundGroup);
                }
            }
        }
    }
    return result;
};

// export const findPropertyById = (id: string, props: ElemProps[]) => {};

const roundToGridStep = (number: number) => {
    if (number % CANVAS.GRID_STEP === 0) {
        return number;
    } else {
        return Math.ceil(number / CANVAS.GRID_STEP) * CANVAS.GRID_STEP;
    }
};

export const calculateGroupBlockCoordinates = (elements: TSchemaNode[]) => {
    const xCoords = elements.map((el) => el.position.x);
    const yCoords = elements.map((el) => el.position.y);
    const xMedium = (Math.max(...xCoords) - Math.min(...xCoords)) / 2;
    const yMedium = (Math.max(...yCoords) - Math.min(...yCoords)) / 2;

    return { x: roundToGridStep(Math.min(...xCoords) + xMedium), y: roundToGridStep(Math.min(...yCoords) + yMedium) };
};

export const calculatePortBlockCoordinates = (elements: TSchemaNode[], portType: TPortType) => {
    const xCoords = elements.map((el) => el.position.x);
    const yCoords = elements.map((el) => el.position.y);
    const xMax = Math.max(...xCoords);
    const xMin = Math.min(...xCoords);
    const yMedium = (Math.max(...yCoords) - Math.min(...yCoords)) / 2;

    const deltaX = 300;
    const x = portType === PortTypes.INPUT ? xMin - deltaX : xMax + deltaX;

    return { x: roundToGridStep(x), y: roundToGridStep(Math.min(...yCoords) + yMedium) };
};

export const getParentParameterName = (node: TSchemaNode) => {
    return node.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value.toString() || '';
};

export const updateProxyMapWithParameter = (
    proxyMap: TProxyMap | null,
    type: string,
    index: number,
    elementId: string | null
): TProxyMap => {
    const proxyMapParameter = { name: `${type}_${index}`, internalBlockId: Number(elementId), internalName: '' };
    const proxyMapUpdated = proxyMap
        ? { ...proxyMap, params: [...proxyMap.params, proxyMapParameter] }
        : { props: [], params: [proxyMapParameter] };
    return proxyMapUpdated;
};

export const updatePortElementPropertiesValues = (
    elements: TSchemaNode[],
    elementId: string | null,
    type: string,
    description: string,
    index: number,
    indexInDescription: number
) => {
    return elements.map((el) => {
        if (el.id === elementId) {
            const elemProps = el.data.elemProps.map((p) => {
                if (p.name === 'parentParameter') {
                    return { ...p, value: `${type}_${index}` };
                }
                if (p.name === 'userParameterName') {
                    return { ...p, value: `${description}_${indexInDescription}` };
                }
                return p;
            });
            return { ...el, data: { ...el.data, elemProps } };
        }
        return el;
    });
};

export const setPorts = (
    parameters: ElemParams[],
    inputPort: TLibraryItemPort,
    outputPort: TLibraryItemPort,
    currentPorts: TSchemaHandle[],
    inputPortDetails: TPortTypeComplex,
    outputPortDetails: TPortTypeComplex
) => {
    return parameters.map((p) => {
        const isConnected = currentPorts.find((port) => port.name === p.name)
            ? currentPorts.find((port) => port.name === p.name)?.isConnected
            : false;
        if (p.name.includes('in')) {
            const { type, ...rest } = inputPortDetails;
            return { ...inputPort, name: p.name, isConnected, ...rest } as TSchemaHandle;
        } else {
            const { type, ...rest } = outputPortDetails;
            return { ...outputPort, name: p.name, isConnected, ...rest } as TSchemaHandle;
        }
    });
};

export const findMissingNumbers = (params: ElemParams[], type: string) => {
    const arr = params.filter((p) => p.name.includes(type)).map((p) => extractNumbers(p.name));
    if (arr.length) {
        const maxNum = Math.max(...arr);
        const fullArr = new Array(maxNum + 1).fill(false);
        for (const num of arr) {
            fullArr[num] = true;
        }
        const missingNumbers = [];
        for (let i = 1; i <= maxNum; i++) {
            if (!fullArr[i]) {
                missingNumbers.push(i);
            }
        }
        return missingNumbers;
    }
    return [];
};

export const findMissingNumbersInDescriptions = (params: ElemParams[], type: string) => {
    const arr = params.filter((p) => p.description.includes(type)).map((p) => extractNumbers(p.description));
    if (arr.length) {
        const maxNum = Math.max(...arr);
        const fullArr = new Array(maxNum + 1).fill(false);
        for (const num of arr) {
            fullArr[num] = true;
        }
        const missingNumbers = [];
        for (let i = 1; i <= maxNum; i++) {
            if (!fullArr[i]) {
                missingNumbers.push(i);
            }
        }
        return missingNumbers;
    }
    return [];
};

export const sortParameters = (params: ElemParams[]) => {
    return params.sort((a, b) => {
        const aIsIn = a.description.includes('in');
        const bIsIn = b.description.includes('in');
        if (aIsIn === bIsIn) {
            const aNum = parseInt(a.description.split('_')[1]);
            const bNum = parseInt(b.description.split('_')[1]);
            return aNum - bNum;
        } else if (aIsIn) {
            return -1;
        } else {
            return 1;
        }
    });
};

export const sortParametersByNames = (params: ElemParams[]) => {
    return params.sort((a, b) => {
        if (a.name.includes('in') && !b.name.includes('in')) {
            return -1;
        }
        if (!a.name.includes('in') && b.name.includes('in')) {
            return 1;
        }
        return 0;
    });
};

export const getPortElementLibraryName = (items: ILibraryItem[], type: string) => {
    return type === 'in'
        ? items.find((item) => item.type === 'InPort')?.name || ''
        : items.find((item) => item.type === 'OutPort')?.name || '';
};

export const findDefaultPorts = (
    libraryItem: ILibraryItem | null
): { defaultInputPort: TLibraryItemPort; defaultOutputPort: TLibraryItemPort } => {
    const input = {
        name: 'in_1',
        position: PortPositions.LEFT,
        libraries: LIBRARIES_DEFAULT_LIST,
        type: PortTypes.INPUT,
        typeConnection: PortConnectionTypes.DEFAULT_INPUT,
    };
    const output = {
        name: 'out_1',
        position: PortPositions.RIGHT,
        libraries: LIBRARIES_DEFAULT_LIST,
        type: PortTypes.OUTPUT,
        typeConnection: PortConnectionTypes.DEFAULT_OUTPUT,
    };

    if (!libraryItem) {
        return {
            defaultInputPort: input,
            defaultOutputPort: output,
        };
    }

    const ports = libraryItem.availablePorts;
    if (ports.length !== 0) {
        return {
            defaultInputPort: ports.filter((p) => p.type === PortTypes.INPUT)[0] || input,
            defaultOutputPort: ports.filter((p) => p.type === PortTypes.OUTPUT)[0] || output,
        };
    }
    return {
        defaultInputPort: input,
        defaultOutputPort: output,
    };
};

export const calculateBlockHeight = (ports: TSchemaHandle[]) => {
    const maxPortsLength = calculatePortsLength(countMaxOfInputsAndOutputs(ports), CANVAS.PORT_MARGIN);
    return Math.max(maxPortsLength, CANVAS.ELEMENT_MIN_HEIGHT);
};

export const findParameterNameByProxyMap = (proxyMap: TProxyMap, inetrnalBlockId: number) => {
    const param = proxyMap.params.find((p) => p.internalBlockId.toString() === inetrnalBlockId.toString());
    return param?.name;
};

export const updateGroupElementsAfterPortsDeleting = (
    elements: TSchemaNode[],

    parametersToDeleteByGroupIds: { [key: string]: string[] }
) => {
    return elements.map((el) => {
        if (el.id in parametersToDeleteByGroupIds) {
            const paramsToDelete = parametersToDeleteByGroupIds[el.id];

            const elemParams = el.data.elemParams.filter((param) => !paramsToDelete.includes(param.name));

            const availablePorts = el.data.availablePorts.filter((port) => !paramsToDelete.includes(port.name));
            const proxyMap = el.data.proxyMap
                ? {
                      ...el.data.proxyMap,
                      params: el.data.proxyMap.params.filter((param) => !paramsToDelete.includes(param.name)),
                  }
                : { params: [], props: [] };

            return {
                ...el,
                data: { ...el.data, elemParams, availablePorts, proxyMap },
            };
        }
        return el;
    });
};

export const updatePortsParentParameterAfterPortDeleting = (
    elements: TSchemaNode[],
    elementType: string
): TSchemaNode[] => {
    const typeForValue = elementType === 'InPort' ? 'in' : 'out';

    return elements
        .filter((el) => el.data.type === elementType)
        .map((el, index) => {
            const elemProps = el.data.elemProps.map((prop) => {
                if (prop.name === 'parentParameter') {
                    return { ...prop, value: `${typeForValue}_${index + 1}` };
                }
                return prop;
            });
            return { ...el, data: { ...el.data, elemProps } };
        });
};
export const updateParameterNameIndex = (name: string, newIndex: number) => {
    const [type, index] = name.split('_');
    return `${type}_${newIndex}`;
};

export const setPortIndex = (elements: TSchemaNode[], type: string) => {
    return elements.filter((el) => el.data.type === type).length + 1;
};

export const findParentGroupBlock = (
    groups: TSchemaGroup[],
    elements: TSchemaNode[],
    metaElementId: string | null
): TSchemaNode | null => {
    const parentGroupBlockId = groups.find((group) => group.id.toString() === metaElementId)?.parentGroupId;
    if (parentGroupBlockId === null) {
        return elements.find((el) => el.id === metaElementId) || null;
    }

    const groupParent = groups.find((group) => group.id.toString() === parentGroupBlockId);
    if (!groupParent) {
        return null;
    }

    return groupParent.elements.find((el) => el.id === metaElementId) || null;
};

export const findParentGroupId = (groups: TSchemaGroup[], groupId: string) => {
    return groups.find((group) => group.id.toString() === groupId)?.parentGroupId;
};

export const pasteGroupPorts = (
    state: IWorkspaceState,
    payload: TSchemaNode[]
): {
    elements: TSchemaNode[] | null;
    parameters: ElemParams[] | null;
    proxyMap: TProxyMap | null;
} => {
    const {
        meta: { elementId },
        schema: {
            schemaItems: { elements, groups },
        },
    } = state;

    const groupsState = groups || [];
    const inputPorts = payload.filter((el) => el.data.type === 'InPort');
    const outputPorts = payload.filter((el) => el.data.type === 'OutPort');
    const inputLibraryName = inputPorts.length ? inputPorts[0].data.name : '';
    const outputLibraryName = outputPorts.length ? outputPorts[0].data.name : '';

    const groupBlock = findParentGroupBlock(groupsState, elements, elementId);
    const currentGroup = groupsState.find((group) => group.id.toString() === elementId);

    if (groupBlock && groupBlock.data.proxyMap) {
        const params = groupBlock.data.elemParams;
        const inputParams = params.filter((p) => p.name.includes('in'));
        const outputParams = params.filter((p) => p.name.includes('out'));
        const inputParamsDefaultDescriptions: ElemParams[] = [];
        const outputParamsDefaultDescriptions: ElemParams[] = [];
        const currentInputsIndexes = inputParams.map((p) => {
            const splittedDescription = p.description.split('_');

            if (
                splittedDescription.length === 2 &&
                splittedDescription[0] === inputLibraryName &&
                containsNumbersOnly(splittedDescription[1])
            ) {
                inputParamsDefaultDescriptions.push(p);
                return Number(extractNumbers(splittedDescription[1]));
            }
            return null;
        });

        const currentOutputsIndexes = outputParams.map((p) => {
            const splittedDescription = p.description.split('_');
            if (
                splittedDescription.length === 2 &&
                splittedDescription[0] === outputLibraryName &&
                containsNumbersOnly(splittedDescription[1])
            ) {
                outputParamsDefaultDescriptions.push(p);
                return Number(extractNumbers(splittedDescription[1]));
            }
            return null;
        });

        const missedInputDescriptionIndexes = findMissedIndexes(currentInputsIndexes.filter((i) => !!i) as number[]);
        const missedOutputDescriptionIndexes = findMissedIndexes(currentOutputsIndexes.filter((i) => !!i) as number[]);
        const inputParamsNamesIndexes = inputParams.map((p) => Number(p.name.split('_')[1]));
        const outputParamsNamesIndexes = outputParams.map((p) => Number(p.name.split('_')[1]));
        const missedInputParamsNamesIndexes = findMissedIndexes(inputParamsNamesIndexes);
        const missedOutputParamsNamesIndexes = findMissedIndexes(outputParamsNamesIndexes);
        const inputSystemNamesNew: string[] = [];
        const outputSystemNamesNew: string[] = [];

        const inputParamsNew = inputPorts.map((p, index) => {
            let indexInDescription;
            let indexInName;

            if (missedInputDescriptionIndexes.length !== 0) {
                indexInDescription = missedInputDescriptionIndexes[0];
            } else {
                indexInDescription = inputParamsDefaultDescriptions.length + index + 1;
            }
            missedInputDescriptionIndexes.shift();

            if (missedInputParamsNamesIndexes.length !== 0) {
                indexInName = missedInputParamsNamesIndexes[0];
            } else {
                indexInName = index + inputParams.length + 1;
            }
            missedInputParamsNamesIndexes.shift();
            inputSystemNamesNew.push(`in_${index + inputParams.length + 1}`);

            return {
                description: `${inputLibraryName}_${indexInDescription}`,
                name: `in_${indexInName}`,
                modelName: '',
                unit: '',
                value: '',
            };
        });

        const outputParamsNew = outputPorts.map((p, index) => {
            let indexInDescription;
            let indexInName;

            if (missedOutputDescriptionIndexes.length !== 0) {
                indexInDescription = missedOutputDescriptionIndexes[0];
            } else {
                indexInDescription = outputParamsDefaultDescriptions.length + index + 1;
            }
            missedOutputDescriptionIndexes.shift();
            if (missedOutputParamsNamesIndexes.length !== 0) {
                indexInName = missedOutputParamsNamesIndexes[0];
            } else {
                indexInName = index + outputParams.length + 1;
            }
            missedOutputParamsNamesIndexes.shift();
            outputSystemNamesNew.push(`out_${index + outputParams.length + 1}`);

            return {
                description: `${outputLibraryName}_${indexInDescription}`,
                name: `out_${indexInName}`,
                modelName: '',
                unit: '',
                value: '',
            };
        });

        const paramsNew = [...groupBlock.data.elemParams, ...inputParamsNew, ...outputParamsNew];

        const proxyMapInputParamsNew = inputPorts.map((port, index) => ({
            name: `${inputParamsNew[index].name}`,
            internalBlockId: port.data.id,
            internalName: '',
        }));
        const proxyMapOutputParamsNew = outputPorts.map((port, index) => ({
            name: `${outputParamsNew[index].name}`,
            internalBlockId: port.data.id,
            internalName: '',
        }));
        const proxyMapNew = {
            ...groupBlock.data.proxyMap,
            params: [...groupBlock.data.proxyMap.params, ...proxyMapInputParamsNew, ...proxyMapOutputParamsNew],
        };

        const updateElementsWithParentParameter = (elements: TSchemaNode[]) => {
            return elements.map((el) => {
                if (el.data.type === 'InPort' || el.data.type === 'OutPort') {
                    let elemProps: ElemProps[] = el.data.elemProps;
                    let index = el.data.index;
                    if (el.data.type === 'InPort' && inputPorts.map((p) => p.id).includes(el.id)) {
                        inputPorts.forEach((port: TSchemaNode, index: number) => {
                            if (el.id === port.id) {
                                elemProps = el.data.elemProps.map((p) => {
                                    if (p.name === 'parentParameter') {
                                        return { ...p, value: inputSystemNamesNew[index] };
                                    }
                                    if (p.name === 'userParameterName') {
                                        return {
                                            ...p,
                                            value: inputParamsNew[index].description,
                                        };
                                    }
                                    return p;
                                });
                            }
                        });
                    }
                    if (el.data.type === 'OutPort' && outputPorts.map((p) => p.id).includes(el.id)) {
                        outputPorts.forEach((port, index) => {
                            if (el.id === port.id) {
                                elemProps = el.data.elemProps.map((p) => {
                                    if (p.name === 'parentParameter') {
                                        return { ...p, value: outputSystemNamesNew[index] };
                                    }
                                    if (p.name === 'userParameterName') {
                                        return {
                                            ...p,
                                            value: outputParamsNew[index].description,
                                        };
                                    }
                                    return p;
                                });
                            }
                        });
                    }
                    return { ...el, data: { ...el.data, elemProps, index } };
                }
                return el;
            });
        };

        return {
            elements: updateElementsWithParentParameter(currentGroup?.elements || []), // для текущей группы
            parameters: sortParameters(paramsNew), // для элемента с типом Группа
            proxyMap: proxyMapNew, // для элемента с типом Группа
        };
    }

    return {
        elements: null,
        parameters: null,
        proxyMap: null,
    };
};

export const setParameterDescription = (parameters: ElemParams[], name: string, description: string) => {
    return parameters.map((param) => {
        if (param.name === name) {
            return { ...param, description };
        }
        return param;
    });
};

export const filterParentWires = (
    wires: TSchemaConnection[],
    elements: TSchemaNode[],
    elementId: string | null,
    portsBlocksIds: number[]
) => {
    const elementGroupProxyMap = elements.find((el) => el.id === elementId)?.data.proxyMap;
    const parametersForDelete = portsBlocksIds.map(
        (id) => elementGroupProxyMap && findParameterNameByProxyMap(elementGroupProxyMap, id)
    );

    return wires.filter(
        (wire) =>
            !(
                elementId &&
                wire.sourceHandle.includes(elementId) &&
                parametersForDelete.some((param) => param && wire.sourceHandle.includes(param))
            ) &&
            !(
                elementId &&
                wire.targetHandle.includes(elementId) &&
                parametersForDelete.some((param) => param && wire.targetHandle.includes(param))
            )
    );
};

export const setPortsNamesByNodeId = (wires: TSchemaConnection[]) => {
    const portsNamesByNodeIdMap: { [key: string]: string[] } = {};

    wires.forEach((wire) => {
        if (portsNamesByNodeIdMap[wire.source] === undefined) {
            portsNamesByNodeIdMap[wire.source] = [getHandleName(wire.sourceHandle)];
        } else {
            portsNamesByNodeIdMap[wire.source] = [
                ...portsNamesByNodeIdMap[wire.source],
                getHandleName(wire.sourceHandle),
            ];
        }

        if (portsNamesByNodeIdMap[wire.target] === undefined) {
            portsNamesByNodeIdMap[wire.target] = [getHandleName(wire.targetHandle)];
        } else {
            portsNamesByNodeIdMap[wire.target] = [
                ...portsNamesByNodeIdMap[wire.target],
                getHandleName(wire.targetHandle),
            ];
        }
    });

    return portsNamesByNodeIdMap;
};

export const findInnerGroupsProperties = (groupsState: TSchemaGroup[], propertyFullName: string) => {
    const splittedPropertyName = propertyFullName.split('-');
    const [targetPropertyName, targetElementId] = [splittedPropertyName[0], splittedPropertyName[1]];
    const groupsIds = splittedPropertyName.slice(2);

    const elementsPropertiesByParentGroupId: { [key: string]: { elementId: string; propertyName: string } } = {};

    groupsIds.forEach((id) => {
        const parentGroupId = findParentGroupId(groupsState, id);

        const groupIdIndex = splittedPropertyName.findIndex((item) => id === item);
        if (groupIdIndex === -1) {
            return;
        }
        const propertyName = splittedPropertyName.slice(0, groupIdIndex).join('-');

        if (parentGroupId) {
            elementsPropertiesByParentGroupId[parentGroupId] = { elementId: id, propertyName };
        }
    });
    elementsPropertiesByParentGroupId[groupsIds[0]] = { elementId: targetElementId, propertyName: targetPropertyName };

    return elementsPropertiesByParentGroupId;
};

export const findOuterGroupsProperties = (
    payload: { id: number; elemProps: { [key: string]: string } },
    metaElementId: string | null,
    groupsState: TSchemaGroup[]
) => {
    const groups = [
        groupsState.find((group) => group.id.toString() === metaElementId),
        ...findParentGroup(metaElementId, groupsState),
    ];
    const parentGroupsIds = groups.map((group) => group && findParentGroupId(groupsState, group.id.toString()));

    const obj: { [parentGroupId: string]: { elementId: string; propertyName: string } } = {};

    parentGroupsIds.forEach((id, index) => {
        const propertyName = `${Object.keys(payload.elemProps)[0]}-${payload.id}`;
        if (id) {
            obj[id] = {
                elementId: groups[index]?.id.toString() || '',
                propertyName,
            };
        } else {
            /// TODO
            obj['MAIN'] = {
                elementId: groups[index]?.id.toString() || '',
                propertyName,
            };
        }
    });
    return obj;
};

export const findOuterGroupsForDeletingProperties = (metaElementId: string | null, groupsState: TSchemaGroup[]) => {
    const groups = [
        groupsState.find((group) => group.id.toString() === metaElementId),
        ...findParentGroup(metaElementId, groupsState),
    ];
    const parentGroupsIds = groups.map((group) => group && findParentGroupId(groupsState, group.id.toString()));

    const obj: { [parentGroupId: string]: string } = {};

    parentGroupsIds.forEach((id, index) => {
        if (id) {
            obj[id] = groups[index]?.id.toString() || '';
        } else {
            // TODO
            obj['MAIN'] = groups[index]?.id.toString() || '';
        }
    });
    return obj;
};

const updatePropertyName = (metaElementId: string | null, newGroupId: string, name: string) => {
    const splittedName = name.split('-');
    if (metaElementId === null) {
        return '';
    }
    const index = splittedName.indexOf(metaElementId);

    if (index === -1) {
        if (splittedName.length === 2) {
            return [...splittedName, newGroupId].join('-');
        }
        return '';
    }

    const startArray = splittedName.slice(0, index);
    const restArray = splittedName.slice(index);

    return [...startArray, newGroupId, ...restArray].join('-');
};

// обновление свойств всех родительских групп при создании группы (в режиме группы) из блоков, свойства которых уже торчали наружу
export const findOuterGroupsPropertiesToUpdate = (
    elementsForSubmodel: TSchemaNode[],
    metaElementId: string | null,
    groupsState: TSchemaGroup[],
    elementsState: TSchemaNode[],
    newGroupId: string
) => {
    const groups = [
        groupsState.find((group) => group.id.toString() === metaElementId),
        ...findParentGroup(metaElementId, groupsState),
    ];
    const groupsIds = groups.map((group) => group?.id.toString());
    const parentIdByGroupId: { [key: string]: string | null } = {};
    const parentGroupsIds = groups.map((group) => {
        if (group && group.id) {
            parentIdByGroupId[group.id.toString()] = group.parentGroupId;
        }
        return group?.parentGroupId;
    });

    const elementsForSubmodelIds = elementsForSubmodel.map((el) => el.id);
    const groupElements: TSchemaNode[] = [];

    parentGroupsIds.forEach((id, index) => {
        groupElements.push(
            ...(groupsState
                .find((group) => group.id.toString() === id)
                ?.elements.filter((el) => el.data.type === 'group') || [])
        );
        if (id === null) {
            groupElements.push(...(elementsState.filter((el) => el.id.toString() === groupsIds[index]) || []));
        }
    });
    const objobj: { [key: string]: ElemProps[] } = {};
    groupElements.forEach((el, index) => {
        const props = el.data.elemProps.filter((item) => {
            return elementsForSubmodelIds.some((id) => item.name.includes(id)); // только те свойства, которые придется менять, т к меняется адрес их целевых блоков
        });
        objobj[el.id] = props;
    });

    const elementsGroupProperties = groupElements
        .map((el) => el.data.elemProps)
        .reduce((acc, val) => acc.concat(val), [])
        .filter((item) => {
            return elementsForSubmodelIds.some((id) => item.name.includes(id));
        });

    const propertiesToChangeNames = elementsGroupProperties.map((prop) => prop.name);

    const innerGroupProperties = elementsGroupProperties
        .map((prop) => prop.name)
        .map((name) => findInnerGroupsProperties(groupsState, name));

    const propsByElementId: { [elId: string]: { [oldName: string]: string }[] } = {};

    groupElements.forEach((el) => {
        el.data.elemProps.forEach((prop) => {
            if (propertiesToChangeNames.includes(prop.name)) {
                if (el.id in propsByElementId) {
                    propsByElementId[el.id].push({
                        [prop.name]: updatePropertyName(metaElementId, newGroupId, prop.name),
                    });
                } else {
                    propsByElementId[el.id] = [
                        { [prop.name]: updatePropertyName(metaElementId, newGroupId, prop.name) },
                    ];
                }
            }
        });
    });

    // parentGroupsIds.forEach((id) => {
    //     if (id) {
    //     }
    // });

    return { propsByElementId, parentIdByGroupId };
};

export const findProjectBlockItemsInsideGroup = (
    groupsState: TSchemaGroup[],
    metaElementId: string | null,
    metaGroupId: string | null
) => {
    const groups = groupsState || [];
    const group = groups.find((group) => group.id.toString() === metaGroupId);
    if (!group) {
        return null;
    }

    const currentNode = group.elements.find((el) => el.id === metaElementId);
    if (!currentNode) {
        return null;
    }

    return {
        elements: currentNode.data.submodelItems?.elements || [],
        wires: currentNode.data.submodelItems?.elements || [],
    };
};

// export const addLibraryItemParameter = (state: IWorkspaceState, elementId: string, type: string) => {
//     const {
//         meta: { userBlockId },
//         schema: {
//             schemaItems: { elements },
//         },
//         libraryItems: { items },
//         libraryPortTypes: { items: portTypes },
//     } = state;

//     let paramsNew;
//     let index = 1;
//     let indexInDescription = 1;
//     const description = getPortElementLibraryName(items, type);

//     const libraryItems = items.map((item) => {
//         if (item.blockId === userBlockId) {
//             const params = item.elemParams;
//             const missedIndexes = findMissingNumbers(params, type);
//             const missedIndexesInDescriptions = findMissingNumbersInDescriptions(params, description);
//             index =
//                 missedIndexes.length === 0 ? params.filter((p) => p.name.includes(type))?.length + 1 : missedIndexes[0];
//             indexInDescription =
//                 missedIndexesInDescriptions.length === 0
//                     ? params.filter((p) => p.description.includes(description))?.length + 1
//                     : missedIndexesInDescriptions[0];
//             paramsNew = [
//                 ...item.elemParams,
//                 {
//                     description: `${description}_${indexInDescription}`,
//                     name: `${type}_${index}`,
//                     modelName: '',
//                     unit: '',
//                     value: '',
//                 },
//             ];
//             const { defaultInputPort, defaultOutputPort } = findDefaultPorts(item);

//             const inputPortDetails = {
//                 ...findPortDetails(portTypes, PortConnectionTypes.DEFAULT_INPUT, 'in_1'), // дает direction и compatibleTypes
//                 type: PortConnectionTypes.DEFAULT_INPUT, // TODO  use typeConnection of connected block
//             };
//             const outputPortDetails = {
//                 ...findPortDetails(portTypes, PortConnectionTypes.DEFAULT_OUTPUT, 'out_1'),
//                 type: PortConnectionTypes.DEFAULT_OUTPUT, // TODO  use typeConnection of connected block
//             };

//             const itemAvailablePorts = item.availablePorts.map((port) => {
//                 const { direction, compatibleTypes } = findPortDetails(portTypes, port.typeConnection, port.name);
//                 return {
//                     ...port,
//                     isConnected: false,
//                     direction,
//                     compatibleTypes,
//                 };
//             });
//             const availablePorts = setPorts(
//                 paramsNew,
//                 defaultInputPort,
//                 defaultOutputPort,
//                 itemAvailablePorts,
//                 inputPortDetails,
//                 outputPortDetails
//             );

//             const newProxyMapParam = { internalBlockId: Number(elementId), internalName: '', name: `${type}_${index}` };

//             return {
//                 ...item,
//                 elemParams: paramsNew,
//                 availablePorts,
//                 view: { ...item.view, minHeight: calculateBlockHeight(availablePorts) },
//                 proxyMap: item.proxyMap
//                     ? { params: [...item.proxyMap.params, newProxyMapParam], props: item.proxyMap.props }
//                     : { params: [], props: [] },
//             };
//         }
//         return item;
//     });

//     return {
//         ...state,
//         libraryItems: { ...state.libraryItems, items: libraryItems },
//         schema: {
//             ...state.schema,
//             schemaItems: {
//                 ...state.schema.schemaItems,
//                 elements: updatePortElementPropertiesValues(
//                     elements,
//                     elementId,
//                     type,
//                     description,
//                     index,
//                     indexInDescription
//                 ),
//             },
//         },
//     };
// };

export const calculateGroupNodeIndex = (elements: TSchemaNode[]) => {
    const allIndexes = elements.map((el) => extractNumbers(el.data.index)).filter((index) => !!index);
    const missedIndexes = findMissedNumbers(allIndexes);

    if (missedIndexes.length !== 0) {
        const numberIndex = missedIndexes[0];
        return `${numberIndex}`;
    } else {
        return `${elements.length}`;
    }
};
