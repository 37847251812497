import { FC } from 'react';
import { useIntl } from 'react-intl';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

import { TranslationKey } from '@repeat/translations';

import { messages } from '../translation';

export const TextEditor: FC<{ value: string; setValue: (value: string) => void }> = ({ value, setValue }) => {
    const { formatMessage } = useIntl();

    const toolbarOptions = [
        [{ header: '1' }, { header: '2' }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ];
    const modules = {
        toolbar: toolbarOptions,
    };

    return (
        <ReactQuill
            placeholder={formatMessage(messages[TranslationKey.USER_BLOCK_DESCRIPTION])}
            value={value}
            onChange={setValue}
            modules={modules}
        />
    );
};
