import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { lte } from 'semver';

import { updateSchema_patch_2_5_1, wiresCheckValidConnections } from 'libs/services/src/lib/Schema/patches/2.5.0';

import { ApplicationActions } from '@repeat/common-slices';
import {
    CANVAS,
    calculatePortsLength,
    countMaxOfInputsAndOutputs,
    getHandleName,
    setPortsByParameters,
} from '@repeat/constants';
import {
    ElemParams,
    ElemProps,
    IModulesCounter,
    IProjectInfo,
    IWorkspaceState,
    NotificationTypes,
    PortTypes,
    Statuses,
    TProxyMap,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
} from '@repeat/models';
import { ProjectsService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';

import { workspaceActions } from '@repeat/store';
import { actions } from '../..';
import { getActionModulesResult, getModulesDiff } from '../../modules/modulesSlice';

export const submodelSlice = {
    getSubmodelItemsRequest: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getSubmodelsSchemaItems: { ...state.schema.getSubmodelsSchemaItems, status: Statuses.LOADING },
            },
        };
    },

    getSubmodelItemsSuccess: (
        state: IWorkspaceState,
        action: PayloadAction<{
            elementId: string;
            schemaData: {
                elements: TSchemaNode[];
                wires: TSchemaConnection[];
                proxyMap: TProxyMap | null;
                externalInfo: { parameters: ElemParams[] | null; properties: ElemProps[] | null } | null;
            };
        }>
    ) => {
        const {
            elementId,
            schemaData: { elements, wires, proxyMap, externalInfo },
        } = action.payload;
        const { groupId } = state.meta;
        const groups = state.schema.schemaItems.groups || [];

        if (!elementId) {
            return;
        }

        const updateElements = (elementsState: TSchemaNode[]) => {
            return elementsState.map((el) => {
                if (el.id === elementId) {
                    const obj: {
                        [key: string]: {
                            internalElementId: string;
                            internalPropName: string;
                            internalPropValue: string;
                        };
                    } = {};
                    const parentElementProperties = el.data.elemProps.filter((p) => !p.isStatic);

                    parentElementProperties.forEach(
                        (p) =>
                            (obj[p.name] = {
                                internalElementId: p.name.split('-')[1],
                                internalPropName: p.name.split('-')[0],
                                internalPropValue: p.value.toString(),
                            })
                    );

                    const elementsWithUpdatedPropertiesValues = elements.map((element) => {
                        const props = element.data.elemProps.map((prop) => {
                            const key = `${prop.name}-${element.id}`;
                            const parentProperty = obj[key];
                            if (parentProperty && prop.name === parentProperty.internalPropName) {
                                return { ...prop, value: parentProperty.internalPropValue };
                            }
                            return prop;
                        });
                        return { ...element, data: { ...element.data, elemProps: props } };
                    });

                    return {
                        ...el,
                        data: {
                            ...el.data,
                            submodelItems: { elements: elementsWithUpdatedPropertiesValues, wires },
                        },
                    };
                }
                return el;
            });
        };

        if (groupId) {
            const groupsNew = groups.map((group) => {
                if (group.id.toString() === groupId) {
                    const elements = updateElements(group.elements);
                    return { ...group, elements };
                }
                return group;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, groups: groupsNew },
                    getSubmodelsSchemaItems: { ...state.schema.getSubmodelsSchemaItems, status: Statuses.SUCCEEDED },
                },
            };
        }

        const elementsNew = updateElements(state.schema.schemaItems.elements);

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements: elementsNew },
                getSubmodelsSchemaItems: { ...state.schema.getSubmodelsSchemaItems, status: Statuses.SUCCEEDED },
            },
        };
    },

    setBlocksGroup: (state: IWorkspaceState, action: PayloadAction<{ state: IWorkspaceState; elementId: string }>) => {
        return action.payload.state;
    },
    setGroupName: (state: IWorkspaceState, { payload }: PayloadAction<{ groupId: number; name: string }>) => {
        const { groupId, name } = payload;
        const elements = state.schema.schemaItems.elements;
        const groupsState = state.schema.schemaItems.groups || [];
        const { elementId } = state.meta;
        const groups = groupsState.map((group) => {
            if (group.id === groupId) {
                return { ...group, name };
            }
            return group;
        });
        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((el) => {
                if (el.id === groupId.toString()) {
                    return { ...el, data: { ...el.data, name } };
                }
                return el;
            });
        };
        if (elementId === null) {
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, elements: updateElements(elements), groups },
                },
            };
        } else {
            const group = groups.find((group) => group.id.toString() === elementId);
            if (!group) {
                return;
            }
            const groupsUpdated = groups.map((group) => {
                if (group.id.toString() === elementId) {
                    return { ...group, elements: updateElements(group.elements) };
                }
                return group;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, groups: groupsUpdated },
                },
            };
        }
    },

    setGroupPropertyDescription: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ groupId: number; name: string; description: string }>
    ) => {
        const { groupId, name, description } = payload;
        const groups = state.schema.schemaItems.groups || [];
        const elements = state.schema.schemaItems.elements;
        const { elementId } = state.meta;
        if (elementId === null) {
            const index = elements.findIndex((el) => el.id === groupId.toString());
            if (index !== -1) {
                const prop = elements[index].data.elemProps.find((prop) => prop.name === name);

                if (prop) {
                    prop.description = description;
                }
            }
        } else {
            const group = groups.find((group) => group.id.toString() === elementId);
            if (!group) {
                return;
            }

            const index = group.elements.findIndex((el) => el.id === groupId.toString());
            if (index !== -1) {
                const prop = group.elements[index].data.elemProps.find((prop) => prop.name === name);

                if (prop) {
                    prop.description = description;
                }
            }
        }
    },

    getSubmodelItemsFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getSubmodelsSchemaItems: {
                    ...state.schema.getSubmodelsSchemaItems,
                    status: Statuses.FAILED,
                    error: payload.error,
                },
            },
        };
    },

    getProjectBlockRequest: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectBlock: { ...state.schema.getProjectBlock, status: Statuses.LOADING },
            },
        };
    },
    getProjectBlockSuccess: (state: IWorkspaceState, { payload }: PayloadAction<IProjectInfo>) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectBlock: { ...state.schema.getProjectBlock, status: Statuses.SUCCEEDED, project: payload },
            },
        };
    },
    getProjectBlockFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectBlock: { ...state.schema.getProjectBlock, status: Statuses.FAILED, error: payload.error },
            },
        };
    },

    updateProjectBlockSuccess: (
        state: IWorkspaceState,
        action: PayloadAction<{
            elementId: string;
            data: {
                proxyMap: TProxyMap | null;
                externalInfo: { parameters: ElemParams[]; properties: ElemProps[] } | null;
                wires: TSchemaConnection[];
                elements: TSchemaNode[];
            };
            ports: TSchemaHandle[];
            height: number;
            hash: string;
            modules: IModulesCounter;
        }>
    ) => {
        const {
            elementId,
            data: { proxyMap, externalInfo },
            ports,
            height,
            hash,
            modules,
        } = action.payload;

        if (!elementId) {
            return;
        }
        const groupsState = state.schema.schemaItems.groups || [];

        const elemParams = externalInfo ? externalInfo.parameters : [];
        const elemProps = externalInfo ? externalInfo.properties : [];

        const portsByParametersInputs: TSchemaHandle[] = ports.filter((port) => port.type === PortTypes.INPUT);
        const portsByParametersOutputs: TSchemaHandle[] = ports.filter((port) => port.type === PortTypes.OUTPUT);

        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((el) => {
                const oldHash = el.data.hash || '';

                if (el.id === elementId) {
                    const diff = el.data.diff
                        ? { ...el.data.diff }
                        : { connectedProject: false, isErrorGettingProject: false };
                    const availablePorts = el.data.availablePorts;

                    const availablePortsInputs = availablePorts.filter((p) => p.name.includes('in'));
                    const availablePortsOutputs = availablePorts.filter((p) => p.name.includes('out'));

                    const availablePortsInputsNames = availablePortsInputs.map((p) => p.name);
                    const availablePortsOutputsNames = availablePortsOutputs.map((p) => p.name);

                    let portsResultInputs = [...availablePortsInputs];
                    let portsResultOutputs = [...availablePortsOutputs];

                    if (availablePortsInputs.length < portsByParametersInputs.length) {
                        portsByParametersInputs.forEach((port) => {
                            if (!availablePortsInputsNames.includes(port.name)) {
                                portsResultInputs.push(port);
                            }
                        });
                    } else if (availablePortsInputs.length > portsByParametersInputs.length) {
                        const portsByParametersInputsNames = portsByParametersInputs.map((p) => p.name);
                        portsResultInputs = portsResultInputs.filter((p) =>
                            portsByParametersInputsNames.includes(p.name)
                        );
                    } else {
                        portsResultInputs = portsByParametersInputs;
                    }

                    if (availablePortsOutputs.length < portsByParametersOutputs.length) {
                        portsByParametersOutputs.forEach((port) => {
                            if (!availablePortsOutputsNames.includes(port.name)) {
                                portsResultOutputs.push(port);
                            }
                        });
                    } else if (availablePortsOutputs.length > portsByParametersOutputs.length) {
                        const portsByParametersOutputsNames = portsByParametersOutputs.map((p) => p.name);
                        portsResultOutputs = portsResultOutputs.filter((p) =>
                            portsByParametersOutputsNames.includes(p.name)
                        );
                    } else {
                        portsResultOutputs = portsByParametersOutputs;
                    }

                    const diffUpd =
                        oldHash !== hash ? { ...diff, connectedProject: true } : { ...diff, connectedProject: false };

                    const currentProps = el.data.elemProps;
                    const currentPropsNames = currentProps.map((prop) => prop.name);
                    const newProps = elemProps.map((prop) => {
                        if (currentPropsNames.includes(prop.name)) {
                            const currentProperty = currentProps.find((p) => p.name === prop.name);
                            if (currentProperty) {
                                return { ...prop, value: currentProperty.value.toString() };
                            }
                        }
                        return prop;
                    });

                    const elementData = {
                        ...el,
                        height,
                        data: {
                            ...el.data,
                            elemParams,
                            elemProps: [...el.data.elemProps.filter((pr) => pr.isStatic), ...newProps],
                            availablePorts: [...portsResultInputs, ...portsResultOutputs],
                            diff: diffUpd,
                            hash,
                            modules,
                        },
                    };
                    if (proxyMap) {
                        return { ...elementData, data: { ...elementData.data, proxyMap } };
                    }
                    return elementData;
                }
                return el;
            });
        };
        const elementsNew = updateElements(state.schema.schemaItems.elements);

        const elementsWithParams = state.schema.elementsWithParams.map((el) => {
            if (el.id.toString() === elementId) {
                return { ...el, elemParams: externalInfo?.parameters || [] };
            }
            return el;
        });

        const wiresNew = state.schema.schemaItems.wires.filter((wire) => {
            const sourceElement = elementsNew.find((el) => el.id === wire.source);
            const sourceHandleName = getHandleName(wire.sourceHandle);

            const targetElement = elementsNew.find((el) => el.id === wire.target);
            const targetHandleName = getHandleName(wire.targetHandle);
            return (
                sourceElement?.data.elemParams.find((p) => p.name === sourceHandleName) &&
                targetElement?.data.elemParams.find((p) => p.name === targetHandleName)
            );
        });
        const wiresNewSourceIds = wiresNew.map((wire) => wire.source);
        const wiresNewTargetIds = wiresNew.map((wire) => wire.target);

        const deletedWires = state.schema.schemaItems.wires.filter((x) => !wiresNew.some((y) => x.id === y.id));
        const deletedWiresSourceIds = deletedWires.map((wire) => wire.source);
        const deletedWiresTargetIds = deletedWires.map((wire) => wire.target);
        const elementsUpd = elementsNew.map((el) => {
            if (deletedWiresSourceIds.includes(el.id)) {
                const wire = deletedWires.find((wire) => wire.source === el.id);
                const portName = getHandleName(wire?.sourceHandle || '');
                const ports = el.data.availablePorts.map((port) => {
                    if (port.name === portName) {
                        return { ...port, isConnected: false };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts: ports } };
            }
            if (deletedWiresTargetIds.includes(el.id)) {
                const wire = deletedWires.find((wire) => wire.target === el.id);
                const portName = getHandleName(wire?.targetHandle || '');
                const ports = el.data.availablePorts.map((port) => {
                    if (port.name === portName) {
                        return { ...port, isConnected: false };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts: ports } };
            }
            if (el.data.type === 'project' && wiresNewSourceIds.includes(el.id)) {
                const wire = wiresNew.find((wire) => wire.source === el.id);
                const sourcePortName = getHandleName(wire?.sourceHandle || '');

                const ports = el.data.availablePorts.map((port) => {
                    if (port.name === sourcePortName) {
                        return { ...port, isConnected: true };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts: ports } };
            }

            if (el.data.type === 'project' && wiresNewTargetIds.includes(el.id)) {
                const wire = wiresNew.find((wire) => wire.target === el.id);

                const targetPortName = getHandleName(wire?.targetHandle || '');
                const ports = el.data.availablePorts.map((port) => {
                    if (port.name === targetPortName) {
                        return { ...port, isConnected: true };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts: ports } };
            }

            return el;
        });

        const groups = groupsState.map((group) => {
            const groupElementsNew = updateElements(group.elements);
            const groupWiresNew = group.wires.filter((wire) => {
                const sourceElement = groupElementsNew.find((el) => el.id === wire.source);
                const sourceHandleName = getHandleName(wire.sourceHandle);

                const targetElement = groupElementsNew.find((el) => el.id === wire.target);
                const targetHandleName = getHandleName(wire.targetHandle);
                return (
                    sourceElement?.data.elemParams.find((p) => p.name === sourceHandleName) &&
                    targetElement?.data.elemParams.find((p) => p.name === targetHandleName)
                );
            });
            const groupWiresNewSourceIds = groupWiresNew.map((wire) => wire.source);
            const groupWiresNewTargetIds = groupWiresNew.map((wire) => wire.target);

            const deletedWires = state.schema.schemaItems.wires.filter(
                (x) => !groupWiresNew.some((y) => x.id === y.id)
            );
            const deletedWiresSourceIds = deletedWires.map((wire) => wire.source);
            const deletedWiresTargetIds = deletedWires.map((wire) => wire.target);
            const groupElementsUpd = groupElementsNew.map((el) => {
                if (deletedWiresSourceIds.includes(el.id)) {
                    const wire = deletedWires.find((wire) => wire.source === el.id);
                    const portName = getHandleName(wire?.sourceHandle || '');
                    const ports = el.data.availablePorts.map((port) => {
                        if (port.name === portName) {
                            return { ...port, isConnected: false };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }
                if (deletedWiresTargetIds.includes(el.id)) {
                    const wire = deletedWires.find((wire) => wire.target === el.id);
                    const portName = getHandleName(wire?.targetHandle || '');
                    const ports = el.data.availablePorts.map((port) => {
                        if (port.name === portName) {
                            return { ...port, isConnected: false };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }
                if (el.data.type === 'project' && groupWiresNewSourceIds.includes(el.id)) {
                    const wire = groupWiresNew.find((wire) => wire.source === el.id);
                    const sourcePortName = getHandleName(wire?.sourceHandle || '');

                    const ports = el.data.availablePorts.map((port) => {
                        if (port.name === sourcePortName) {
                            return { ...port, isConnected: true };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }

                if (el.data.type === 'project' && groupWiresNewTargetIds.includes(el.id)) {
                    const wire = groupWiresNew.find((wire) => wire.target === el.id);

                    const targetPortName = getHandleName(wire?.targetHandle || '');
                    const ports = el.data.availablePorts.map((port) => {
                        if (port.name === targetPortName) {
                            return { ...port, isConnected: true };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }

                return el;
            });
            return {
                ...group,
                elements: groupElementsUpd,
                wires: wiresCheckValidConnections(groupWiresNew, groupElementsUpd),
            };
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: elementsUpd,
                    wires: wiresCheckValidConnections(wiresNew, elementsUpd),
                    groups,
                },
                elementsWithParams,
            },
        };
    },
    updateProjectBlockFailed: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ error: string; projectsIds: string[] }>
    ) => {
        const elements = state.schema.schemaItems.elements.map((el) => {
            const projectIdValue = el.data.elemProps.find((prop) => prop.name === 'projectId')?.value.toString() || '';

            if (payload.projectsIds.includes(projectIdValue)) {
                const diff = el.data.diff
                    ? { ...el.data.diff }
                    : { connectedProject: false, isErrorGettingProject: false };
                return { ...el, data: { ...el.data, diff: { ...diff, isErrorGettingProject: true } } };
            }
            return el;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements },
            },
        };
    },
    getProjectsForProjectBlocksRequest: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectsForProjectsBlock: {
                    ...state.schema.getProjectsForProjectsBlock,
                    status: Statuses.LOADING,
                },
            },
        };
    },
    getProjectsForProjectBlocksSuccess: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectsForProjectsBlock: {
                    ...state.schema.getProjectsForProjectsBlock,
                    status: Statuses.SUCCEEDED,
                },
            },
        };
    },
};

export const setSubmodelItems =
    (payload: { projectId: number }) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        dispatch(actions.getSubmodelItemsRequest());
        try {
            const response = await ProjectsService.loadProject(payload.projectId);
            const schemaData = response.data.data;

            const state = getState().workspace;
            const elementId = state.schema.selectedItems.elements[0]?.id || state.meta.elementId;

            if (elementId && schemaData) {
                dispatch(actions.getSubmodelItemsSuccess({ elementId, schemaData }));
            }
        } catch (error) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.getSubmodelItemsFailed({ error: errorKey }));

            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

export const getProjectBlock =
    (payload: { projectId: number }) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        dispatch(actions.getProjectBlockRequest());
        try {
            const response = await ProjectsService.loadProject(payload.projectId);
            const projectInfo = response.data;
            dispatch(actions.getProjectBlockSuccess(projectInfo));
        } catch (error) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.getProjectBlockFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

const prepareProjectBlock = (
    block: TSchemaNode,
    // meta:
    parameters: {
        data: {
            proxyMap: TProxyMap | null;
            externalInfo: { parameters: ElemParams[]; properties: ElemProps[] } | null;
            modules: IModulesCounter;
        };
        ports: TSchemaHandle[];
        height: number;
        hash: string;
    }
) => {
    const {
        data: { proxyMap, externalInfo, modules },
        ports,
        height,
        hash,
    } = parameters;

    const elemParams = externalInfo ? externalInfo.parameters : [];
    const elemProps = externalInfo ? externalInfo.properties : [];

    const updateBlock = (block: TSchemaNode) => {
        const blockData = {
            ...block,
            height,
            data: {
                ...block.data,
                elemParams,
                elemProps: [...block.data.elemProps.filter((pr: ElemProps) => pr.isStatic), ...elemProps],
                availablePorts: ports,
                hash,
                modules,
            },
        };
        if (proxyMap) {
            return { ...blockData, data: { ...blockData.data, proxyMap } };
        }
        return blockData;
    };

    return updateBlock(block);
};

export const addProjectBlock =
    (payload: { node: TSchemaNode; elemProps: { [key: string]: string } }) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        if (!Object.keys(payload.elemProps).length) {
            return;
        }

        const projectId = payload.elemProps['projectId'] ?? null;
        let projectBlock = payload.node;

        const keys = Object.keys(payload.elemProps);
        const newProps = projectBlock.data.elemProps.map((option: ElemProps) => {
            for (let i = 0; i <= keys.length; i++) {
                if (option.name === keys[i]) {
                    return {
                        ...option,
                        value: payload.elemProps[keys[i]].toString(),
                    };
                }
            }
            return option;
        });

        projectBlock = {
            ...projectBlock,
            data: {
                ...projectBlock.data,
                elemProps: newProps,
            },
        };

        // TODO check get project by 403 code and display message about modules
        return dispatch(getProjectBlock({ projectId: +projectId }))
            .then(() => {
                const state = getState().workspace;
                const project = state.schema.getProjectBlock.project;

                const hash = project.header.hash || '';
                const libraryPortTypes = state.libraryPortTypes.items;
                const parameters = project.data.externalInfo?.parameters;

                const ports = parameters
                    ? setPortsByParameters(parameters, project.data.elements, project.data.wires, libraryPortTypes)
                    : [];
                const maxPortsLength = calculatePortsLength(countMaxOfInputsAndOutputs(ports), CANVAS.PORT_MARGIN);
                const newHeight = Math.max(maxPortsLength, CANVAS.ELEMENT_MIN_HEIGHT);
                if (!project.data) {
                    return;
                }

                projectBlock = prepareProjectBlock(projectBlock, {
                    data: project.data,
                    ports,
                    height: newHeight,
                    hash,
                });

                return dispatch(actions.addNode(projectBlock));
            })
            .catch((e) => {
                throw e;
            });
    };

export const updateProjectBlocks =
    (payload: { projectsIds: string[]; projectBlocksIds: string[] }) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        dispatch(actions.getProjectsForProjectBlocksRequest());
        try {
            const blocks = getState().workspace.schema.schemaItems.elements;
            const groupBlocks =
                getState()
                    .workspace.schema.schemaItems.groups?.map((group: TSchemaGroup) => group.elements)
                    .flat() || [];
            const appModules = getState().workspace.modules;

            const ids = Array.from(new Set(payload.projectsIds));

            const projectBlocks = [...blocks, ...groupBlocks]
                .filter((block: TSchemaNode) => block.data.type === 'project')
                .filter((block: TSchemaNode) => {
                    const projectProperty = block.data.elemProps.find(
                        (property: ElemProps) => property.name === 'projectId'
                    ) as ElemProps;
                    const projectId = projectProperty.value as string;
                    return ids.includes(projectId);
                });
            const projectBlockByIdMap = new Map<string, TSchemaNode>();
            projectBlocks.forEach((block: TSchemaNode) => {
                projectBlockByIdMap.set(block.id, block);
            });

            const response = await ProjectsService.loadProjects(ids);

            const subProjects = (!Array.isArray(response.data) ? [response.data] : response.data) as (IProjectInfo & {
                projectId: string;
            })[];
            const subProjectsIds = subProjects.map((project) => project.projectId.toString());
            const deletedProjects = ids.filter((id) => !subProjectsIds.includes(id));
            const libraryItems = getState().workspace.libraryItems.items;
            const libraryPortTypes = getState().workspace.libraryPortTypes.items;

            if (deletedProjects.length !== 0) {
                const errorKey = TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_FOR_PROJECT_BLOCKS;
                dispatch(actions.updateProjectBlockFailed({ error: errorKey, projectsIds: deletedProjects }));
            }

            let changeActionModules: IModulesCounter = {};
            subProjects.forEach((projectInfo, index) => {
                const data = projectInfo.data;
                const elementId = payload.projectBlocksIds[index];
                const hash = projectInfo.header.hash || '';
                const parameters = data.externalInfo?.parameters;
                let updatedSchema = { elements: data.elements, wires: data.wires };
                const version = projectInfo.header.version;

                if (version && lte(version, '2.5.1')) {
                    updatedSchema = updateSchema_patch_2_5_1(
                        { elements: data.elements, wires: data.wires, groups: data.groups || [] },
                        libraryItems,
                        libraryPortTypes
                    );
                }

                const ports = parameters
                    ? setPortsByParameters(parameters, updatedSchema.elements, updatedSchema.wires, libraryPortTypes)
                    : [];
                const maxPortsLength = calculatePortsLength(countMaxOfInputsAndOutputs(ports), CANVAS.PORT_MARGIN);
                const newHeight = Math.max(maxPortsLength, CANVAS.ELEMENT_MIN_HEIGHT);
                if (elementId && data) {
                    dispatch(
                        actions.updateProjectBlockSuccess({
                            elementId,
                            data,
                            ports,
                            height: newHeight,
                            hash,
                            modules: projectInfo.data.modules,
                        })
                    );

                    const previousBlock = projectBlockByIdMap.get(elementId) as TSchemaNode;
                    changeActionModules = {
                        ...changeActionModules,
                        ...getModulesDiff(
                            previousBlock.data.modules as IModulesCounter,
                            data.modules as IModulesCounter
                        ),
                    };
                }
            });

            // Calculate and update modules blocks counter
            const { updatedModules, newlyActivatedModules, deactivatedModules } = getActionModulesResult(
                changeActionModules,
                appModules
            );
            dispatch(workspaceActions.updateModules(updatedModules));

            if (newlyActivatedModules.length > 0) {
                dispatch(workspaceActions.sendMessageAboutNewlyPermissions(newlyActivatedModules));
            }
            if (deactivatedModules.length > 0) {
                dispatch(workspaceActions.sendMessageAboutDeactivatedPermissions(deactivatedModules));
            }
        } catch (error) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
            console.error(error);
        }
        dispatch(actions.getProjectsForProjectBlocksSuccess());
    };
