import { FC, memo } from 'react';

import { EFileManagerItemType, IFileManagerPreview } from './FileManagerTypes';
import { SFileManagerPreview, SFileManagerPreviewHeader, SFileManagerPreviewList } from './SFileManager';

import { LowAccent } from '../Accent/Accent';
import { AuthImage } from '../AuthImage/AuthImage';
import { FormattedDate } from '../FormatedDate/FormatedDate';
import { Icon } from '../Icon/Icon';

const FileManagerSinglePreview: FC<Pick<IFileManagerPreview, 'imageLoader' | 'files' | 'selectedElements'>> = ({
    imageLoader,
    files,
    selectedElements,
}) => {
    const currentFiles = files.filter((file) => selectedElements.includes(file.uuid));

    return (
        <SFileManagerPreviewHeader>
            {imageLoader !== null &&
                !currentFiles[0].data?.image &&
                currentFiles[0].type === EFileManagerItemType.FILE && (
                    <AuthImage
                        {...imageLoader}
                        headers={{ ...imageLoader.headers, [imageLoader.requestKey]: currentFiles[0].id }}
                    />
                )}
            {currentFiles[0].type === EFileManagerItemType.DIR && <Icon name={'checkedFolder'} />}
        </SFileManagerPreviewHeader>
    );
};

export const FileManagerPreview: FC<IFileManagerPreview> = memo(
    ({ imageLoader, translations, files = [], selectedElements = [] }) => {
        const currentFiles = files.filter((file) => selectedElements.includes(file.uuid));

        return (
            <SFileManagerPreview
                data-name={'file-manager-preview'}
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
                onDragLeave={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                onDragEnter={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {currentFiles.length === 1 && (
                    <>
                        <h6>{currentFiles[0].name}</h6>
                        <FileManagerSinglePreview
                            imageLoader={imageLoader}
                            files={files}
                            selectedElements={selectedElements}
                        />
                        <SFileManagerPreviewList>
                            <li>
                                <LowAccent>
                                    {translations && translations['createdAt'] && `${translations['createdAt']}:`}&nbsp;
                                    {currentFiles[0]?.createdAt && <FormattedDate date={currentFiles[0]?.createdAt} />}
                                </LowAccent>
                            </li>
                            <li>
                                <LowAccent>
                                    {translations && translations['updatedAt'] && `${translations['updatedAt']}:`}&nbsp;
                                    {currentFiles[0]?.updatedAt && <FormattedDate date={currentFiles[0]?.updatedAt} />}
                                </LowAccent>
                            </li>
                        </SFileManagerPreviewList>
                    </>
                )}
                {currentFiles.length > 1 && (
                    <>
                        <SFileManagerPreviewHeader>
                            <Icon name={'multiFolder'} />
                        </SFileManagerPreviewHeader>
                        <SFileManagerPreviewList>
                            {currentFiles.map((file) => (
                                <li key={`preview-file-${file.uuid}`}>
                                    <Icon name={file.type === EFileManagerItemType.FILE ? 'fileNoName' : 'folder'} />
                                    {file.name}
                                </li>
                            ))}
                        </SFileManagerPreviewList>
                    </>
                )}
                {currentFiles.length === 0 && translations && (
                    <h6>
                        {translations['selected']}:&nbsp;{currentFiles.length}
                    </h6>
                )}
            </SFileManagerPreview>
        );
    }
);
