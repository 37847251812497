import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { InputDataTable } from '@components/InputDataTable/InputDataTable';
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
    createFilterOptions,
    FormControl,
    TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDebounce } from 'use-debounce';

import { getStylesByVariant, sanitizeHTML, unicodeReplaceSymbol, unicodeToHtml } from '@repeat/constants';
import { useAppDispatch, useAppSelector, useDemoMode } from '@repeat/hooks';
import { ElemPropsView, PropertyTypes, TPropertyValue, TSchemaHandle } from '@repeat/models';
import { setDynamicOptions, setUndoRedoHotkeysPressed, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { InputField, TInputVariant, Toggle, Tooltip, TooltipPosition, TooltipVariant, uiColors } from '@repeat/ui-kit';

import {
    SButtons,
    SIconButton,
    SInputs,
    SPropertyItemBlock,
    SSelectItem,
    StyledIcon,
    StyledLabel,
    StyledLabelInput,
    StyledPropertiesItem,
} from './SItemProperty';
import { TooltipContent } from './TooltipContent';

import { SAutocomplete } from '../../Visualization/Charts/AutoCompleteParametersForm/SAutocompleteParametersForm';
import { messages } from '../translation';

interface IItemProperty {
    property: ElemPropsView;
    variant?: TInputVariant;
    help?: { description: string; image: string } | null;
    elementId: number;
    elementType: string;
    availablePorts: TSchemaHandle[];
    hasManagedPorts: boolean;
    isEditable?: boolean;
    availableValues: TPropertyValue[] | null | undefined;
    onChange: (name: string | number, value: string) => void;
    setOptions: (name: string | number, value: string) => void;
    setOptionsBuffer: (name: string | number, value: string) => void;
    clearBuffer: () => void;
}

export const ItemProperty: FC<IItemProperty> = (props) => {
    const intl = useIntl();
    const {
        property: { name, description, value, editable, isStatic, type, minValue, maxValue },
        help,
        elementId,
        elementType,
        onChange,
        setOptions,
        setOptionsBuffer,
        clearBuffer,
        availablePorts,
        hasManagedPorts,
        isEditable,
        availableValues,
        ...rest
    } = props;

    const dispatch = useAppDispatch();

    const { isDemo } = useDemoMode();

    const [localValue, setLocalValue] = useState<string>(value?.toString());
    const [localPropValue, setPropValue] = useState<TPropertyValue[]>([{ title: 'Давление', value: 'pressure' }]);
    const [isLabelInput, setIsLabelInput] = useState(false);
    const [propertyDescription, setPropertyDescription] = useState(description);

    const [debouncedValue] = useDebounce(localValue, 500);

    const [hotkeyPressed, setHotkeyPressed] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const autoCompleteParameters = useAppSelector(workspaceSelectors.schemaElementsWithParameters);
    const currentNode = useAppSelector(workspaceSelectors.currentNodeParameters);
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);
    const filterStateElements = autoCompleteParameters.filter((item) => item.id === currentNode.id)[0];
    const defaultPropertyValue = useAppSelector(workspaceSelectors.currentNodeProperties)
        .elemProps.filter((itemProperty) => itemProperty.name === 'parametersVector')
        .map((item) => item.availableValues)[0]
        ?.map((item) => item)[0];
    const filterSelectedElementProperties = useAppSelector(workspaceSelectors.currentNodeProperties)
        .elemProps.filter((itemProperty) => itemProperty.name === 'parametersVector')
        .map((item) => item.value);

    const variant = useMemo(() => {
        if (value !== localValue && !hotkeyPressed && !isSaved) {
            return 'warning';
        }
        if (isSaved && !hotkeyPressed) {
            return 'success';
        }

        return 'default';
    }, [localValue, value, debouncedValue, isSaved, hotkeyPressed]);

    useEffect(() => {
        setLocalValue(value?.toString());
    }, [value]);

    useEffect(() => {
        if (localValue === debouncedValue && localValue !== value) {
            onChange(name, localValue);

            submitPropertyValue({ [name]: localValue.toString() }, elementId);
        }
    }, [localValue, debouncedValue]);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (isSaved) {
            timer = setTimeout(() => {
                setIsSaved(false);
            }, 500);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [isSaved]);

    useEffect(() => {
        if (filterStateElements && filterStateElements.elemParams.length && filterSelectedElementProperties[0] !== '') {
            const currentPropertiesArray = filterSelectedElementProperties.join().split(';');
            const propArray: TPropertyValue[] = [];
            for (let i = 0; i < currentPropertiesArray.length; i++) {
                availableValues &&
                    propArray.push(
                        availableValues.filter((item) => item.value === currentPropertiesArray[i])[0] as TPropertyValue
                    );
            }
            setPropValue(propArray);
        } else {
            setPropValue([]);
        }
    }, []);

    const submitPropertyValue = useCallback(
        (obj: { [key: string]: string }, elId: number) => {
            if (!hotkeyPressed) {
                if (
                    hasManagedPorts &&
                    elementType !== 'project' &&
                    elementType !== 'group' &&
                    elementType !== 'userBlock'
                ) {
                    dispatch(
                        setDynamicOptions({ element: { type: elementType, id: elId, availablePorts }, options: obj })
                    );
                }
                if (isEditable && elementType !== 'group') {
                    dispatch(workspaceActions.setExternalPropertyValue(obj));
                }
                if (elId) {
                    dispatch(workspaceActions.setElementPropertiesValues({ id: elId, elemProps: obj }));
                }

                setIsSaved(true);
                clearBuffer();
            }
        },
        [hotkeyPressed, hasManagedPorts, elementType, availablePorts, isEditable, dispatch, clearBuffer]
    );

    const inputChangeHandler = useCallback(
        (name: string, currentValue: string) => {
            setOptions(name, currentValue);
            if (!hotkeyPressed) {
                setOptionsBuffer(name, currentValue);
            }

            setLocalValue(currentValue);
        },
        [setOptions, setOptionsBuffer, hotkeyPressed]
    );

    const inputOnBlurHandler = useCallback(
        (currentValue: string) => {
            if (variant === 'warning') {
                submitPropertyValue({ [name]: currentValue }, elementId);
            }
        },
        [submitPropertyValue, variant, name, elementId]
    );

    const inputOnKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.key === 'z' && event.ctrlKey) || (event.key === 'z' && event.metaKey)) {
            setHotkeyPressed(true);
            dispatch(setUndoRedoHotkeysPressed({ event }));
            clearBuffer();
        }
        if (!(event.key === 'z' && event.ctrlKey) && !(event.key === 'z' && event.metaKey)) {
            setHotkeyPressed(false);
        }
    };
    const handleDeleteButtonClick = () => {
        if (elementType === 'group') {
            dispatch(workspaceActions.deleteGroupBlockProperty({ groupId: elementId.toString(), propertyName: name }));
        } else if (elementType === 'userBlock' && isUserBlockEditorMode) {
            dispatch(workspaceActions.deleteLibraryItemProperty({ propertyName: name }));
        } else {
            dispatch(workspaceActions.deleteExternalProperty(name));
        }
    };

    const handleSubmitButtonClick = () => {
        setIsLabelInput(false);

        if (elementType === 'group') {
            dispatch(
                workspaceActions.setGroupPropertyDescription({
                    groupId: elementId,
                    name,
                    description: propertyDescription,
                })
            );
        } else if (isUserBlockEditorMode && elementType === 'userBlock') {
            dispatch(workspaceActions.setLibraryItemPropertyDescription({ name, description: propertyDescription }));
        } else {
            dispatch(
                workspaceActions.setExternalPropertyDescription({
                    name,
                    description: propertyDescription,
                })
            );
        }
    };

    if (isStatic === true) {
        return null;
    }

    const optionEqualToValue = (option: TPropertyValue, value: TPropertyValue) => {
        if (option && value) {
            return option.value === value.value;
        }
        return false;
    };

    const getOptionLabel = (option: TPropertyValue) => unicodeReplaceSymbol(option?.title) || '';
    const renderInput = (label: string) => (params: AutocompleteRenderInputParams) =>
        <TextField {...params} label={label} />;

    const renderOption = (
        props: React.HTMLAttributes<HTMLElement>,
        option: TPropertyValue,
        state: AutocompleteRenderOptionState
    ) => {
        const innerHTML = `<span>${unicodeToHtml(option.title)}</span`;
        return <li {...props} key={option.title} dangerouslySetInnerHTML={{ __html: innerHTML }} />;
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: TPropertyValue) => `${option.title} ${unicodeToHtml(option.title)}`,
    });

    return (
        <StyledPropertiesItem type={type} isEditable={isEditable}>
            <SPropertyItemBlock isEditable={isEditable ? isEditable : false}>
                {type !== PropertyTypes.TABLE && (
                    <StyledLabel
                        isLabelInput={isLabelInput}
                        onDoubleClick={() => isEditable && setIsLabelInput(true)}
                        htmlFor={name}
                        {...rest}
                        dangerouslySetInnerHTML={sanitizeHTML(unicodeToHtml(description))}
                    />
                )}

                {isLabelInput && (
                    <StyledLabelInput
                        value={propertyDescription}
                        onChange={(e) => setPropertyDescription(e.target.value)}
                    />
                )}

                {help && (
                    <Tooltip
                        text={<TooltipContent help={help} />}
                        position={TooltipPosition.LEFT}
                        variant={TooltipVariant.LIGHT}
                        tooltipWidth={200}
                    >
                        <StyledIcon name='help' fill={uiColors.darkGrey} />
                    </Tooltip>
                )}

                {isEditable && (
                    <SButtons>
                        {!isLabelInput && (
                            <SIconButton
                                noHover
                                fill={uiColors.red}
                                name='close'
                                size='big'
                                onClick={handleDeleteButtonClick}
                            />
                        )}
                        {isLabelInput && (
                            <>
                                <SIconButton
                                    noHover
                                    fill={uiColors.darkGrey}
                                    name='close'
                                    size='big'
                                    onClick={() => {
                                        setIsLabelInput(false);
                                    }}
                                    isLabelInput={isLabelInput}
                                />
                                <SIconButton
                                    noHover
                                    fill={uiColors.green}
                                    name='done'
                                    size='big'
                                    onClick={handleSubmitButtonClick}
                                />
                            </>
                        )}
                    </SButtons>
                )}
            </SPropertyItemBlock>
            <SInputs isEditable={isEditable}>
                {type === PropertyTypes.SELECT && (
                    <FormControl size='small' style={{ width: '100%' }}>
                        <Select
                            sx={getStylesByVariant(variant)}
                            id={name}
                            value={localValue}
                            onChange={(e: SelectChangeEvent<unknown>) => {
                                if ((e.target.value as any)?.toString() !== localValue) {
                                    submitPropertyValue({ [name]: e.target.value as string }, elementId);
                                    setLocalValue(e.target.value as string);
                                    onChange(name, e.target.value as string);
                                    setOptions(name, e.target.value as string);
                                }
                            }}
                            disabled={!editable || isDemo}
                        >
                            {availableValues &&
                                availableValues.length > 0 &&
                                availableValues.map((propertyValue: TPropertyValue) => (
                                    <SSelectItem value={propertyValue.value} key={`select-${propertyValue.value}`}>
                                        {propertyValue.title}
                                    </SSelectItem>
                                ))}
                        </Select>
                    </FormControl>
                )}

                {type === PropertyTypes.TOGGLE && (
                    <Toggle
                        variant={variant}
                        size='small'
                        htmlType='button'
                        isChecked={localValue === 'true'}
                        onClick={(value) => {
                            inputChangeHandler(name, value.toString());
                        }}
                        disabled={!editable}
                    />
                )}

                {type === PropertyTypes.MULTISELECT && (
                    <SAutocomplete>
                        <Autocomplete
                            multiple
                            disableClearable
                            size={'small'}
                            id={name}
                            options={availableValues ? availableValues : []}
                            noOptionsText={intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND])}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, options: TPropertyValue[]) => {
                                if (options.length === 0 && defaultPropertyValue) {
                                    options.push({
                                        title: defaultPropertyValue.title,
                                        value: defaultPropertyValue.value,
                                    });
                                    setPropValue([
                                        { title: defaultPropertyValue.title, value: defaultPropertyValue.value },
                                    ]);
                                    submitPropertyValue(
                                        { [name]: options.map((item) => item.value).join(';') as string },
                                        elementId
                                    );
                                } else {
                                    setPropValue(options);
                                    submitPropertyValue(
                                        { [name]: options.map((item) => item.value).join(';') as string },
                                        elementId
                                    );
                                }
                            }}
                            isOptionEqualToValue={optionEqualToValue}
                            value={localPropValue}
                            getOptionLabel={getOptionLabel}
                            renderInput={renderInput('')}
                            renderOption={(props, option: TPropertyValue, state) => {
                                return (
                                    <li {...props} key={option.value}>
                                        {option.title}
                                    </li>
                                );
                            }}
                            filterOptions={filterOptions}
                            defaultValue={localPropValue}
                        />
                    </SAutocomplete>
                )}

                {type === PropertyTypes.NUMBER && (
                    <InputField
                        variant={variant}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputChangeHandler(name, e.target.value);
                        }}
                        onBlur={(e) => inputOnBlurHandler(e.target.value)}
                        onKeyDown={inputOnKeyDownHandler}
                        id={name}
                        type='number'
                        value={localValue}
                        size='small'
                        minValue={minValue && minValue}
                        maxValue={maxValue && maxValue}
                        disabled={!editable || isDemo}
                    />
                )}

                {!type && editable && (
                    <InputField
                        disabled={isDemo}
                        variant={variant}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputChangeHandler(name, e.target.value);
                        }}
                        onBlur={(e) => inputOnBlurHandler(e.target.value)}
                        onKeyDown={inputOnKeyDownHandler}
                        id={name}
                        type='text'
                        value={localValue}
                        size='small'
                    />
                )}

                {!type && !editable && (
                    <InputField
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(name, e.target.value)}
                        id={name}
                        type='text'
                        value={value}
                        disabled={!editable || isDemo}
                    />
                )}

                {editable && type === PropertyTypes.TABLE && (
                    <InputDataTable
                        id={name}
                        initialValues={value as string}
                        label={sanitizeHTML(unicodeToHtml(description))}
                        disabled={!editable || isDemo}
                        onChange={(name: string, value: string) => {
                            inputChangeHandler(name, value);
                        }}
                    />
                )}
            </SInputs>
        </StyledPropertiesItem>
    );
};
