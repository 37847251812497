import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.WORKSPACE_PROJECT_TITLE]: {
        id: TranslationKey.WORKSPACE_PROJECT_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_TITLE],
    },
    [TranslationKey.WORKSPACE_ELEMENT_TITLE]: {
        id: TranslationKey.WORKSPACE_ELEMENT_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_ELEMENT_TITLE],
    },
    [TranslationKey.WORKSPACE_CONNECTION_TITLE]: {
        id: TranslationKey.WORKSPACE_CONNECTION_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_CONNECTION_TITLE],
    },
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
    [TranslationKey.WORKSPACE_OBJECTS_TITLE]: {
        id: TranslationKey.WORKSPACE_OBJECTS_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_OBJECTS_TITLE],
    },
    [TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA]: {
        id: TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA,
        defaultMessage: ru[TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA],
    },
    [TranslationKey.PROJECTS_COLUMN_CREATED_AT]: {
        id: TranslationKey.PROJECTS_COLUMN_CREATED_AT,
        defaultMessage: ru[TranslationKey.PROJECTS_COLUMN_CREATED_AT],
    },
    [TranslationKey.PROJECTS_COLUMN_UPDATED_AT]: {
        id: TranslationKey.PROJECTS_COLUMN_UPDATED_AT,
        defaultMessage: ru[TranslationKey.PROJECTS_COLUMN_UPDATED_AT],
    },
    [TranslationKey.WORKSPACE_ADD_PROPERTY]: {
        id: TranslationKey.WORKSPACE_ADD_PROPERTY,
        defaultMessage: ru[TranslationKey.WORKSPACE_ADD_PROPERTY],
    },
    [TranslationKey.HIDE]: {
        id: TranslationKey.HIDE,
        defaultMessage: ru[TranslationKey.HIDE],
    },
    [TranslationKey.SETTINGS]: {
        id: TranslationKey.SETTINGS,
        defaultMessage: ru[TranslationKey.SETTINGS],
    },
    [TranslationKey.ELEMENT]: {
        id: TranslationKey.ELEMENT,
        defaultMessage: ru[TranslationKey.ELEMENT],
    },
    [TranslationKey.WORKSPACE_BLOCK_TITLE]: {
        id: TranslationKey.WORKSPACE_BLOCK_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_BLOCK_TITLE],
    },
    [TranslationKey.ONLY_DIGITS_ERROR_MSG]: {
        id: TranslationKey.ONLY_DIGITS_ERROR_MSG,
        defaultMessage: ru[TranslationKey.ONLY_DIGITS_ERROR_MSG],
    },
});
