import { ElemParams, IWatchingParameterOption, TSchemaGroup, TSchemaNode } from '@repeat/models';

export const extractParametersForVisualization = (elemParams: ElemParams[], parametersToDisplay: string[] | null) => {
    if (parametersToDisplay === null || parametersToDisplay === undefined) {
        return elemParams;
    } else if (parametersToDisplay && parametersToDisplay?.length !== 0) {
        return parametersToDisplay.map((str) => elemParams.find((param) => param.name.includes(str))) as ElemParams[];
    } else if (parametersToDisplay && parametersToDisplay.length === 0) {
        return [];
    } else {
        return elemParams;
    }
};

const findElementGroup = (groups: TSchemaGroup[], elementId: string): TSchemaGroup | null => {
    let group: TSchemaGroup | null = null;
    groups.forEach((g) => {
        const groupElementsIds = g.elements.map((el) => el.id);
        if (groupElementsIds.includes(elementId)) {
            group = g;
        }
    });
    return group;
};

export const getElementsOptions = (elements: TSchemaNode[], groups: TSchemaGroup[]): IWatchingParameterOption[] => {
    return elements
        .map((element) => {
            const elementParameters = extractParametersForVisualization(
                element.data.elemParams,
                element.data.parametersToDisplay
            );
            const elementGroup = findElementGroup(groups, element.id);
            return elementParameters
                .filter((parameter) => {
                    // parameter.name.indexOf('in_') === -1; // fix error
                    const exp = new RegExp('in_');
                    if (parameter && parameter.name) {
                        return (
                            !exp.test(parameter.name) &&
                            (!Object.prototype.hasOwnProperty.call(parameter, 'isVisible') ||
                                parameter.isVisible === true)
                        );
                    }
                    return;
                }) // исключаем входные порты
                .map((parameter) => {
                    const parameterTitle = parameter?.unit
                        ? `${parameter?.description}, ${parameter?.unit}`
                        : `${parameter?.description}`;
                    const parameterKey = elementGroup
                        ? `${elementGroup.id}.${element.data.id}_${parameter.name}`
                        : `${element.data.id}_${parameter.name}`;
                    const elementTitle =
                        element.data.type === 'group'
                            ? `${element.data.name}`
                            : `${element.data.name} [${element.data.index}]`;
                    const elementTitleWithGroup = elementGroup
                        ? `${elementGroup.name} / ${elementTitle}`
                        : elementTitle;

                    const title = `${element.data.name} [${element.data.index}] / ${parameterTitle}`;
                    const titleWithGroup = elementGroup ? `${elementGroup.name} / ${title}` : title;

                    return {
                        key: parameterKey,
                        title: titleWithGroup,
                        elementTitle: elementTitleWithGroup,
                        parameterTitle: parameterTitle,
                    };
                });
        })
        .flat();
};

export const compileParameterOptions = (
    nodes: TSchemaNode[],
    selectedNodes: TSchemaNode[],
    schemaGroups: TSchemaGroup[],
    workspaceMetaGroupId: string | null
): IWatchingParameterOption[] => {
    const selectedGroupElementsIds: string[] = [];
    const selectedNodesIds = selectedNodes.map((n) => {
        if (n.data.type === 'group') {
            selectedGroupElementsIds.push(n.id);
        }
        return n.id;
    });

    const filteredGroups = schemaGroups.filter((group) =>
        [...selectedGroupElementsIds, workspaceMetaGroupId].includes(group.id.toString())
    );

    const groupsElements = filteredGroups.map((group) => group.elements).flat();

    const filteredNodes = selectedNodesIds.length > 0 ? nodes.filter((n) => selectedNodesIds.includes(n.id)) : nodes;

    return getElementsOptions(
        workspaceMetaGroupId ? groupsElements : [...filteredNodes, ...groupsElements],
        filteredGroups
    );
};
