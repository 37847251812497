import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { sanitizeFilename } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { Statuses } from '@repeat/models';
import { generateNoNameFile, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, LoaderDefault, Tooltip, uiColors } from '@repeat/ui-kit';

import { AbilityContext } from './../../../context/Can';
import { messages } from './translation';

const DOWNLOAD_LOADER_ICON_SIZE = 26;

export const MenuGetNoNameItem: FC<{ projectId: number; projectName: string }> = ({ projectId, projectName }) => {
    const { formatMessage } = useIntl();

    const dispatch = useAppDispatch();

    const ability = useContext(AbilityContext);
    const canUse = ability.can('manage', 'all');

    const isDownloading = useAppSelector<boolean>(
        (state) => state.workspace.modelControl.generateNoNameFile.status === Statuses.LOADING
    );

    const [abortController, setAbortController] = useState<AbortController | null>(null);

    useEffect(() => {
        if (abortController && abortController.signal.aborted) {
            setAbortController(new AbortController());
        }

        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [abortController, isDownloading]);

    const handleDownload = (localUrl: string, fileExtension: string) => {
        const anchor: HTMLAnchorElement = document.createElement('a');
        anchor.href = localUrl;
        anchor.download = `${sanitizeFilename(projectName)}.${fileExtension}`;

        document.body.appendChild(anchor);
        anchor.click();

        URL.revokeObjectURL(localUrl);
    };

    const handleGenerateNoNameFile = () => {
        const controller = new AbortController();

        setAbortController(controller);

        dispatch(generateNoNameFile({ projectId, onDownload: handleDownload, abortController: controller }));
    };
    const handleCancelGenerateNoNameFile = () => {
        if (abortController) {
            abortController.abort();
        }
        dispatch(workspaceActions.generateNoNameFileCancel());
    };

    const getTooltipMessage = useCallback(() => {
        if (isDownloading) {
            return formatMessage(messages[TranslationKey.WORKSPACE_GET_NONAME_FILE_CANCEL]);
        }

        return formatMessage(messages[TranslationKey.WORKSPACE_GET_NONAME_FILE]);
    }, [isDownloading]);

    if (!canUse) {
        return null;
    }

    return (
        <Tooltip text={getTooltipMessage()}>
            {isDownloading && (
                <LoaderDefault
                    color={uiColors.black}
                    width={DOWNLOAD_LOADER_ICON_SIZE}
                    height={DOWNLOAD_LOADER_ICON_SIZE}
                    onClick={handleCancelGenerateNoNameFile}
                />
            )}
            {!isDownloading && (
                <IconButton
                    fill={uiColors.darkGrey}
                    noHover
                    name='fileNoName'
                    onClick={() => handleGenerateNoNameFile()}
                />
            )}
        </Tooltip>
    );
};
