import React, { FC } from 'react';

import styled from 'styled-components';

import { pxToRem } from '../../config/config';

export const SShowMoreTitle = styled.h5`
    font-weight: 700;
    font-size: ${pxToRem(14)};
    margin: ${pxToRem(8)} 0;
    padding: 0;
`;

export const SShowMoreContent = styled.div``;

export const SShowMore = styled.div`
    margin: 0 0 ${pxToRem(16)};
    padding: 0 ${pxToRem(16)};

    label {
        font-size: ${pxToRem(12)};
    }
`;

export interface IShowMore {
    title?: string;
    children: React.ReactNode;
}

export const ShowMore: FC<IShowMore> = ({ title, children }) => {
    return (
        <SShowMore>
            {title && <SShowMoreTitle>{title}</SShowMoreTitle>}
            <SShowMoreContent>{children}</SShowMoreContent>
        </SShowMore>
    );
};
