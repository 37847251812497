import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.SUBLIBRARY_ROTATIONAL_MOTION]: {
        id: TranslationKey.SUBLIBRARY_ROTATIONAL_MOTION,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_ROTATIONAL_MOTION],
    },
    [TranslationKey.SUBLIBRARY_TRANSLATIONAL_MOTION]: {
        id: TranslationKey.SUBLIBRARY_TRANSLATIONAL_MOTION,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_TRANSLATIONAL_MOTION],
    },
    [TranslationKey.LIBRARY_NEW_BLOCK]: {
        id: TranslationKey.LIBRARY_NEW_BLOCK,
        defaultMessage: ru[TranslationKey.LIBRARY_NEW_BLOCK],
    },
    [TranslationKey.SUBLIBRARY_BASE_ELEMENTS]: {
        id: TranslationKey.SUBLIBRARY_BASE_ELEMENTS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_BASE_ELEMENTS],
    },
    [TranslationKey.SUBLIBRARY_CONVERTERS]: {
        id: TranslationKey.SUBLIBRARY_CONVERTERS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_CONVERTERS],
    },
    [TranslationKey.SUBLIBRARY_ELECTRIC_MACHINES]: {
        id: TranslationKey.SUBLIBRARY_ELECTRIC_MACHINES,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_ELECTRIC_MACHINES],
    },
    [TranslationKey.SUBLIBRARY_SUPPLIERS]: {
        id: TranslationKey.SUBLIBRARY_SUPPLIERS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_SUPPLIERS],
    },
    [TranslationKey.SUBLIBRARY_FUNCTIONS]: {
        id: TranslationKey.SUBLIBRARY_FUNCTIONS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_FUNCTIONS],
    },
    [TranslationKey.SUBLIBRARY_OPERATIONS]: {
        id: TranslationKey.SUBLIBRARY_OPERATIONS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_OPERATIONS],
    },
    [TranslationKey.SUBLIBRARY_LOGIC]: {
        id: TranslationKey.SUBLIBRARY_LOGIC,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_LOGIC],
    },
    [TranslationKey.SUBLIBRARY_SWITCH]: {
        id: TranslationKey.SUBLIBRARY_SWITCH,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_SWITCH],
    },
    [TranslationKey.SUBLIBRARY_TRANSFERFUNCTIONS]: {
        id: TranslationKey.SUBLIBRARY_TRANSFERFUNCTIONS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_TRANSFERFUNCTIONS],
    },
    [TranslationKey.SUBLIBRARY_CONTROLLERS]: {
        id: TranslationKey.SUBLIBRARY_CONTROLLERS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_CONTROLLERS],
    },
    [TranslationKey.SUBLIBRARY_DELAYS]: {
        id: TranslationKey.SUBLIBRARY_DELAYS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_DELAYS],
    },
    [TranslationKey.SUBLIBRARY_RELAY]: {
        id: TranslationKey.SUBLIBRARY_RELAY,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_RELAY],
    },
    [TranslationKey.SUBLIBRARY_NONLINEAR]: {
        id: TranslationKey.SUBLIBRARY_NONLINEAR,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_NONLINEAR],
    },
    [TranslationKey.SUBLIBRARY_SIGNAL_PROCESSING]: {
        id: TranslationKey.SUBLIBRARY_SIGNAL_PROCESSING,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_SIGNAL_PROCESSING],
    },
    [TranslationKey.SUBLIBRARY_STRUCTURAL_AND_SUPPORT]: {
        id: TranslationKey.SUBLIBRARY_STRUCTURAL_AND_SUPPORT,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_STRUCTURAL_AND_SUPPORT],
    },
    [TranslationKey.SUBLIBRARY_LOADS]: {
        id: TranslationKey.SUBLIBRARY_LOADS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_LOADS],
    },
    [TranslationKey.SUBLIBRARY_TRANSFORMERS]: {
        id: TranslationKey.SUBLIBRARY_TRANSFORMERS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_TRANSFORMERS],
    },
    [TranslationKey.SUBLIBRARY_SOURCES]: {
        id: TranslationKey.SUBLIBRARY_SOURCES,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_SOURCES],
    },
    [TranslationKey.SUBLIBRARY_POWER_CONVERTERS]: {
        id: TranslationKey.SUBLIBRARY_POWER_CONVERTERS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_POWER_CONVERTERS],
    },
    [TranslationKey.SUBLIBRARY_LONGITUDINAL_ELEMENTS]: {
        id: TranslationKey.SUBLIBRARY_LONGITUDINAL_ELEMENTS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_LONGITUDINAL_ELEMENTS],
    },
    [TranslationKey.SUBLIBRARY_ELECTRONIC_COMPONENTS_AND_POWER_CONVERTERS]: {
        id: TranslationKey.SUBLIBRARY_ELECTRONIC_COMPONENTS_AND_POWER_CONVERTERS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_ELECTRONIC_COMPONENTS_AND_POWER_CONVERTERS],
    },
    [TranslationKey.SUBLIBRARY_PASSIVE_ELEMENTS]: {
        id: TranslationKey.SUBLIBRARY_PASSIVE_ELEMENTS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_PASSIVE_ELEMENTS],
    },
    [TranslationKey.SUBLIBRARY_BASIC_COMPONENTS]: {
        id: TranslationKey.SUBLIBRARY_BASIC_COMPONENTS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_BASIC_COMPONENTS],
    },
    [TranslationKey.SUBLIBRARY_MEASURING_ELEMENTS]: {
        id: TranslationKey.SUBLIBRARY_MEASURING_ELEMENTS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_MEASURING_ELEMENTS],
    },
    [TranslationKey.SUBLIBRARY_CMS]: {
        id: TranslationKey.SUBLIBRARY_CMS,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_CMS],
    },
    [TranslationKey.SUBLIBRARY_TRANSPORT]: {
        id: TranslationKey.SUBLIBRARY_TRANSPORT,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_TRANSPORT],
    },
    [TranslationKey.SUBLIBRARY_RF]: {
        id: TranslationKey.SUBLIBRARY_RF,
        defaultMessage: ru[TranslationKey.SUBLIBRARY_RF],
    },
});
