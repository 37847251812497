import { PayloadAction } from '@reduxjs/toolkit';

import { ApplicationActions } from '@repeat/common-slices';
import { IWorkspaceState, NotificationTypes, TState, TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { loadProject } from './schema/schemaSlice';
import { loadUserBlock } from './userBlocks/userBlocksSlice';

import { actions, initialState, stopProject } from '.';

import { AppDispatch, RootStateFn } from '../../store';

export const metaReducers = {
    initializeWorkspaceRequest: (state: IWorkspaceState) => ({
        ...state,
        meta: {
            ...state.meta,
            isInitialized: false,
            isLoading: true,
            mode: WorkspaceModes.MAIN,
            elementId: null,
        },
    }),
    initializeWorkspaceSuccess: (state: IWorkspaceState) => ({
        ...state,
        meta: {
            ...state.meta,
            isInitialized: true,
            isLoading: false,
        },
    }),
    initializeWorkspaceFailed: (state: IWorkspaceState) => ({
        ...state,
        meta: {
            ...state.meta,
            isInitialized: true,
            isLoading: false,
        },
    }),
    resetWorkspace: () => ({
        ...initialState,
    }),
    changeWorkspaceMode: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            mode: TWorkspaceMode;
            elementId?: string | null;
            groupId?: string | null;
            userBlockId?: string | null;
        }>
    ) => {
        const { mode, elementId, groupId, userBlockId } = payload;
        const meta = {
            ...state.meta,
            mode,
            elementId: elementId || null,
            groupId: groupId || groupId === null ? groupId : state.meta.groupId ||null,
            userBlockId:
                userBlockId || userBlockId === null ? userBlockId : state.meta.userBlockId || userBlockId || null,
        };
        if (payload.mode === WorkspaceModes.MAIN) {
            return {
                ...state,
                meta: { ...meta, groupId: null },
            };
        }
        return {
            ...state,
            meta,
        };
    },
    setIsUserBlockEditor: (state: IWorkspaceState) => {
        const meta = {
            ...state.meta,
            isUserBlockEditor: true,
        };
        return {
            ...state,
            meta,
        };
    },
};

export const initializeWorkspace =
    (isLibraryFromDB?: boolean) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        const state = getState() as TState;
        const isLoading = state.workspace.meta.isLoading;
        if (isLoading) {
            return;
        }

        dispatch(actions.initializeWorkspaceRequest());

        try {
            const projectIdString = localStorage.getItem('projectId');
            const userBlockId = localStorage.getItem('userBlockId'); // режим редактирования блока
            const isUserBlock = localStorage.getItem('isUserBlock'); // true - только в режиме просмотра блока
            if (userBlockId) {
                await dispatch(loadUserBlock(userBlockId));
                dispatch(actions.initializeWorkspaceSuccess());
            }
            if (isUserBlock) {
                localStorage.removeItem('isUserBlock');
                localStorage.removeItem('demoProject');
            }
            const projectId = projectIdString ? parseInt(projectIdString) : null;
            if (!projectId) {
                return;
            }

            await dispatch(stopProject());
            dispatch(workspaceActions.setModelTime(initialState.modelControl.modelTime));
            await dispatch(loadProject(projectId, isLibraryFromDB));

            dispatch(actions.initializeWorkspaceSuccess());
        } catch (error) {
            dispatch(actions.initializeWorkspaceFailed());
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_UNKNOWN,
                    },
                })
            );
        }
    };

export const uninitializeWorkspace = () => async (dispatch: AppDispatch) => {
    dispatch(workspaceActions.resetWorkspace());
};
