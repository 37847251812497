import styled from 'styled-components';

import { pxToRem, uiColors, zIndex } from '@repeat/ui-kit';

interface ISElementProps {
    hasNewBadge: boolean;
}

export const SElement = styled.div<ISElementProps>`
    cursor: grab;
    display: inline-flex;
    max-height: ${pxToRem(60)};
    max-width: ${pxToRem(60)};
    position: relative;
    margin-top: ${pxToRem(8)};

    img {
        display: block;
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
    }

    &:last-child {
        margin-bottom: ${pxToRem(32)};
    }

    ${({ hasNewBadge }) =>
        hasNewBadge &&
        `
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -${pxToRem(4)};
            left: 0;
            z-index: ${zIndex.top};
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: ${uiColors.accentGreen};
        }
    `}
`;

export const SElementText = styled.span`
    color: var(--ui-text);
    bottom: -${pxToRem(18)};
    font-size: ${pxToRem(12)};
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 100%;
`;

export const SElementsWrapper = styled.div`
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    gap: ${pxToRem(32)} ${pxToRem(24)};
    padding: 0 0 0 ${pxToRem(16)};
    height: auto;
    transition: height 0.3s ease;
    overflow-x: hidden;
`;
