import { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Moveable, { OnResize } from 'react-moveable';
import { useUpdateNodeInternals } from 'reactflow';

import { CANVAS } from '@repeat/constants';
import { useAppDispatch, useAppSelector, useDemoMode } from '@repeat/hooks';
import { ElemProps, TElement, TSchemaNode, WorkspaceModes } from '@repeat/models';
import { elementsSelectors, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey, ru } from '@repeat/translations';

import { useInitNodeViewData } from '../../hooks/useInitNodeViewData';
import { SBlockWrapper, STextNodeWrapper } from '../SComponents';

const messages = defineMessages({
    [TranslationKey.ELEMENT_TEXT_BLOCK_TYPING]: {
        id: TranslationKey.ELEMENT_TEXT_BLOCK_TYPING,
        defaultMessage: ru[TranslationKey.ELEMENT_TEXT_BLOCK_TYPING],
    },
});

export const TextElement: FC<{ id: string; data: TElement; selected: boolean }> = memo(({ id, data, selected }) => {
    const currentNodeMain = useAppSelector(elementsSelectors.getElementById(id)) as TSchemaNode;
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const { isDemo } = useDemoMode();

    const currentNodeSubmodel = useAppSelector(workspaceSelectors.getSubmodelElementById(id)) as TSchemaNode;
    const currentNode =
        workspaceMode === WorkspaceModes.SUBMODEL || workspaceMode === WorkspaceModes.GROUP
            ? currentNodeSubmodel
            : currentNodeMain;

    const [changedValues, setChangedValues] = useState<{
        width: number;
        height: number;
        rotated: boolean;
        rotation: number;
    }>({
        width: currentNode?.width ? currentNode.width : 0,
        height: currentNode?.height ? currentNode.height : 0,
        rotated: currentNode?.rotation ? !!currentNode.rotation : false,
        rotation: currentNode?.rotation ? currentNode.rotation : 0,
    });

    const { isInitialized } = useInitNodeViewData(currentNode, changedValues);
    const [isEditDisabled, setIsEditDisabled] = useState(true);

    const initialValue = (data.elemProps.find((p) => p.name === 'text')?.value as string) || '';
    const [value, setValue] = useState<string>(initialValue);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const valueRef = useRef('');
    const updateNodeInternals = useUpdateNodeInternals();
    const textElementRef = useRef<HTMLDivElement | null>(null);
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const [size, setSize] = useState({
        width: currentNode?.width ? currentNode.width : CANVAS.GRID_STEP * 10,
        height: currentNode?.height ? currentNode.height : CANVAS.GRID_STEP * 4,
    });
    const onChangeHandle = (event: ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        valueRef.current = target.value;
        setValue(target.value);
        setIsDirty(target.value !== value);
    };

    const save = (v: string) => {
        let valueToSave = '';
        const propertyValue = data.elemProps.find((prop) => prop.name === 'text')?.value;
        if (v.length === 0 && propertyValue && propertyValue?.toString().length !== 0) {
            valueToSave = propertyValue.toString();
        } else {
            valueToSave = v;
        }
        const propertiesValues: { [key: string]: string } = {};
        data.elemProps.forEach((property: ElemProps) => {
            if (property.name === 'text') {
                propertiesValues[property.name] = valueToSave;
            }
        });
        if (propertyValue !== valueToSave) {
            dispatch(workspaceActions.setElementPropertiesValues({ id: data.id, elemProps: propertiesValues }));

            setIsDirty(false);
        }
    };

    const onEditHandle = async () => setIsEditDisabled(false);

    useEffect(() => {
        if (isInitialized && id) {
            updateNodeInternals(id);
        }
    }, [isInitialized]);

    useEffect(() => {
        if (!selected) {
            setIsEditDisabled(true);

            if (isDirty) {
                save(value);
            }
        }

        const debounceDelay = setTimeout(() => {
            if (selected && isDirty) {
                save(value);
            }
        }, 500);

        return () => clearTimeout(debounceDelay);
    }, [selected, value, isDirty]);

    useEffect(() => {
        if (!isEditDisabled) {
            textElementRef.current !== null && textElementRef.current.classList.add('nodrag');
        } else {
            textElementRef.current !== null && textElementRef.current.classList.remove('nodrag');
        }
    }, [isEditDisabled]);

    if (!isInitialized || !currentNode) {
        return null;
    }

    return (
        <STextNodeWrapper ref={textElementRef} width={size.width} height={size.height} isOutlined={selected}>
            <SBlockWrapper
                isEditMode={!isEditDisabled}
                onDoubleClick={() =>
                    workspaceMode !== WorkspaceModes.SUBMODEL &&
                    !isDemo &&
                    onEditHandle().then(() => {
                        const field = textAreaRef.current as HTMLTextAreaElement;
                        field.focus();
                    })
                }
            >
                <textarea
                    autoFocus={!isEditDisabled}
                    ref={textAreaRef}
                    disabled={isEditDisabled}
                    value={value}
                    onChange={onChangeHandle}
                    placeholder={formatMessage(messages[TranslationKey.ELEMENT_TEXT_BLOCK_TYPING])}
                />
            </SBlockWrapper>
            {!isDemo && selected && workspaceMode !== WorkspaceModes.SUBMODEL && (
                <Moveable
                    edge={true}
                    throttleResize={CANVAS.GRID_STEP}
                    target={textElementRef}
                    resizable={true}
                    onResize={({ target, width, height, delta }: OnResize) => {
                        if (width >= CANVAS.GRID_STEP * 10) {
                            delta[0] && (target!.style.width = `${width}px`);
                        }
                        if (height >= CANVAS.GRID_STEP * 4) {
                            delta[1] && (target!.style.height = `${height}px`);
                        }
                    }}
                    onResizeEnd={(event) => {
                        if (event && event.lastEvent) {
                            const size = { width: event.lastEvent.width, height: event.lastEvent.height };
                            dispatch(workspaceActions.changeElementSize({ id: data.id, size }));
                        }
                    }}
                />
            )}
        </STextNodeWrapper>
    );
});
