import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const projectMessages = defineMessages({
    [TranslationKey.PROJECTS_FILTER_BY_TITLE]: {
        id: TranslationKey.PROJECTS_FILTER_BY_TITLE,
        defaultMessage: ru[TranslationKey.PROJECTS_FILTER_BY_TITLE],
    },
    [TranslationKey.PROJECTS_COLUMN_TITLE]: {
        id: TranslationKey.PROJECTS_COLUMN_TITLE,
        defaultMessage: ru[TranslationKey.PROJECTS_COLUMN_TITLE],
    },
    [TranslationKey.PROJECTS_COLUMN_CREATED_AT]: {
        id: TranslationKey.PROJECTS_COLUMN_CREATED_AT,
        defaultMessage: ru[TranslationKey.PROJECTS_COLUMN_CREATED_AT],
    },
    [TranslationKey.PROJECTS_COLUMN_UPDATED_AT]: {
        id: TranslationKey.PROJECTS_COLUMN_UPDATED_AT,
        defaultMessage: ru[TranslationKey.PROJECTS_COLUMN_UPDATED_AT],
    },
    [TranslationKey.PROJECT_DELETE_DROPDOWN]: {
        id: TranslationKey.PROJECT_DELETE_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_DELETE_DROPDOWN],
    },
    [TranslationKey.PROJECT_EDIT_DROPDOWN]: {
        id: TranslationKey.PROJECT_EDIT_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_EDIT_DROPDOWN],
    },
    [TranslationKey.PROJECT_EXPORT_DROPDOWN]: {
        id: TranslationKey.PROJECT_EXPORT_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_EXPORT_DROPDOWN],
    },
    [TranslationKey.PROJECT_DUPLICATE_DROPDOWN]: {
        id: TranslationKey.PROJECT_DUPLICATE_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_DUPLICATE_DROPDOWN],
    },
    [TranslationKey.PROJECT_COPY_ID_DROPDOWN]: {
        id: TranslationKey.PROJECT_COPY_ID_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_COPY_ID_DROPDOWN],
    },
    [TranslationKey.PROJECT_NAME]: {
        id: TranslationKey.PROJECT_NAME,
        defaultMessage: ru[TranslationKey.PROJECT_NAME],
    },
    [TranslationKey.OBLIGATORY_FIELD_MSG]: {
        id: TranslationKey.OBLIGATORY_FIELD_MSG,
        defaultMessage: ru[TranslationKey.OBLIGATORY_FIELD_MSG],
    },
    [TranslationKey.SAVE]: {
        id: TranslationKey.SAVE,
        defaultMessage: ru[TranslationKey.SAVE],
    },
    [TranslationKey.SAVE_AS]: {
        id: TranslationKey.SAVE_AS,
        defaultMessage: ru[TranslationKey.SAVE_AS],
    },
    [TranslationKey.PROJECT_EDIT]: {
        id: TranslationKey.PROJECT_EDIT,
        defaultMessage: ru[TranslationKey.PROJECT_EDIT],
    },
    [TranslationKey.PROJECT_EDIT_NOT_SELECTED]: {
        id: TranslationKey.PROJECT_EDIT_NOT_SELECTED,
        defaultMessage: ru[TranslationKey.PROJECT_EDIT_NOT_SELECTED],
    },
    [TranslationKey.ERROR_NEW_PROJECT_NAME]: {
        id: TranslationKey.ERROR_NEW_PROJECT_NAME,
        defaultMessage: ru[TranslationKey.ERROR_NEW_PROJECT_NAME],
    },
    [TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY]: {
        id: TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY,
        defaultMessage: ru[TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY],
    },
    [TranslationKey.CANCEL]: {
        id: TranslationKey.CANCEL,
        defaultMessage: ru[TranslationKey.CANCEL],
    },
    [TranslationKey.CREATE_TITLE]: {
        id: TranslationKey.CREATE_TITLE,
        defaultMessage: ru[TranslationKey.CREATE_TITLE],
    },
    [TranslationKey.PROJECT_LIBRARY]: {
        id: TranslationKey.PROJECT_LIBRARY,
        defaultMessage: ru[TranslationKey.PROJECT_LIBRARY],
    },
    [TranslationKey.PROJECT_SOLVER]: {
        id: TranslationKey.PROJECT_SOLVER,
        defaultMessage: ru[TranslationKey.PROJECT_SOLVER],
    },
    [TranslationKey.ELECTROCITY]: {
        id: TranslationKey.ELECTROCITY,
        defaultMessage: ru[TranslationKey.ELECTROCITY],
    },
    [TranslationKey.ELECTROCITY_DC]: {
        id: TranslationKey.ELECTROCITY_DC,
        defaultMessage: ru[TranslationKey.ELECTROCITY_DC],
    },
    [TranslationKey.AUTO]: {
        id: TranslationKey.AUTO,
        defaultMessage: ru[TranslationKey.AUTO],
    },
    [TranslationKey.ALL_LIBRARY_TYPES]: {
        id: TranslationKey.ALL_LIBRARY_TYPES,
        defaultMessage: ru[TranslationKey.ALL_LIBRARY_TYPES],
    },
    [TranslationKey.SOLVER_USDS]: {
        id: TranslationKey.SOLVER_USDS,
        defaultMessage: ru[TranslationKey.SOLVER_USDS],
    },
    [TranslationKey.SOLVER_JAUTO]: {
        id: TranslationKey.SOLVER_JAUTO,
        defaultMessage: ru[TranslationKey.SOLVER_JAUTO],
    },
    [TranslationKey.SOLVER_MDCORE]: {
        id: TranslationKey.SOLVER_MDCORE,
        defaultMessage: ru[TranslationKey.SOLVER_MDCORE],
    },
    [TranslationKey.PROJECT_NEW]: {
        id: TranslationKey.PROJECT_NEW,
        defaultMessage: ru[TranslationKey.PROJECT_NEW],
    },
    [TranslationKey.PROJECT_IMPORT]: {
        id: TranslationKey.PROJECT_IMPORT,
        defaultMessage: ru[TranslationKey.PROJECT_IMPORT],
    },
    [TranslationKey.PROJECT_IMPORT_MODAL_TITLE]: {
        id: TranslationKey.PROJECT_IMPORT_MODAL_TITLE,
        defaultMessage: ru[TranslationKey.PROJECT_IMPORT_MODAL_TITLE],
    },
    [TranslationKey.PROJECT_LIST_EMPTY]: {
        id: TranslationKey.PROJECT_LIST_EMPTY,
        defaultMessage: ru[TranslationKey.PROJECT_LIST_EMPTY],
    },
    [TranslationKey.PROJECT_LIST_BY_FILTER_EMPTY]: {
        id: TranslationKey.PROJECT_LIST_BY_FILTER_EMPTY,
        defaultMessage: ru[TranslationKey.PROJECT_LIST_BY_FILTER_EMPTY],
    },
    [TranslationKey.PROJECT_NOT_SUPPORTED]: {
        id: TranslationKey.PROJECT_NOT_SUPPORTED,
        defaultMessage: ru[TranslationKey.PROJECT_NOT_SUPPORTED],
    },
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST]: {
        id: TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST,
        defaultMessage: ru[TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST],
    },
    [TranslationKey.DO_NOT_SAVE]: {
        id: TranslationKey.DO_NOT_SAVE,
        defaultMessage: ru[TranslationKey.DO_NOT_SAVE],
    },
    [TranslationKey.PAGE_ENGINEER_TITLE]: {
        id: TranslationKey.PAGE_ENGINEER_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_ENGINEER_TITLE],
    },
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE]: {
        id: TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE],
    },
    [TranslationKey.WORKSPACE_USER_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE]: {
        id: TranslationKey.WORKSPACE_USER_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE,
        defaultMessage: ru[TranslationKey.WORKSPACE_USER_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE],
    },
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_CONFIRM_SAVING_PROJECT]: {
        id: TranslationKey.WORKSPACE_PROJECT_BLOCK_CONFIRM_SAVING_PROJECT,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_BLOCK_CONFIRM_SAVING_PROJECT],
    },
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING]: {
        id: TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING],
    },
    [TranslationKey.PAGE_DEMO_PROJECTS_TITLE]: {
        id: TranslationKey.PAGE_DEMO_PROJECTS_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_DEMO_PROJECTS_TITLE],
    },
    [TranslationKey.PAGE_PROJECTS_TITLE]: {
        id: TranslationKey.PAGE_PROJECTS_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_PROJECTS_TITLE],
    },
    [TranslationKey.DEMO_PROJECT_LIST_EMPTY]: {
        id: TranslationKey.DEMO_PROJECT_LIST_EMPTY,
        defaultMessage: ru[TranslationKey.DEMO_PROJECT_LIST_EMPTY],
    },
    [TranslationKey.DEMO_PROJECT_DUPLICATE_DROPDOWN]: {
        id: TranslationKey.DEMO_PROJECT_DUPLICATE_DROPDOWN,
        defaultMessage: ru[TranslationKey.DEMO_PROJECT_DUPLICATE_DROPDOWN],
    },
    [TranslationKey.PAGE_FAV_PROJECTS_TITLE]: {
        id: TranslationKey.PAGE_FAV_PROJECTS_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_FAV_PROJECTS_TITLE],
    },
    [TranslationKey.PAGE_PROJECT_ADD_FAV]: {
        id: TranslationKey.PAGE_PROJECT_ADD_FAV,
        defaultMessage: ru[TranslationKey.PAGE_PROJECT_ADD_FAV],
    },
    [TranslationKey.PAGE_PROJECTS_ADD_FAV]: {
        id: TranslationKey.PAGE_PROJECTS_ADD_FAV,
        defaultMessage: ru[TranslationKey.PAGE_PROJECTS_ADD_FAV],
    },
    [TranslationKey.PAGE_PROJECTS_REMOVE]: {
        id: TranslationKey.PAGE_PROJECTS_REMOVE,
        defaultMessage: ru[TranslationKey.PAGE_PROJECTS_REMOVE],
    },
    [TranslationKey.PAGE_FAV_PROJECTS_EMPTY]: {
        id: TranslationKey.PAGE_FAV_PROJECTS_EMPTY,
        defaultMessage: ru[TranslationKey.PAGE_FAV_PROJECTS_EMPTY],
    },
    [TranslationKey.PAGE_PROJECT_DELETE_FAV]: {
        id: TranslationKey.PAGE_PROJECT_DELETE_FAV,
        defaultMessage: ru[TranslationKey.PAGE_PROJECT_DELETE_FAV],
    },
    [TranslationKey.PAGE_PROJECTS_REMOVE_FAV]: {
        id: TranslationKey.PAGE_PROJECTS_REMOVE_FAV,
        defaultMessage: ru[TranslationKey.PAGE_PROJECTS_REMOVE_FAV],
    },
    [TranslationKey.PROJECT_ADD_TO_DEMO_DROPDOWN]: {
        id: TranslationKey.PROJECT_ADD_TO_DEMO_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_ADD_TO_DEMO_DROPDOWN],
    },
    [TranslationKey.PROJECT_REMOVE_FROM_DEMO_DROPDOWN]: {
        id: TranslationKey.PROJECT_REMOVE_FROM_DEMO_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_REMOVE_FROM_DEMO_DROPDOWN],
    },
    [TranslationKey.FILE_MANAGER_FILES_COUNT]: {
        id: TranslationKey.FILE_MANAGER_FILES_COUNT,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_FILES_COUNT],
    },
    [TranslationKey.FILE_MANAGER_OPEN_FILE]: {
        id: TranslationKey.FILE_MANAGER_OPEN_FILE,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_OPEN_FILE],
    },
    [TranslationKey.FILE_MANAGER_SELECTED_COUNT]: {
        id: TranslationKey.FILE_MANAGER_SELECTED_COUNT,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_SELECTED_COUNT],
    },
    [TranslationKey.FILE_MANAGER_REMOVE_DIALOG]: {
        id: TranslationKey.FILE_MANAGER_REMOVE_DIALOG,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_REMOVE_DIALOG],
    },
    [TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION]: {
        id: TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION,
        defaultMessage: ru[TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION],
    },
    [TranslationKey.PROJECT_IMPORT_SELECT_FILE]: {
        id: TranslationKey.PROJECT_IMPORT_SELECT_FILE,
        defaultMessage: ru[TranslationKey.PROJECT_IMPORT_SELECT_FILE],
    },
    [TranslationKey.FILE_MANAGER_NEW_FOLDER]: {
        id: TranslationKey.FILE_MANAGER_NEW_FOLDER,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_NEW_FOLDER],
    },
    [TranslationKey.FILE_MANAGER_SORT_ASC_TOOLTIP]: {
        id: TranslationKey.FILE_MANAGER_SORT_ASC_TOOLTIP,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_SORT_ASC_TOOLTIP],
    },
    [TranslationKey.FILE_MANAGER_SORT_DESC_TOOLTIP]: {
        id: TranslationKey.FILE_MANAGER_SORT_DESC_TOOLTIP,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_SORT_DESC_TOOLTIP],
    },
    [TranslationKey.FILE_MANAGER_SORT_DEFAULT_TOOLTIP]: {
        id: TranslationKey.FILE_MANAGER_SORT_DEFAULT_TOOLTIP,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_SORT_DEFAULT_TOOLTIP],
    },
    [TranslationKey.FILE_MANAGER_SELECT_ALL_TOOLTIP]: {
        id: TranslationKey.FILE_MANAGER_SELECT_ALL_TOOLTIP,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_SELECT_ALL_TOOLTIP],
    },
    [TranslationKey.FILE_MANAGER_PREVIEW_TOOLTIP]: {
        id: TranslationKey.FILE_MANAGER_PREVIEW_TOOLTIP,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_PREVIEW_TOOLTIP],
    },
    [TranslationKey.FILE_MANAGER_MANAGER_VIEW_TOOLTIP]: {
        id: TranslationKey.FILE_MANAGER_MANAGER_VIEW_TOOLTIP,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_MANAGER_VIEW_TOOLTIP],
    },
    [TranslationKey.FILE_MANAGER_SORT_DATE]: {
        id: TranslationKey.FILE_MANAGER_SORT_DATE,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_SORT_DATE],
    },
    [TranslationKey.FILE_MANAGER_MANAGER_VIEW_UPDATE]: {
        id: TranslationKey.FILE_MANAGER_MANAGER_VIEW_UPDATE,
        defaultMessage: ru[TranslationKey.FILE_MANAGER_MANAGER_VIEW_UPDATE],
    },
    [TranslationKey.USER_BLOCK_EMPTY_MESSAGE]: {
        id: TranslationKey.USER_BLOCK_EMPTY_MESSAGE,
        defaultMessage: ru[TranslationKey.USER_BLOCK_EMPTY_MESSAGE],
    },
    [TranslationKey.USER_BLOCKS_LIST_TITLE]: {
        id: TranslationKey.USER_BLOCKS_LIST_TITLE,
        defaultMessage: ru[TranslationKey.USER_BLOCKS_LIST_TITLE],
    },
    [TranslationKey.USER_BLOCK_DELETE_CONFIRM]: {
        id: TranslationKey.USER_BLOCK_DELETE_CONFIRM,
        defaultMessage: ru[TranslationKey.USER_BLOCK_DELETE_CONFIRM],
    },
    [TranslationKey.USER_BLOCK_EDIT_SYSTEM_PARAMETERS]: {
        id: TranslationKey.USER_BLOCK_EDIT_SYSTEM_PARAMETERS,
        defaultMessage: ru[TranslationKey.USER_BLOCK_EDIT_SYSTEM_PARAMETERS],
    },
});
