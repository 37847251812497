import { NavigateFunction } from 'react-router-dom';

import {
    ActionReducerMapBuilder,
    AnyAction,
    createAsyncThunk,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
    API_BAD_REQUEST_CODE,
    deleteCookie,
    fuzzyСomparison,
    getCookie,
    KEEP_LOCAL_STORAGE_KEYS,
    LICENSE_DISTRIBS,
    sanitizePhone,
    setCookie,
    setLocale,
    setMode,
    setTheme,
} from '@repeat/constants';
import { environment } from '@repeat/environment';
import {
    DistribTypes,
    EmailData,
    IsOnlineError,
    IUserState,
    LicenseActivationStatuses,
    LoginUserData,
    NotificationTypes,
    RegistrationData,
    ResetPasswordData,
    Statuses,
    TNotificationDetailMessage,
    UpdateForgottenPasswordData,
    User,
} from '@repeat/models';
import { ForgotPasswordService, UserService } from '@repeat/services';
import { CODE_LIST, TranslationKey } from '@repeat/translations';

import { actions as ApplicationActions } from './appSlice';

const { DISTRIB, REPEAT_DOMAIN: repeatDomain, ACCOUNT_DOMAIN: accountDomain, AUTH_DOMAIN: authDomain } = environment;

export const initialState: IUserState = {
    currentUser: null,
    isAuth: false,
    status: Statuses.IDLE,
    error: null,
    forgotPassword: {
        status: Statuses.IDLE,
        error: null,
    },
    resetPassword: {
        status: Statuses.IDLE,
        error: null,
    },
    updateForgottenPassword: {
        status: Statuses.IDLE,
        error: null,
    },
    isOnline: {
        status: Statuses.IDLE,
        error: null,
        errorCount: 0,
    },
    refreshToken: {
        status: Statuses.IDLE,
        error: null,
    },
};

const reducers = {
    loadUserSuccess: (state: IUserState, action: PayloadAction<{ user: User }>) => ({
        ...state,
        currentUser: action.payload.user,
        isAuth: true,
    }),
    loginRequest: () => ({
        ...initialState,
        status: Statuses.LOADING,
    }),
    loginSuccess: (state: IUserState, action: PayloadAction<{ user: User }>) => ({
        ...state,
        currentUser: action.payload.user,
        isAuth: true,
        status: Statuses.SUCCEEDED,
    }),
    loginFailed: (state: IUserState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        status: Statuses.FAILED,
        error: action.payload.error,
    }),
    signUpRequest: () => ({
        ...initialState,
        status: Statuses.LOADING,
    }),
    signUpSuccess: (state: IUserState, action: PayloadAction<{ user: User }>) => ({
        ...state,
        status: Statuses.SUCCEEDED,
        currentUser: action.payload.user,
    }),
    signUpFailed: (state: IUserState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        status: Statuses.FAILED,
        error: action.payload.error,
    }),
    logoutRequest: (state: IUserState) => ({
        ...state,
        currentUser: null,
        isAuth: false,
        status: Statuses.IDLE,
        error: null,
    }),
    logoutSuccess: (state: IUserState) => ({
        ...state,
        currentUser: null,
        isAuth: false,
    }),
    updateUserRequest: (state: IUserState) => ({
        ...state,
        status: Statuses.LOADING,
    }),
    updateUserSuccess: (state: IUserState, action: PayloadAction<{ user: User }>) => ({
        ...state,
        currentUser: {
            ...state.currentUser,
            ...action.payload.user,
        },
        status: Statuses.SUCCEEDED,
    }),
    updateUserFailed: (state: IUserState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        status: Statuses.FAILED,
        error: action.payload.error,
    }),
    updateSettingsRequest: (state: IUserState) => ({
        ...state,
        status: Statuses.LOADING,
    }),
    updateSettingsSuccess: (state: IUserState) => ({
        ...state,
        status: Statuses.SUCCEEDED,
    }),
    updateSettingsFailed: (state: IUserState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        status: Statuses.FAILED,
        error: action.payload.error,
    }),
    getUserRequest: (state: IUserState) => ({
        ...state,
        status: Statuses.LOADING,
    }),
    getUserSuccess: (state: IUserState, action: PayloadAction<{ user: User }>) => ({
        ...state,
        currentUser: {
            ...state.currentUser,
            ...action.payload.user,
        },
        status: Statuses.SUCCEEDED,
    }),
    getUserFailed: (state: IUserState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        status: Statuses.FAILED,
        error: action.payload.error,
    }),
    updatePasswordRequest: (state: IUserState) => ({
        ...state,
        status: Statuses.LOADING,
    }),
    updatePasswordSuccess: (state: IUserState) => ({
        ...state,
    }),
    updatePasswordFailed: (state: IUserState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        status: Statuses.FAILED,
        error: action.payload.error,
    }),
    isOnlineRequest: (state: IUserState) => ({
        ...state,
        isOnline: {
            ...state.isOnline,
            status: Statuses.LOADING,
        },
    }),
    isOnlineSuccess: (state: IUserState) => ({
        ...state,
        isOnline: {
            ...state.isOnline,
            status: Statuses.SUCCEEDED,
            errorCount: 0,
        },
    }),
    isOnlineFailed: (state: IUserState, action: PayloadAction<{ error: any }>) => {
        const {
            isOnline: { errorCount },
        } = state;
        const count = errorCount + 1;
        return {
            ...state,
            isOnline: {
                ...state.isOnline,
                status: Statuses.FAILED,
                error: action.payload.error,
                errorCount: count,
            },
        };
    },
    refreshTokenRequest: (state: IUserState) => ({
        ...state,
        refreshToken: {
            ...state.refreshToken,
            status: Statuses.LOADING,
        },
    }),
    refreshTokenSuccess: (state: IUserState) => ({
        ...state,
        refreshToken: {
            ...state.refreshToken,
            status: Statuses.SUCCEEDED,
        },
    }),
    refreshTokenFailed: (state: IUserState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        refreshToken: {
            ...state.refreshToken,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
};

const extraReducersAsyncThunks = {
    forgotPassword: createAsyncThunk(
        'auth/forgotPassword',
        async (data: EmailData & { locale?: string }, { dispatch, rejectWithValue }) => {
            try {
                return await ForgotPasswordService.userForgotPassword(data);
            } catch (error) {
                if (error instanceof AxiosError) {
                    if (error.code === 'ERR_NETWORK') {
                        dispatch(
                            ApplicationActions.showNotification({
                                notification: {
                                    type: NotificationTypes.ERROR,
                                    message: TranslationKey.NETWORK_ERR_MSG,
                                },
                            })
                        );
                    }
                    const errorMessage = error.response?.data?.message as string;
                    return rejectWithValue(errorMessage);
                    //TODO: + handle the case where the user not registered at all (no such email exists in db)
                } else {
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: { type: NotificationTypes.ERROR, message: TranslationKey.ERROR_UNKNOWN },
                        })
                    );
                }
                return rejectWithValue(error);
            }
        }
    ),

    resetPassword: createAsyncThunk(
        'auth/resetPassword',
        async ({ token }: ResetPasswordData, { rejectWithValue, dispatch }) => {
            try {
                if (!token.trim().length) {
                    return dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: TranslationKey.MALFORMED_EMAIL_MSG,
                            },
                        })
                    );
                }
                return await ForgotPasswordService.userResetPassword({ token });
            } catch (error: any) {
                const errorMessage = error.response?.data?.message || error.toString();
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.ERROR,
                            message: TranslationKey.FORGOT_PASSWORD_TOKEN_INVALID,
                        },
                    })
                );
                return rejectWithValue(errorMessage);
            }
        }
    ),

    updateForgottenPassword: createAsyncThunk(
        'auth/updateForgottenPassword',
        async (data: UpdateForgottenPasswordData, { dispatch, rejectWithValue }) => {
            try {
                const updateResult = await ForgotPasswordService.userUpdateForgottenPassword(data);
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.SUCCESS,
                            message: TranslationKey.MESSAGE_PASSWORD_UPDATED,
                        },
                    })
                );
                return updateResult;
            } catch (error: any) {
                if (error instanceof AxiosError && error.code === 'ERR_NETWORK') {
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: TranslationKey.NETWORK_ERR_MSG,
                            },
                        })
                    );
                    //TODO: + handle the case where the user did not verify token (or not registered at all)
                } else {
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: { type: NotificationTypes.ERROR, message: TranslationKey.ERROR_UNKNOWN },
                        })
                    );
                }
                const errorMessage = error.response?.data?.message || error.toString();
                return rejectWithValue(errorMessage);
            }
        }
    ),

    signIn: createAsyncThunk('auth/signIn', async (data: LoginUserData, { dispatch, rejectWithValue }) => {
        dispatch(actions.loginRequest());

        try {
            const response = await UserService.signIn(data);
            const { accessToken, refreshToken, accessBaseToken, refreshBaseToken } = response;
            const searchParams = new URLSearchParams(document.location.search);
            const redirectUri = searchParams.get('redirectUri');

            if (!!accessToken && !!refreshToken && !!accessBaseToken && !!refreshBaseToken) {
                const baseDomain = getBaseDomainIfCloud();
                setCookie('accessToken', accessToken);
                setCookie('refreshToken', refreshToken);
                setCookie('accessBaseToken', accessBaseToken, baseDomain!);
                setCookie('refreshBaseToken', refreshBaseToken, baseDomain!);

                if (searchParams && redirectUri) {
                    window.location.href = redirectUri;
                } else {
                    const domain = document.referrer;

                    window.location.assign(getDomain(domain));
                }
            }
            return response;
        } catch (error: any) {
            const httpStatus = error?.response?.status;
            const errorKey = httpStatus === 406 ? TranslationKey.ERROR_SIGIN_DATA : TranslationKey.ERROR_UNKNOWN;

            dispatch(actions.loginFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
            return rejectWithValue(error);
        }
    }),
};

export const { forgotPassword, updateForgottenPassword, resetPassword, signIn } = extraReducersAsyncThunks;

const userSlice = createSlice({
    name: 'appUser',
    initialState,
    reducers,
    extraReducers: (builder: ActionReducerMapBuilder<IUserState>) =>
        builder
            .addCase(forgotPassword.pending, (state) => {
                state.forgotPassword.status = Statuses.LOADING;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.forgotPassword.status = Statuses.FAILED;
                state.forgotPassword.error = action.error?.message || null;
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.forgotPassword.status = Statuses.SUCCEEDED;
            })
            .addCase(updateForgottenPassword.pending, (state) => {
                state.updateForgottenPassword.status = Statuses.LOADING;
            })
            .addCase(updateForgottenPassword.rejected, (state, action) => {
                state.updateForgottenPassword.status = Statuses.FAILED;
                state.updateForgottenPassword.error = action.error?.message || null;
            })
            .addCase(updateForgottenPassword.fulfilled, (state) => {
                state.updateForgottenPassword.status = Statuses.SUCCEEDED;
            })
            .addCase(resetPassword.pending, (state) => {
                state.resetPassword.status = Statuses.LOADING;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.resetPassword.status = Statuses.FAILED;
                state.resetPassword.error = action.error?.message || null;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.resetPassword.status = Statuses.SUCCEEDED;
            }),
});

export const { actions, reducer } = userSlice;

export const signUp =
    (user: RegistrationData, locale?: string, navigate?: NavigateFunction) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        dispatch(actions.signUpRequest);
        try {
            const preparedUser: RegistrationData = {
                ...user,
                phone: sanitizePhone(user.phone),
            };
            const response: any = await UserService.signUp(preparedUser, locale);
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.MESSAGE_REGISTRATION_SUCCESS,
                    },
                })
            );
            dispatch(actions.signUpSuccess({ user: response.data }));
            return navigate && navigate('/', { replace: true });
        } catch (error: any) {
            const httpStatus = error?.response?.status;
            const errorKey = TranslationKey.ERROR_SIGNUP_DATA;
            const details: TNotificationDetailMessage[] =
                httpStatus === API_BAD_REQUEST_CODE
                    ? error?.response.data.errors.map((e: { message: string }) => ({
                          message: e.message,
                      }))
                    : [];

            dispatch(actions.signUpFailed({ error: errorKey }));
            return dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                        details,
                    },
                })
            );
        }
    };

export const getBaseDomainIfCloud = () => {
    if (DISTRIB !== DistribTypes.CLOUD) {
        return null;
    } else {
        const secondLevelDomain = window.location.hostname.split('.').slice(1).join('.');
        return secondLevelDomain;
    }
};

const getDomain = (domain: string) => {
    if (fuzzyСomparison(accountDomain, domain)) {
        return domain;
    } else {
        return repeatDomain;
    }
};

const isSameApp = (domainA: string, domainB: string) => {
    return domainA === domainB;
};

const isCurrentApp = (domainA: string) => {
    const currentHref = window.location.href;
    return currentHref.includes(domainA);
};

const hasActiveLicenseKey = (state: any) => {
    const data = state.app.license.activeLicense;
    return (
        data !== null &&
        data.licenseKey !== null &&
        (data.activationStatus === LicenseActivationStatuses.COMPLETED ||
            (data.activationStatus === LicenseActivationStatuses.REQUIRED && data?.trialPeriodExpireAt !== undefined))
    );
};

export const checkUserAndDomain =
    (domain: string, navigate?: NavigateFunction) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        const state = getState();
        const accessBaseToken = getCookie('accessBaseToken') ?? '';
        const refreshBaseToken = getCookie('refreshBaseToken') ?? '';

        if (LICENSE_DISTRIBS.includes(DISTRIB)) {
            if (!hasActiveLicenseKey(state)) {
                const redirectDomain = accountDomain;
                const redirectPath = `/license`;
                if (!isSameApp(redirectDomain, domain)) {
                    const redirectUrl = `${redirectDomain}${redirectPath}`;
                    window.location.assign(redirectUrl);
                } else {
                    navigate && navigate(redirectPath, { replace: true });
                }
                return;
            }
            if (isCurrentApp(accountDomain)) {
                const currentUrl = window.location.href;
                if (currentUrl.startsWith(`${accountDomain}/license`)) {
                    return;
                }
            }
        }

        if (authDomain === domain && accessBaseToken && refreshBaseToken) {
            const redirectDomain = getDomain(domain);

            if (!isSameApp(redirectDomain, domain)) {
                window.location.assign(redirectDomain);
            } else {
                const url = new URL(redirectDomain);
                navigate && navigate(url.pathname, { replace: true });
            }
            return;
        } else if (authDomain !== domain && (accessBaseToken === '' || refreshBaseToken === '')) {
            if (isCurrentApp(authDomain)) {
                return;
            }

            if (!isSameApp(authDomain, domain)) {
                window.location.assign(authDomain);
            } else {
                const url = new URL(authDomain);
                navigate && navigate(url.pathname, { replace: true });
            }

            return;
        }

        return;
    };

export const loadUser = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const user = await UserService.getUser();

        dispatch(
            actions.loadUserSuccess({
                user,
            })
        );
    } catch (error: any) {
        console.error(error);
    }
};

export const logout = () => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    // TODO make action for stop project and other cleanings, no others actions except logout must be here
    dispatch(actions.logoutRequest());

    try {
        const baseDomain = getBaseDomainIfCloud();
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
            if (!KEEP_LOCAL_STORAGE_KEYS.includes(key)) {
                localStorage.removeItem(key);
            }
        });
        deleteCookie('accessToken');
        deleteCookie('refreshToken');
        deleteCookie('accessBaseToken', baseDomain!);
        deleteCookie('refreshBaseToken', baseDomain!);

        dispatch(actions.logoutSuccess());
        window.location.assign(authDomain);
    } catch (error: any) {
        console.error(error);
    }
};

export const updateUser =
    (data: { userName: string; email: string; phone: string }, navigate: NavigateFunction) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        dispatch(actions.updateUserRequest());

        try {
            const response = await UserService.updateUser(data);
            const user: User = { ...response.data };

            localStorage.setItem('authUser', JSON.stringify(user));

            dispatch(
                actions.updateUserSuccess({
                    user,
                })
            );
            navigate('/account');
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.MESSAGE_ACCOUNT_UPDATED,
                    },
                })
            );
        } catch (error: any) {
            const httpStatus = error?.response?.status;
            const errorKey = TranslationKey.ERROR_ACCOUNT_UPDATE;
            const details: TNotificationDetailMessage[] =
                httpStatus === API_BAD_REQUEST_CODE
                    ? error?.response.data.errors.map((e: { code?: number; message: string }) => ({
                          message: e?.code ? CODE_LIST[e.code] : e.message,
                      }))
                    : [];
            dispatch(actions.updateUserFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                        details,
                    },
                })
            );
        }
    };

export const updatePassword =
    (data: { password: string; newPassword: string }) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        dispatch(actions.updatePasswordRequest());

        try {
            await UserService.updatePassword(data);

            dispatch(actions.updatePasswordSuccess());
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.MESSAGE_PASSWORD_UPDATED,
                    },
                })
            );
        } catch (error: any) {
            const httpStatus = error?.response?.status;
            const errorKey = TranslationKey.ERROR_ACCOUNT_UPDATE;
            const details: TNotificationDetailMessage[] =
                httpStatus === API_BAD_REQUEST_CODE
                    ? error?.response.data.errors.map((e: { code?: number; message: string }) => ({
                          message: e?.code ? CODE_LIST[e.code] : e.message,
                      }))
                    : [];

            dispatch(actions.updatePasswordFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                        details,
                    },
                })
            );
        }
    };

export const getUser = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    dispatch(actions.getUserRequest());

    try {
        const user = await UserService.getUser();

        dispatch(actions.getUserSuccess({ user }));
    } catch (e: any) {
        dispatch(actions.getUserFailed({ error: e }));
    }
};

export const setOnline = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    dispatch(actions.isOnlineRequest());
    try {
        await UserService.setOnline();
        return dispatch(actions.isOnlineSuccess());
    } catch (e: AxiosError | any) {
        let error = null;
        if (e.response && e.response.data && Array.isArray(e.response.data.errors)) {
            error = e.response.data.errors.filter((error: IsOnlineError) =>
                error.code === 1081 ? { ...error } : null
            );
        }
        if (error !== null) {
            return dispatch(actions.isOnlineFailed({ error }));
        }
        return dispatch(actions.isOnlineFailed({ error: e }));
    }
};

export const updateSettings =
    (data: { locale: string; theme: string; isEngineer: boolean }, navigate: NavigateFunction) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        dispatch(actions.updateSettingsRequest());
        const user = getState().appUser.currentUser as User;

        try {
            const locale = data.locale;
            const theme = data.theme;
            const isEngineer = data.isEngineer;
            const payload = {
                settings: {
                    locale,
                    isEngineer,
                    theme,
                },
            };
            const response = await UserService.updateSettings(payload);

            setLocale(locale);
            setTheme(theme);
            setMode(isEngineer);
            const updatedUser = { ...user, settings: { ...user.settings, locale, theme, isEngineer } };
            dispatch(actions.updateUserSuccess({ user: updatedUser }));

            if (navigate && getState().appUser?.currentUser?.settings?.locale !== locale) {
                setTimeout(() => {
                    navigate(0);
                }, 500);
            }
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.MESSAGE_ACCOUNT_UPDATED,
                    },
                })
            );
        } catch (error: any) {
            const httpStatus = error?.response?.status;
            const errorKey = TranslationKey.ERROR_ACCOUNT_SETTINGS_UPDATE;
            const details: TNotificationDetailMessage[] =
                httpStatus === API_BAD_REQUEST_CODE
                    ? error?.response.data.errors.map((e: { code?: number; message: string }) => ({
                          message: e?.code ? CODE_LIST[e.code] : e.message,
                      }))
                    : [];
            dispatch(actions.updateSettingsFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                        details,
                    },
                })
            );
        }
    };

export const refreshUserToken = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    dispatch(actions.refreshTokenRequest());
    try {
        const baseDomain = getBaseDomainIfCloud();
        const refreshToken = getCookie('refreshToken') ?? '';
        const response = await UserService.refreshToken(refreshToken);

        setCookie('accessToken', response.data.accessToken);
        setCookie('refreshToken', response.data.refreshToken);
        setCookie('accessBaseToken', response.data.refreshBaseToken, baseDomain!);
        setCookie('refreshBaseToken', response.data.refreshBaseToken, baseDomain!);

        return dispatch(actions.refreshTokenSuccess());
    } catch (e: AxiosError | any) {
        const error = null;
        if (error !== null) {
            return dispatch(actions.refreshTokenFailed({ error }));
        }
        return dispatch(actions.refreshTokenFailed({ error: e }));
    }
};
