import styled from 'styled-components';

import { pxToRem, uiColors } from '@repeat/ui-kit';

export const SAttentionMessage = styled.div`
    padding: ${pxToRem(12)};
    font-size: ${pxToRem(12)};
    color: ${uiColors.orange};
    margin-bottom: ${pxToRem(12)};
`;
