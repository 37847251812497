import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';
import { gte, lt, lte } from 'semver';

import { CANVAS } from '@repeat/constants';
import {
    ElemParams,
    ElemProps,
    ILibraryItem,
    INewSchemaState,
    PortTypes,
    TElement,
    TLibraryItemPort,
    TLibraryType,
    TPortPosition,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
} from '@repeat/models';

import { applyPatch_1_4_0 } from './patches/1.4.0';
import { applyPatch_1_4_1 } from './patches/1.4.1';
import { applyPatch_1_8_0 } from './patches/1.8.0';
import { applyPatch_1_8_1 } from './patches/1.8.1';
import { applyPatch_1_9_0 } from './patches/1.9.0';
import { applyPatch_2_5_1 } from './patches/2.5.0';

interface ISchemaItems {
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
    groups?: TSchemaGroup[];
}

interface IBlockChangesDiff {
    arePortsChanged: boolean;
    arePropsChanged: boolean;
}
const ELEMENT_PARAMS_UPGRADE_EXCEPTIONS = [
    'electrocityMeter',
    'SWProperties',
    'project',
    'userBlock',
    'group',
    'RFSParameters',
];
const ELEMENTS_NEED_TO_UPDATE_MANUALLY_EXCEPTIONS = ['project', 'group', 'userBlock', 'RFSParameters'];
const ELEMENT_ELECTROCITY_METER_TYPE = 'electrocityMeter';
const ELEMENTS_WITH_USER_PORTS = [
    'fmi',
    'jython',
    'project',
    'InPort',
    'OutPort',
    'group',
    'userBlock',
    'RFSParameters',
];
const ELEMENT_PROJECT_TYPE = 'project';
const ELEMENT_GROUP_TYPE = 'group';
const ELEMENT_USER_BLOCK_TYPE = 'userBlock';
const ELEMENTS_WITH_CUSTOM_NAMES = ['fmi', 'PulseqSource', 'group', 'userBlock', 'RFSParameters'];
const ELEMENT_ELECTROCITY_BUS_TYPE = 'electrocityBus';
const OLD_BUS_WIDTH = 1435;
export const ELEMENTS_WITH_CONDITIONAL_PORTS_TYPES = [
    '_Lever',
    '_ReductionDrive',
    '_TranslationToRotation',
    '_RotationToTranslation',
    'ElectronicMotorDrive',
    'AnnularLeakage',
    'PressureAndTemperatureSensorTH',
    'TemperatureSensor',
    'ThermalResistance',
    'VariableReductionDrive',
];

const findDefaultPortPosition = (libraryPorts: TLibraryItemPort[], port: TSchemaHandle) => {
    const availablePortsInput = libraryPorts.filter((port) => port.type === PortTypes.INPUT);
    const availablePortsOutput = libraryPorts.filter((port) => port.type === PortTypes.OUTPUT);
    if (port.type === PortTypes.INPUT) {
        return availablePortsInput[availablePortsInput.length - 1].position;
    } else {
        return availablePortsOutput[availablePortsOutput.length - 1].position;
    }
};

export default class SchemaAdapterService {
    static upgrade = (
        schema: INewSchemaState,
        libraryItems: ILibraryItem[],
        versionFrom: string | null,
        versionTo: string,
        libraryPortTypes: TPortTypeComplex[]
    ): INewSchemaState => {
        const baseSchemaItems: ISchemaItems = !versionFrom
            ? applyPatch_1_4_0(schema.rawSchemaItems, libraryItems, versionFrom, versionTo, libraryPortTypes)
            : (schema.rawSchemaItems as ISchemaItems);

        const schemaItems = SchemaAdapterService._applyPatches(
            baseSchemaItems,
            libraryItems,
            libraryPortTypes,
            versionFrom,
            versionTo
        );

        const updatedSchemaElements = SchemaAdapterService._upgradeElements(
            schemaItems.elements,
            schemaItems.wires,
            libraryItems,
            libraryPortTypes
        ) as TSchemaNode[];

        const updatedExternalInfo = SchemaAdapterService._upgradeExternalInfo(
            schema.externalInfo,
            updatedSchemaElements
        );

        const elementsWithParams = updatedSchemaElements.map((n) => {
            const { id, name, index, elemParams, blockId } = n.data;
            return {
                id,
                name,
                index,
                elemParams,
                blockId: blockId || null,
            };
        });
        return {
            ...schema,
            version: versionTo,
            schemaItems: {
                ...schemaItems,
                elements: updatedSchemaElements,
            },
            elementsWithParams,
            externalInfo: updatedExternalInfo,
        };
    };

    private static _upgradeElements = (
        nodes: TSchemaNode[],
        connections: TSchemaConnection[],
        libraryItems: ILibraryItem[],
        libraryPortTypes: TPortTypeComplex[]
    ) => {
        return nodes.map((node: TSchemaNode) => {
            const libraryItem = libraryItems.find(
                (item) => item.type === node.data.type && item?.subtype === node.data?.subtype
            );
            if (!libraryItem) {
                return node;
            }

            const {
                view,
                isViewOnly,
                type,
                description,
                hasConfigurations,
                parametersToDisplay,
                hasManagedPorts,
                isDeprecated,
                maintainedTo,
            } = libraryItem;
            const stateParameters = type === 'project' ? node.data.stateParameters : libraryItem.stateParameters;
            const isNeedToUpdateManually = SchemaAdapterService._checkIsNeedToUpdateManually(node, libraryItem);

            const hasUserPorts = SchemaAdapterService._hasUserPorts(node);
            const { name, shortName } = SchemaAdapterService._upgradeElementName(node, libraryItem);
            const { properties: updatedElemProps } = SchemaAdapterService._upgradeElementProperties(node, libraryItem);

            const availablePorts =
                !isNeedToUpdateManually && !hasUserPorts
                    ? SchemaAdapterService._upgradeElementAvailablePorts(node, libraryItem, libraryPortTypes)
                    : node.data.availablePorts;
            const elemParams = !isNeedToUpdateManually
                ? SchemaAdapterService._upgradeElementParams(node, libraryItem, nodes, libraryItems)
                : node.data.elemParams;

            const data: TElement = {
                ...node.data,
                name,
                shortName,
                availablePorts,
                elemParams,
                elemProps: [...updatedElemProps],
                stateParameters,
                view,
                isViewOnly,
                description,
                parametersToDisplay,
                hasConfigurations,
                hasManagedPorts,
                isNeedToUpdate: isNeedToUpdateManually,
                isDeprecated: isDeprecated,
                maintainedTo,
            };

            if (type === ELEMENT_ELECTROCITY_BUS_TYPE && (!node.width || !node.height)) {
                const width = OLD_BUS_WIDTH;
                return {
                    ...node,
                    width,
                    ...(!node.height && {
                        height: data.view && data.view.minHeight ? data.view.minHeight : CANVAS.ELEMENT_MIN_HEIGHT,
                    }),
                    data,
                };
            }

            if (!node.width && !node.height) {
                const width = data.view && data.view.minWidth ? data.view.minWidth : CANVAS.ELEMENT_MIN_WIDTH;
                const height = data.view && data.view.minHeight ? data.view.minHeight : CANVAS.ELEMENT_MIN_HEIGHT;
                return {
                    ...node,
                    data,
                    height,
                    width,
                    rotation: 0,
                };
            }

            return {
                ...node,
                data,
            };
        });
    };

    private static _upgradeElementName = (
        node: TSchemaNode,
        libraryItem: ILibraryItem
    ): { name: string; shortName: string } => {
        const hasCustomName = ELEMENTS_WITH_CUSTOM_NAMES.includes(node.data.type);
        return {
            name: hasCustomName ? node.data.name : libraryItem.name,
            shortName: hasCustomName ? node.data.shortName : libraryItem.shortName,
        };
    };

    private static _checkIsNeedToUpdateManually = (node: TSchemaNode, libraryItem: ILibraryItem): boolean => {
        const { availablePorts } = libraryItem;
        const diff: IBlockChangesDiff = {
            arePortsChanged: false,
            arePropsChanged: false,
        };

        if (
            [...ELEMENTS_NEED_TO_UPDATE_MANUALLY_EXCEPTIONS, ...ELEMENTS_WITH_CONDITIONAL_PORTS_TYPES].includes(
                node.data.type
            )
        ) {
            return false;
        }

        const nodePropertiesNames = node.data.elemProps.map((property: ElemProps) => property.name);
        const libraryPropertiesNames = libraryItem.elemProps.map((property: ElemProps) => property.name);
        const notRequired: Record<string, any> = {};
        libraryItem.elemProps.map((prop) => {
            if (prop.rules) {
                prop.rules.forEach((rule: Record<string, any>) => {
                    if (rule['required'] === false) {
                        notRequired[prop.name] = rule['required'];
                    }
                });
            }
        });

        const deletedPropertiesNames = nodePropertiesNames.filter(
            (name: string) => !libraryPropertiesNames.includes(name)
        );
        const addedPropertiesNames = libraryPropertiesNames.filter((name: string) => {
            return !nodePropertiesNames.includes(name) && notRequired[name] !== false;
        });
        if (deletedPropertiesNames.length > 0) {
            diff.arePropsChanged = true;
        }

        if (node.data.isDeprecated) {
            return diff.arePropsChanged;
        }

        if (libraryItem.hasManagedPorts !== true && availablePorts.length !== node.data.availablePorts.length) {
            diff.arePortsChanged = true;
        }

        return diff.arePortsChanged || diff.arePropsChanged;
    };

    private static _hasUserPorts = (node: TSchemaNode): boolean => {
        return ELEMENTS_WITH_USER_PORTS.includes(node.data.type);
    };

    private static _upgradeElementAvailablePorts = (
        node: TSchemaNode,
        libraryItem: ILibraryItem,
        libraryPortTypes: TPortTypeComplex[]
    ): TSchemaHandle[] => {
        const { availablePorts } = libraryItem;
        const ports = node.data.availablePorts.map((port) => {
            if (availablePorts) {
                const portFromLibrary = availablePorts.find((p) => p.name === port.name);
                let libraries: TLibraryType[] = [];
                let position: TPortPosition;
                if (!portFromLibrary) {
                    libraries = availablePorts[0].libraries;
                    position = findDefaultPortPosition(availablePorts, port);
                } else {
                    libraries = portFromLibrary.libraries;
                    position = portFromLibrary.position;
                }

                if (portFromLibrary && portFromLibrary.typeConnection) {
                    return { ...port, libraries, position, typeConnection: portFromLibrary.typeConnection };
                }

                return { ...port, libraries, position };
            } else {
                return port;
            }
        });
        const portsUpdated = ports.map((port) => {
            const portComplexType = libraryPortTypes.find((p) => p.type === port.typeConnection);
            if (port.typeConnection && portComplexType) {
                const { type, ...rest } = portComplexType;
                return { ...port, ...rest };
            }
            return port;
        });

        return [...portsUpdated];
    };

    private static _upgradeElementParams = (
        node: TSchemaNode,
        libraryItem: ILibraryItem,
        nodes: TSchemaNode[],
        libraryItems: ILibraryItem[]
    ): ElemParams[] => {
        const { elemParams } = libraryItem;
        if (node.data.type === ELEMENT_ELECTROCITY_METER_TYPE) {
            const connectedBlockID =
                node.data.elemProps.find((prop) => prop.name === 'parentID')?.value.toString() || '';

            const connectedBlockStateParameter =
                node.data.elemProps.find((prop) => prop.name === 'par')?.value.toString() || '';

            const connectedBlockStateUnitsFactor =
                node.data.elemProps.find((prop) => prop.name === 'unitsFactor')?.value.toString() || '';

            const connectedBlock = nodes.find((node) => node.id === connectedBlockID);

            const connectedBlockType = connectedBlock?.data.type || '';
            const connectedBlockIndex = connectedBlock?.data.index || '';

            const connectedBlockLibrary = libraryItems.find((item) => item.type === connectedBlockType);

            const unitsFactorString =
                connectedBlockStateUnitsFactor === '1' ||
                connectedBlockStateUnitsFactor === '1.0' ||
                connectedBlockStateUnitsFactor === ''
                    ? ''
                    : `\u00B7 ${connectedBlockStateUnitsFactor}`;

            const connectedBlockLibraryStateParameter = connectedBlockLibrary?.stateParameters.find(
                (param) => param.name === connectedBlockStateParameter
            );

            const connectedBlockLibraryName = connectedBlockLibrary?.name;

            const meterParameter = node.data.elemParams[0];
            return [
                {
                    ...meterParameter,
                    description: `${connectedBlockLibraryName} [${connectedBlockIndex}] / ${connectedBlockLibraryStateParameter?.description}`,
                    unit: `${connectedBlockLibraryStateParameter?.unit} ${unitsFactorString}`,
                },
            ];
        }

        const nodeElemParams = node.data.elemParams || [];

        const params = !ELEMENT_PARAMS_UPGRADE_EXCEPTIONS.includes(node.data.type) // TODO to come with idea how to improve this condition
            ? nodeElemParams.map((parameter) => {
                  const libraryParameter = elemParams.find((param) => param.name === parameter.name);
                  if (libraryParameter) {
                      const { description, unit, help } = libraryParameter;
                      if (!help) {
                          return { ...parameter, description, unit };
                      }
                      return { ...parameter, description, unit, help };
                  }
                  return parameter;
              })
            : [...nodeElemParams];

        return [...params];
    };

    private static _upgradeElementProperties = (
        node: TSchemaNode,
        libraryItem: ILibraryItem
    ): { properties: ElemProps[] } => {
        const { elemProps, type } = libraryItem;

        const props = node.data.elemProps;
        if ([ELEMENT_USER_BLOCK_TYPE, ELEMENT_GROUP_TYPE].includes(type)) {
            return { properties: props };
        }
        let newProps: ElemProps[] = elemProps.filter((x) => !props.some((y) => x.name === y.name));

        if (type === ELEMENT_ELECTROCITY_BUS_TYPE && newProps.length !== 0) {
            newProps = newProps.map((p) => {
                if (['Input_ports', 'Output_ports'].includes(p.name)) {
                    return { ...p, value: '12' };
                }
                return p;
            });
        }

        const propertiesUpdated: ElemProps[] = node.data.elemProps.map((p) => {
            const libraryProperty = elemProps.find((prop) => prop.name === p.name);
            if (!libraryProperty) {
                return p;
            }

            const { description, help, type, availableValues, visibilityConditions, isPortsManager } = libraryProperty;
            const updatedProperties: Pick<
                ElemProps,
                'description' | 'help' | 'type' | 'availableValues' | 'visibilityConditions' | 'isPortsManager'
            > = { description };

            if (help) {
                updatedProperties.help = help;
            }

            if (!help || (help.description === '' && help.image === '')) {
                updatedProperties.help = null;
            }

            if (type) {
                updatedProperties.type = type;
            }

            if (availableValues) {
                updatedProperties.availableValues = availableValues;
            }

            if (visibilityConditions) {
                updatedProperties.visibilityConditions = visibilityConditions;
            }
            if (isPortsManager) {
                updatedProperties.isPortsManager = isPortsManager;
            }

            return { ...p, ...updatedProperties };
        });

        return { properties: [...propertiesUpdated, ...newProps] };
    };

    private static _upgradeExternalInfo = (
        externalInfo: { properties: ElemProps[]; parameters: ElemParams[] } | null,
        nodes: TSchemaNode[]
    ): { properties: ElemProps[]; parameters: ElemParams[] } | null => {
        if (externalInfo === null) {
            return null;
        }

        const properties =
            externalInfo?.properties !== undefined
                ? externalInfo.properties.map((prop) => {
                      const [propName, elementId] = prop.name.split('-');
                      const element = nodes.find((el) => el.id === elementId);
                      if (!element) {
                          return prop;
                      }
                      const elementProp = element.data.elemProps.find((prop) => prop.name === propName);
                      if (!elementProp) {
                          return prop;
                      }
                      const help = elementProp.help;
                      return { ...prop, help };
                  })
                : [];

        return { ...externalInfo, properties };
    };

    private static _applyPatches = (
        rawSchemaItems: ISchemaItems,
        libraryItems: ILibraryItem[],
        libraryPortTypes: TPortTypeComplex[],
        versionFrom: string | null,
        versionTo: string
    ): ISchemaItems => {
        let schemaItems = {
            elements: rawSchemaItems.elements,
            wires: rawSchemaItems.wires,
            groups: rawSchemaItems.groups || [],
        };

        if (versionFrom === '1.4.0') {
            schemaItems = applyPatch_1_4_1(schemaItems);
        }
        if (versionFrom && gte(versionFrom, '1.6.0') && lt(versionFrom, '1.8.0')) {
            schemaItems = applyPatch_1_8_0(schemaItems, libraryItems);
        }
        if (versionFrom === '1.8.0') {
            schemaItems = applyPatch_1_8_1(schemaItems);
        }
        if (versionFrom && gte(versionFrom, '1.6.0') && lt(versionFrom, '1.9.0')) {
            schemaItems = applyPatch_1_9_0(schemaItems, libraryItems);
        }
        if (versionFrom && lte(versionFrom, '2.6.0')) {
            schemaItems = applyPatch_2_5_1(schemaItems, libraryItems, libraryPortTypes);
        }

        return schemaItems;
    };
}
