import { IChartItemParameter, IChartList, INewSchemaState } from '@repeat/models';
import { Locales } from '@repeat/translations';

const updateParameter = (param: IChartItemParameter, schema: INewSchemaState) => {
    const { key } = param;
    const nodeId = key?.split('_')[0];
    let node = schema.schemaItems.elements.find((el) => el.id === nodeId);

    let elementTitle = `${node?.data.name} [${node?.data.index}]`;

    const splittedComplexKey = key.split('.');
    if (splittedComplexKey.length === 2) {
        const groupId = splittedComplexKey[0];
        const groupNodeId = splittedComplexKey[1].split('_')[0];
        const group = schema.schemaItems.groups?.find((group) => group.id.toString() === groupId);
        node = group?.elements.find((el) => el.id === groupNodeId);
        elementTitle = `${group?.name} / ${node?.data.name} [${node?.data.index}]`;
    }

    const parameterName = key?.split('_').slice(1).join('_');

    const nodeElemParams = node?.data.elemParams || [];
    const currentParameter = nodeElemParams.find((param) => param.name === parameterName);
    const unit = currentParameter?.unit ? `, ${currentParameter?.unit}` : '';
    const parameterTitle = `${currentParameter?.description || ''}${unit}`;
    const title = param.title.length > 0 ? param.title : `${elementTitle} / ${parameterTitle}`;
    return { ...param, parameterTitle, elementTitle, title };
};

export const setDefaultParameter = (locale: string) => {
    const defaultParameterTitle = locale === Locales.RUSSIAN ? 'Время, с' : 'Time, s'; // TODO fix translation
    const timeParameter = {
        key: 'time',
        title: defaultParameterTitle,
        elementTitle: defaultParameterTitle,
        parameterTitle: defaultParameterTitle,
    } as IChartItemParameter;
    return timeParameter;
};

const updateXParameter = (XParameter: IChartItemParameter | null, schema: INewSchemaState, locale: string) => {
    if (XParameter === null) {
        return XParameter;
    }
    const { key } = XParameter;
    if (key === 'time') {
        return setDefaultParameter(locale);
    }

    const parameter = updateParameter(XParameter, schema);

    return parameter;
};

export default class ChartsAdapterService {
    static upgrade = (charts: IChartList, schema: INewSchemaState, locale: string): IChartList => {
        const chartsUpdated: IChartList = {};

        for (const index in charts) {
            if (index in charts) {
                chartsUpdated[index] = Object.assign({}, charts[index]);

                chartsUpdated[index].YParameters = charts[index].YParameters.map((yParam) => {
                    return updateParameter(yParam, schema);
                });

                chartsUpdated[index].XParameters = updateXParameter(chartsUpdated[index].XParameters, schema, locale);
            }
        }

        return chartsUpdated;
    };
}
