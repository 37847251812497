/* eslint-disable @typescript-eslint/no-explicit-any */

import { AccountTypeData, IUserAccount } from './account';
import { Status } from './status';
import { ITariffItem } from './tariff/tariff';

import type { Company } from './company';

export interface EmailData {
    email: string;
}

export enum EUserRoles {
    USER = 'demo',
    DEV = 'dev',
    ADMIN = 'admin',
    BASE = 'base',
    MECHANICS = 'mechanics',
    HYDRO = 'hydro',
    HEATHYDRO = 'heathydro',
    PNEUMO = 'pneumo',
    ELECTRODYNAMICS = 'electrodynamics',
    CODE_GENERATION = 'codeGeneraton',
}

export type TUserRole =
    | EUserRoles.DEV
    | EUserRoles.USER
    | EUserRoles.ADMIN
    | EUserRoles.BASE
    | EUserRoles.MECHANICS
    | EUserRoles.ELECTRODYNAMICS
    | EUserRoles.CODE_GENERATION;

export enum EUserPermissions {
    BASE = 'base',
    MECHANICS = 'mechanics',
    HYDRO = 'hydro',
    HEATHYDRO = 'heathydro',
    PNEUMO = 'pneumo',
    ELECTRODYNAMICS = 'electrodynamics',
    CODE_GENERATION = 'codegenerating',
}

export type TUserPermission =
    | EUserPermissions.BASE
    | EUserPermissions.MECHANICS
    | EUserPermissions.HYDRO
    | EUserPermissions.HEATHYDRO
    | EUserPermissions.PNEUMO
    | EUserPermissions.ELECTRODYNAMICS
    | EUserPermissions.CODE_GENERATION;

// TODO: are types same
export type User = EmailData &
    AccountTypeData & {
        id: number;
        login: string;
        phone: string;
        userName: string;
        tariffId: number;
        role?: TUserRole;
        roles?: TUserRole[];
        permissions?: TUserPermission[];
        isAdmin?: boolean;
        settings?: {
            locale: string;
            isEngineer: boolean;
            theme: string;
        };
    };

export type UserCurrentUser = {
    accessToken?: string;
    refreshToken?: string;
    accessBaseToken?: string;
    refreshBaseToken?: string;
    user?: User;
    userData?: any[];
};

export interface IUserState {
    currentUser: User | null;
    isAuth: boolean | null;
    status: Status;
    error: string | null;
    forgotPassword: {
        status: Status;
        error: string | null;
    };
    resetPassword: {
        status: Status;
        error: string | null;
    };
    updateForgottenPassword: {
        status: Status;
        error: string | null;
    };
    isOnline: {
        status: Status;
        error: null | string | [];
        errorCount: number;
    };
    refreshToken: {
        status: Status;
        error: string | null;
    };
}
export interface IUserTariffState {
    data: ITariffItem[] | null;
    status: Status;
    error: string | null;
}

export interface IUserCompanyState {
    company: Company | null;
    status: Status;
    error: string | null;
}

export interface IAppUser {
    // currentUser: IUser,
    currentUser?: UserCurrentUser | null;
    isAuth?: boolean;
}

export interface LoginUserData {
    login: string;
    password: string;
}

export type RegisterUserData = EmailData &
    LoginUserData & {
        username: string;
        phone: string;
        userName: string;
        passwordConfirmation?: string;
    };

export interface EditPassword {
    password: string;
    newPassword: string;
}

export type EditProfile = Pick<User, 'userName' | 'email' | 'phone'>;

export type EditUser = EditPassword | EditProfile;

export interface IUserData {
    id: number;
    name: string;
}

export interface IUser extends IUserData {
    email: string;
    isAdmin: boolean;
    userData?: Array<IUserData>;
}

export type UpdateForgottenPasswordData = { token: string; password: string };
export type ResetPasswordData = { token: string };

export type IsOnlineError = {
    code: number;
    createdAt: string;
    message: string;
};

export interface IUserAdministrationState {
    accounts: {
        data: IUserAccount[] | null;
        status: Status;
    };
    addAccount: {
        status: Status;
    };
    editAccount: {
        status: Status;
    };
    deleteAccount: {
        status: Status;
    };
}
