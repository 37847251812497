import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';

import { IS_SHOW_OPTIONS_SAVE_BUTTON } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    ElemProps,
    ElemPropsView,
    PropertyTypes,
    SchemaSelectionModes,
    TElement,
    TPropertyVisibilityCondition,
    TSchemaGroup,
    TSchemaNode,
    WorkspaceModes,
} from '@repeat/models';
import { setDynamicOptions, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { InputField, ShowMore, uiColors } from '@repeat/ui-kit';

import { ItemProperty } from '../ItemOptions/ItemProperty';
import { messages } from '../translation';

const ELEMENTS_WITH_DYNAMIC_PORTS = ['sum', 'electrocityBus'];

const checkANDConditions = (conditionsAnd: TPropertyVisibilityCondition, options: { [key: string]: string }) => {
    const arr: boolean[] = [];
    Object.keys(conditionsAnd).forEach((conditionPropName) =>
        arr.push(conditionsAnd[conditionPropName].includes(options[conditionPropName]))
    );
    return arr.filter((el) => !el).length === 0;
};

const setLimitValue = (
    property: { [key: string]: string },
    properties: ElemProps[],
    options: { [key: string]: string }
) => {
    const propertyName = Object.values(property)[0];
    const propertyValue = properties.find((prop) => prop.name === propertyName)?.value;
    const optionValue = options[propertyName];

    const value = optionValue ? optionValue : propertyValue;
    return value?.toString();
};

export const getPropsResult = (
    properties: ElemProps[],
    optionsForVisibility: { [key: string]: string },
    options: { [key: string]: string },
    type: string
) => {
    if (type === 'userBlock') {
        return properties.map(p=>({...p, isVisible: true}))
    }
    const propsRes: ElemPropsView[] = properties.map((p) => {
        let isVisible = true;
        let minValue: string | undefined = '';
        let maxValue: string | undefined = '';
        if (p.visibilityConditions && p.visibilityConditions.length !== 0) {
            const resultsForEachCondition = p.visibilityConditions?.map((condition) => {
                return checkANDConditions(condition, optionsForVisibility);
            });
            isVisible = resultsForEachCondition.filter((el) => el).length !== 0;
        }

        if (p.type === PropertyTypes.NUMBER && p.rules) {
            const maxProperty = p.rules.find((el) => 'max' in el);
            const minProperty = p.rules.find((el) => 'min' in el);

            if (maxProperty) {
                maxValue = setLimitValue(maxProperty, properties, options);
            }
            if (minProperty) {
                minValue = setLimitValue(minProperty, properties, options);
            }
        }
        return { ...p, value: optionsForVisibility[p.name], isVisible, minValue, maxValue };
    });
    return propsRes;
};

const getPropsResultGroup = (
    properties: ElemProps[],
    groups: TSchemaGroup[],
    optionsForVisibility: { [key: string]: string },
    options: { [key: string]: string },
    id: number,
    type: string
) => {
    const propsRes: ElemPropsView[] = properties.map((p) => {
        if (p.visibilityConditions && p.visibilityConditions.length !== 0) {
            const splittedName = p.name.split('-');
            const name = splittedName[0];
            const elementId = splittedName[splittedName.length - 1];

            const groupElementProps = groups
                .find((group) => group.id.toString() === id.toString())
                ?.elements.find((el) => el.id === elementId)?.data.elemProps;
            if (!groupElementProps) {
                return { ...p, isVisible: true };
            }

            const props = getPropsResult(
                groupElementProps,
                getPropertiesValuesByNames(groupElementProps),
                options,
                type
            );
            const propIsVisible = props.find((p) => p.name === name)?.isVisible;
            return { ...p, isVisible: propIsVisible !== undefined ? propIsVisible : true };
        }
        return { ...p, isVisible: true };
    });

    return propsRes;
};

export const getPropertiesValuesByNames = (properties: ElemProps[]) => {
    const propertiesObject: { [key: string]: string } = {};
    properties.forEach((prop) => (propertiesObject[prop.name] = prop.value?.toString()));
    return propertiesObject;
};

export const ElementProperties: FC<{ mode?: SchemaSelectionModes }> = ({ mode }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const node = useAppSelector(workspaceSelectors.currentNodeProperties) as TElement;
    const currentNode = useAppSelector(workspaceSelectors.getSchemaNodeById(node?.id?.toString())) as TSchemaNode;
    const externalProperties = useAppSelector((state) => state.workspace.schema.externalInfo?.properties);
    const selectedItems = useAppSelector(workspaceSelectors.selectedItems);
    const selectedItemProperties = useAppSelector(workspaceSelectors.selectedItemProperties);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);
    const groups = useAppSelector(workspaceSelectors.schemaGroups) || [];

    const [inputValue, setInputValue] = useState(currentNode?.customName || '');

    const { id, type, elemProps, availablePorts, hasManagedPorts } = node;

    const currentElementProperties =
        mode === SchemaSelectionModes.PROJECT &&
        workspaceMode === WorkspaceModes.MAIN &&
        !isUserBlockEditorMode &&
        externalProperties
            ? externalProperties
            : elemProps;
    const [propertiesToRender, setPropertiesToRender] = useState<ElemProps[]>(currentElementProperties);

    const initialPropertiesState = getPropertiesValuesByNames(currentElementProperties);

    const [optionsForVisibility, setOptionsForVisibility] = useState<{ [key: string]: string }>(initialPropertiesState);

    const [options, setOptions] = useState<{ [key: string]: string }>({});
    const [optionsBuffer, setOptionsBuffer] = useState<{ [key: string]: string }>({});

    const [isChanged, setIsChanged] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        dispatch(workspaceActions.changeElementCustomName({ id: currentNode.data.id, name: event.target.value }));
    };

    useEffect(() => {
        setInputValue(currentNode?.customName || '');
    }, [currentNode]);

    useEffect(() => {
        const name = Object.keys(optionsBuffer)[0];
        const value = Object.values(optionsBuffer)[0];
        const propertyValue = propertiesToRender.find((prop) => prop.name === name)?.value;
        if (propertyValue !== value) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [optionsBuffer, propertiesToRender]);

    const idRef = useRef(id);

    useEffect(() => {
        if (
            id !== idRef.current &&
            selectedItems.elements.length !== 0 &&
            Object.keys(optionsBuffer).length !== 0 &&
            isChanged
        ) {
            submitContextHandler(optionsBuffer, idRef.current);
            setOptionsBuffer({});
        }
        idRef.current = id;
    }, [id, isChanged, optionsBuffer]);

    useEffect(() => {
        setOptionsForVisibility(getPropertiesValuesByNames(currentElementProperties));
    }, [currentElementProperties]);

    useLayoutEffect(() => {
        if (type !== 'group') {
            setPropertiesToRender(getPropsResult(currentElementProperties, optionsForVisibility, options, type));
        } else {
            const propsGroupRes = getPropsResultGroup(
                currentElementProperties,
                groups,
                optionsForVisibility,
                options,
                id,
                type
            );
            setPropertiesToRender(propsGroupRes);
        }
    }, [optionsForVisibility]);

    useEffect(() => {
        setPropertiesToRender(currentElementProperties);
        setOptionsForVisibility(getPropertiesValuesByNames(currentElementProperties));
    }, [id]);

    const changeHandler = (name: string | number, value: string) => {
        setOptionsForVisibility((prev: Record<string | number, string>) => ({ ...prev, [name]: value }));
    };

    const optionsHandler = (name: string | number, value: string) => {
        setOptions((prev: Record<string | number, string>) => ({ ...prev, [name]: value }));
    };

    const optionsBufferHandler = (name: string | number, value: string) => {
        setOptionsBuffer({ [name]: value });
    };

    const clearBuffer = () => {
        setOptionsBuffer({});
    };

    const submitContextHandler = (obj: { [key: string]: string }, elId: number | undefined) => {
        if (ELEMENTS_WITH_DYNAMIC_PORTS.includes(type) && id) {
            dispatch(setDynamicOptions({ element: { type, id, availablePorts }, options: options }));
        }
        if (elId) {
            dispatch(workspaceActions.setElementPropertiesValues({ id: elId, elemProps: obj }));
        }
    };

    const getHelpImage = (property: ElemProps) =>
        property?.help?.image ? `/assets/library/images/help/${property?.help?.image}.png` : '';

    const saveButtonIsVisible = propertiesToRender.filter((prop) => prop.editable)?.length !== 0;

    if (mode === SchemaSelectionModes.PROJECT) {
        return (
            <ShowMore title={intl.formatMessage(messages[TranslationKey.WORKSPACE_PROPERTIES])}>
                {!node.isIndicator && propertiesToRender.length !== 0 ? (
                    <form
                        action=''
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitContextHandler(options, Number(id));
                        }}
                    >
                        {propertiesToRender?.map(
                            (property: ElemPropsView) =>
                                'isVisible' in property && (
                                    <ItemProperty
                                        key={`${id}-${property.name}`}
                                        elementId={id}
                                        elementType={type}
                                        property={property}
                                        onChange={changeHandler}
                                        help={
                                            property?.help && {
                                                ...property.help,
                                                image: getHelpImage(property),
                                            }
                                        }
                                        availablePorts={availablePorts}
                                        setOptions={optionsHandler}
                                        setOptionsBuffer={optionsBufferHandler}
                                        clearBuffer={clearBuffer}
                                        hasManagedPorts={hasManagedPorts}
                                        isEditable={mode === SchemaSelectionModes.PROJECT}
                                        availableValues={
                                            selectedItemProperties !== null &&
                                            selectedItemProperties[property.name]?.availableValues !== undefined
                                                ? selectedItemProperties[property.name].availableValues
                                                : property?.availableValues
                                        }
                                    />
                                )
                        )}

                        {saveButtonIsVisible && IS_SHOW_OPTIONS_SAVE_BUTTON && (
                            <Button type='submit'>{intl.formatMessage(messages[TranslationKey.SAVE])}</Button>
                        )}
                    </form>
                ) : (
                    <span style={{ color: `${uiColors.darkGrey}`, fontSize: '12px' }}>
                        {intl.formatMessage(messages[TranslationKey.WORKSPACE_PROPERTIES_NOT_DEFINED])}
                    </span>
                )}
            </ShowMore>
        );
    }

    return (
        <ShowMore title={intl.formatMessage(messages[TranslationKey.WORKSPACE_PROPERTIES])}>
            <InputField
                label={intl.formatMessage(messages[TranslationKey.WORKSPACE_PROPERTIES_CUSTOM_NAME])}
                size={'small'}
                id='custom-name-value'
                value={inputValue}
                maxLength={56}
                onChange={handleInputChange}
            />

            {!node.isIndicator && propertiesToRender.length !== 0 ? (
                <form
                    action=''
                    onSubmit={(e) => {
                        e.preventDefault();
                        submitContextHandler(options, Number(id));
                    }}
                >
                    {propertiesToRender?.map(
                        (property: ElemPropsView) =>
                            property.isVisible && (
                                <ItemProperty
                                    key={`${id}-${property.name}`}
                                    elementId={id}
                                    elementType={type}
                                    property={property}
                                    onChange={changeHandler}
                                    help={
                                        property?.help && {
                                            ...property.help,
                                            image: getHelpImage(property),
                                        }
                                    }
                                    availablePorts={availablePorts}
                                    setOptions={optionsHandler}
                                    setOptionsBuffer={optionsBufferHandler}
                                    clearBuffer={clearBuffer}
                                    hasManagedPorts={hasManagedPorts}
                                    isEditable={selectedItems.elements[0]?.data.type === 'group'}
                                    availableValues={
                                        selectedItemProperties !== null &&
                                        selectedItemProperties[property.name]?.availableValues !== undefined
                                            ? selectedItemProperties[property.name].availableValues
                                            : property?.availableValues
                                    }
                                />
                            )
                    )}

                    {saveButtonIsVisible && IS_SHOW_OPTIONS_SAVE_BUTTON && (
                        <Button type='submit'>{intl.formatMessage(messages[TranslationKey.SAVE])}</Button>
                    )}
                </form>
            ) : (
                <span style={{ color: `${uiColors.darkGrey}`, fontSize: '12px' }}>
                    {intl.formatMessage(messages[TranslationKey.WORKSPACE_PROPERTIES_NOT_FOUND])}
                </span>
            )}
        </ShowMore>
    );
};
