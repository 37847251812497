// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { getBaseDomainIfCloud } from '@repeat/common-slices';
import { deleteCookie, getCookie, setCookie } from '@repeat/constants';
import { environment } from '@repeat/environment';

export { apiEndpoint } from './endpoints';

const { API_URL: baseURL, AUTH_DOMAIN: authDomain } = environment;

const apiConfig: AxiosRequestConfig = {
    baseURL,
};

export const $api = axios.create(apiConfig);

$api.interceptors.request.use((config) => {
    const accessToken = getCookie('accessToken');
    if (accessToken && config?.headers) {
        config.headers['Authorization'] = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
    }
    return config;
});

$api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error: AxiosError) => {
        const originalRequest = error.config;
        const currentDomain = getBaseDomainIfCloud();
        // eslint-disable-next-line no-underscore-dangle
        if (error?.response?.status === 401 && error.config && !error.config?._isRetry) {
            try {
                const response = await axios.post(`${baseURL}/api/v1/auth/refreshToken`, {
                    refreshToken: getCookie('refreshToken'),
                });

                originalRequest._isRetry = true; // eslint-disable-line no-underscore-dangle

                setCookie('accessToken', response.data.accessToken);
                setCookie('refreshToken', response.data.refreshToken);
                setCookie('accessBaseToken', response.data.accessBaseToken, currentDomain);
                setCookie('refreshBaseToken', response.data.refreshBaseToken, currentDomain);

                return $api.request(originalRequest);
            } catch (e: AxiosError) {
                if (e.response?.status === 401) {
                    try {
                        const baseResponse = await axios.post(`${baseURL}/api/v1/auth/refreshTokenByBaseToken`, {
                            refreshBaseToken: getCookie('refreshBaseToken'),
                        });

                        originalRequest._isRetry = true; // eslint-disable-line no-underscore-dangle

                        setCookie('accessToken', baseResponse.data.accessToken);
                        setCookie('refreshToken', baseResponse.data.refreshToken);
                        setCookie('accessBaseToken', baseResponse.data.accessBaseToken, currentDomain);
                        setCookie('refreshBaseToken', baseResponse.data.refreshBaseToken, currentDomain);

                        return $api.request(originalRequest);
                    } catch (e2) {
                        deleteCookie('accessToken');
                        deleteCookie('refreshToken');
                        deleteCookie('accessBaseToken', currentDomain);
                        deleteCookie('refreshBaseToken', currentDomain);

                        window.onbeforeunload = null;
                        window.location.assign(authDomain);
                    }
                }
            }
        }

        throw error;
    }
);
