import { PayloadAction } from '@reduxjs/toolkit';

import { TProxyMap } from 'libs/models/src/lib/element';

import {
    ElemParams,
    ElemProps,
    IChartList,
    IChartsDataMap,
    IModulesCounter,
    IProjectSettingsOptions,
    ISchemaGoToMap,
    IWorkspaceState,
    Statuses,
    TLibraryType,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaNode,
    TSolverType,
} from '@repeat/models';

import { initialState } from '../schemaSlice';

export const GetProjectSlices = {
    // getProject actions
    getProjectRequest: (state: IWorkspaceState) => ({
        ...state,
        schema: {
            ...initialState,
            getProject: {
                ...initialState.getProject,
                status: Statuses.LOADING,
            },
        },
    }),
    getProjectSuccess: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            data: {
                elements: TSchemaNode[];
                wires: TSchemaConnection[];
                groups?: TSchemaGroup[];
                externalInfo: { properties: ElemProps[]; parameters: ElemParams[] } | null;
                proxyMap: TProxyMap | null;
                goToMap: ISchemaGoToMap | null;
                userBlocks?: { [blockId: string]: number };
                modules: IModulesCounter;
            };
            libraryType: TLibraryType;
            solverType: TSolverType;
            header: IProjectSettingsOptions & { charts: IChartList };
        }>
    ) => {
        const chartListDataMap = {} as IChartsDataMap;
        if (payload.header?.charts) {
            Object.values(payload.header?.charts).forEach((chart) => {
                chartListDataMap[chart.uuid] = {
                    index: 0,
                    lateTimeStart: 0,
                    isReady: true,
                };
            });
        }

        const groups = payload.data.groups || [];
        const groupsUpd = groups.map((group) => ({ ...group, id: Number(group.id) })); // TODO fix kostil!!!
        return {
            ...state,
            graphs: {
                ...state.graphs,
                dataMap: chartListDataMap,
                ...(payload.header.charts && { charts: payload.header.charts }),
            },
            settings: { ...state.settings, ...payload.header },
            schema: {
                ...state.schema,
                version: payload.header?.version || null,
                libraryType: payload.libraryType,
                solverType: payload.solverType,
                rawSchemaItems: {
                    elements: payload.data.elements,
                    wires: payload.data.wires,
                    groups: groupsUpd,
                },
                getProject: {
                    ...state.schema.getProject,
                    status: Statuses.SUCCEEDED,
                },
                externalInfo: payload.data.externalInfo,
                proxyMap: payload.data.proxyMap,
                goToMap: payload.data.goToMap,
                userBlocksCount: payload.data.userBlocks || {},
            },
            modules: { ...payload.data.modules },
        };
    },
    getProjectFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => ({
        ...state,
        schema: {
            ...state.schema,
            getProject: {
                ...state.schema.getProject,
                status: Statuses.FAILED,
                error: payload.error,
            },
        },
    }),
};
